// Library Imports
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import axios from 'axios'
import { withRouter } from 'react-router-dom'

// Redux Actions
import { createForest } from '../../redux/actions/forest'

// Components
import UseDebounceEffect from '../../utils/UseDebounceEffect'

// Assets
import './modal.css'

const CreateForestModal = ({ closeModal, createForest, history, forests }) => {

    const [forestData, setForestData] = useState({
        title: '',
        description: '',
        users: []
    })

    const handleTitle = (e) => {
        const tempState = { ...forestData }
        tempState.title = e.target.value
        setForestData(tempState);
    }

    const handleDescription = (e) => {
        const tempState = { ...forestData }
        tempState.description = e.target.value
        setForestData(tempState);
    }

    const handleForestCreation = () => {
        createForest(forestData, history);
        window.location.reload()
    }


    const [userArray, setUserArray] = useState([])
    const [currentUserToAdd, setCurrentUserToAdd] = useState(null)

    const createUserArray = () => {
        let tempUserArray = []
        for (var i = 0; i < forests.length; i++) {
            for (var j = 0; j < forests[i].users.length; j++) {
                tempUserArray.push(forests[i].users[j])
            }
        }
        let uniqueUsers = [...new Set(tempUserArray)];
        for (var u = 0; u < uniqueUsers.length; u++) {
            axios.get(`/api/users/populateUser/${uniqueUsers[u]}`).then(res => {
                setCurrentUserToAdd(res.data)
            }).catch(err => console.log(err))
        }
    }

    useEffect(() => {
        if (currentUserToAdd !== null) {
            let tempUserArray = [...userArray, currentUserToAdd]
            setUserArray(tempUserArray);
        }
    }, [currentUserToAdd])


    UseDebounceEffect(() => {
        console.log(userArray);

        setUsers(userArray); //Set users which are mapped to render

        let tempSelectionArray = []
        for (var i = 0; i < userArray.length; i++) {
            tempSelectionArray.push(false)
        }
        setSelectionArray(tempSelectionArray); //Set Selection options which are mapped to render

    }, [userArray], 100)


    //User Selection Box stuff
    const [users, setUsers] = useState([])
    const [selectionArray, setSelectionArray] = useState([])
    const [usersToAddToForest, setUsersToAddToForest] = useState([])

    useEffect(() => {
        createUserArray();
    }, [])

    const selectionHandler = (index) => {
        let tempSelectionArray = [...selectionArray]
        tempSelectionArray[index] = !tempSelectionArray[index]
        setSelectionArray(tempSelectionArray);

        //This checks the array to either add or remove as necessary, and excecutes.
        if (usersToAddToForest.indexOf(users[index]) === -1) {
            let tempUsersToAddArray = [...usersToAddToForest]
            tempUsersToAddArray.push(users[index])
            setUsersToAddToForest(tempUsersToAddArray)
        } else {
            let tempUsersToAddArray = [...usersToAddToForest]
            tempUsersToAddArray.splice(usersToAddToForest.indexOf(users[index]), 1)
            setUsersToAddToForest(tempUsersToAddArray)
        }
    }

    //Use effect at end, after selections have been handled to update the object.
    useEffect(() => {
        const tempState = { ...forestData }
        tempState.users = usersToAddToForest
        setForestData(tempState);
    }, [usersToAddToForest])


    return (
        <div className='modal-content-container'>
            <div className='modal-title'>Create a Forest</div>
            <div className='modal-description'>Create a space to shoot the breeze, and see what your fellow guides have been up to.</div>

            <div className='modal-content'>


                <div className='modal-input-title'>Forest Name</div>
                <input type='text'
                    className='modal-text-input'
                    name='title'
                    value={forestData.title}
                    onChange={(e) => handleTitle(e)} />

                <div className='modal-input-title'>Forest Description</div>
                <textarea
                    className='modal-text-input textarea'
                    rows={5}
                    name='description'
                    value={forestData.description}
                    onChange={(e) => handleDescription(e)} />

                <div className='modal-input-title'>Select Trees</div>
                <form className='user-select-form'>

                    {users.length === 0 ? <>No trees added, yet!</>
                        :
                        <>
                            {
                                users.map((item, index) => (
                                    <div
                                        className={selectionArray[index] ? 'modal-selection-option selected clickable' : 'modal-selection-option clickable'}
                                        onClick={() => selectionHandler(index)}
                                        key={index}>
                                        <div className='modal-selection-title' key={index}>{item.name}</div>
                                    </div>
                                ))
                            }

                        </>
                    }


                </form>

            </div>

            <div className='modal-control-container'>
                <button className='modal-cancel-btn clickable' onClick={closeModal}>Cancel</button>
                <button
                    className='modal-continue-btn clickable'
                    onClick={() => {
                        handleForestCreation();
                        closeModal();
                    }}>
                    + Create Forest
                </button>
            </div>

        </div>
    )
}

const mapStateToProps = state => ({
    forests: state.forest.forests
})

CreateForestModal.propTypes = {
    createForest: PropTypes.func.isRequired,
    forests: PropTypes.array.isRequired,
}

export default connect(mapStateToProps, { createForest })(withRouter(CreateForestModal))