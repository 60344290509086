// ! TARGET IS FOR THIS TO REPLACE OTHER SELECTOR
// ! We are close, I just need to remove it from ForestOrganizer

// Library Imports
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons'

// Redux Actions


// Assets

// ! TARGET IS FOR THIS TO REPLACE OTHER SELECTOR

// This component looks to the currently logged in user's Profile,
// and 

const ForestSelector = ({parentCB,includeAllTreesEverywhere}) => {

    // REDUX
    const currentUserProfile= useSelector((state) => state.profile.profile);

    // TODO: Refactor Forest and User lists in profile object!

    // On changes of profile, populate forest selector requirements.
    useEffect(() => {
        if (currentUserProfile && currentUserProfile.forests){
            let tempForestIds = [...userForestIds]
            let tempForestNames = [...userForestNames]
            for (let i=0;i<currentUserProfile.forests.length;i++){
                tempForestIds.push(currentUserProfile.forests[i]._id);
                tempForestNames.push(currentUserProfile.forests[i].title);
            }
            setUserForestIds(tempForestIds)
            setUserForestNames(tempForestNames)
        }
    }, [currentUserProfile])

    const [userForestIds, setUserForestIds] = useState(['ALL_TREES_EVERYWHERE', 'MY_TREES'])
    const [userForestNames, setUserForestNames] = useState(['All Trees Everywhere', 'All My Trees'])

    // Handle Looping around index selection.
    const [selectedIndex, setSelectedIndex]= useState(0)
    const handleIncrement = () => {
        if (selectedIndex <= userForestIds.length-2) {
            setSelectedIndex(selectedIndex+1)
            parentCB(userForestIds[selectedIndex+1])
        } else {
            setSelectedIndex(0)
            parentCB(userForestIds[0])
        }        
    }

    const handleDecrement = () => {
        if (selectedIndex >= 1) {
            setSelectedIndex(selectedIndex-1)
            parentCB(userForestIds[selectedIndex-1])
        } else {
            setSelectedIndex(userForestIds.length-1)
            parentCB(userForestIds[userForestIds.length-1])
        }
    }

    // On load, if we are not to include all trees everywhere, remove it from the list.
    useEffect(() => {
        if (!includeAllTreesEverywhere) {
            setUserForestIds(['MY_TREES'])
        }
    }, [])

    return (
        <div className='forest-selector-tool sub-content-container'>
            <div className='btn-arrow clickable'><FontAwesomeIcon className='btn-arrow' icon={faCaretLeft} onClick={() => handleDecrement()} /></div>
            <div className='cell-container'>
                <div className='sliding-cell clickable' key='currentCell'>{userForestNames[selectedIndex]}</div>
            </div>
            <div className='btn-arrow clickable'><FontAwesomeIcon className='btn-arrow' icon={faCaretRight} onClick={() => handleIncrement()} /></div>
        </div>
    )
}

export default ForestSelector
