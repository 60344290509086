import { GET_PLANT, PLANT_ERROR, CREATE_PLANT, GET_USER_PLANTS } from "../actions/types";

const initialState = {
    plant: null,
    plants: [],
    loading: true,
    error: {}
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case CREATE_PLANT:
        case GET_PLANT:
            return {
                ...state,
                plant: payload,
                loading: false
            }
        case GET_USER_PLANTS:
            return {
                ...state,
                plants: payload,
                loading: false
            }
        case PLANT_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state
    }
}