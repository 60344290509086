// Library Imports
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector, useDispatch} from 'react-redux'


// Redux Actions
import { getUserBreezes } from '../../../redux/actions/breezes'

// Assets
import '../css/your-latest-breezes.css'
import breezes from '../../../redux/reducers/breezes'
import LoadingSpinner from '../../../components/Spinner/LoadingSpinner'

export default function YourLatestBreezes({ auth, userId }) {

    // REDUX
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.auth.user)

    // Get Latest Breezes on Load
    useEffect(() => {
        if (currentUser != null) {
            dispatch(getUserBreezes(currentUser._id, 3))
        }
    }, [currentUser])

    const breezesLoading = useSelector(state => state.breezes.userBreezesLoading);
    const latestBreezes = useSelector(state => state.breezes.userOwns);

    // Once Breezes have loaded, update data for display
    const [userBreezes, setUserBreezes] = useState([])

    useEffect(() => {
        if (!breezesLoading){
            setUserBreezes(latestBreezes);
        }
    }, [breezesLoading])


    return (
        <div className='your-latest-breezes-container'>
            <div className='your-latest-breezes-title'>Latest Breezes:</div>
            {breezesLoading ? 
            <LoadingSpinner size={40}/>
            :
            userBreezes.length > 0 ?
                userBreezes.map(item => {
                    return (
                        <div className='latest-breeze-item sub-content-container' key={item._id}>
                            <div>{item.text}</div>
                            <div>{item.supportingUsers.length}/{Math.ceil(item.forestSupportLimit) + Math.ceil(item.othersSupportLimit)}</div>
                        </div>
                    )
                })
                :
                <>No Breezes</>
            }
            <a href='/breezehistory'><div className='view-all-btn'>
                View All My Breezes
            </div>
            </a>
        </div>
    )
}
