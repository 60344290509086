import React, { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLeaf, faTree, faCannabis, faSeedling, faGlobeAmericas, faSpa, faTemperatureHigh, faCertificate, faCheck } from '@fortawesome/free-solid-svg-icons'

import './large-profile-card.css'

export default function ProfileCard({ name, joinedDate, profileImage, includeBadges = false }) {





    return (
        <div className={includeBadges ? 'card large-profile-card with-badges' : 'card large-profile-card'}>

            <div className='profile-img-container'>
                <img className='profile-summary-img' alt='Profile Picture' src={profileImage} />
            </div>

            <div className='profile-text-container'>
                <div className='profile-name'>{name}</div>
                <div className='profile-member-since'>Member Since: {moment(joinedDate).format('MMM YYYY')}</div>
            </div>

            {includeBadges ?
                <div className='badges-container'>
                    <FontAwesomeIcon className='clickable badge-green' icon={faLeaf} />
                    <FontAwesomeIcon className='clickable badge-green' icon={faTree} />
                    <FontAwesomeIcon className='clickable badge-yellow' icon={faCannabis} />
                    <FontAwesomeIcon className='clickable badge-red' icon={faTemperatureHigh} />
                    <FontAwesomeIcon className='clickable' icon={faSeedling} />
                    <FontAwesomeIcon className='clickable' icon={faSpa} />
                    <FontAwesomeIcon className='clickable' icon={faGlobeAmericas} />
                </div>
                :
                <></>}




        </div>
    )
}
