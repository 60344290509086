// Library Imports
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { connect } from 'react-redux'

// Redux Actions
import { getUserPlants, getFieldPlants } from '../../../redux/actions/plant'

// Components
import PlantCard from '../../../components/Cards/PlantCard/PlantCard'

// Assets
import '../css/profile-field.css'

const ProfileField = ({ auth, plant, profile, getUserPlants, getFieldPlants }) => {

    const [treesInForest, setTreesInForest] = useState(0)
    const [forestsAddedTo, setForestsAddedTo] = useState(0)
    const [plantsInGarden, setPlantsInGarden] = useState(0)

    const [guidePlants, setGuidePlants] = useState([])
    const [fieldPlants, setFieldPlants] = useState([])
    const [allPlants, setAllPlants] = useState([])

    const [currentPlantToAdd, setCurrentPlantToAdd] = useState(null);

    const fieldUserId = window.location.href.split('?id=')[1]

    //Get All Plants this user has:
    useEffect(() => {
        //Get Owned plants
        axios.get(`/api/plant/user/${fieldUserId}`).then(res => {
            console.log('Got plants the current user owns:');
            console.log(res);
            setGuidePlants(res.data)
        }).catch(err => console.log(err))

        //Get Cultivating plants
        axios.get(`/api/profile/user/${fieldUserId}`).then(res => {
            console.log('Got user Profile:');
            console.log(res);
            //Grab each plant via the ID provided:
            for (var i = 0; i < res.data.plants.length; i++) {
                axios.get(`/api/plant/${res.data.plants[i]}`)
                    .then(res => {
                        setCurrentPlantToAdd(res.data);
                    }).catch(err => console.log(err))
            }

        }).catch(err => console.log(err))

        //Not getting seeds.

        //Get how many forests this user is in
        axios.get(`/api/forest/checkForests/${fieldUserId}`).then(res => {
            setForestsAddedTo(res.data.length)
        }).catch(err => console.log(err))

        //Get how many trees this user has added to their forests
        axios.get(`/api/forest/totalUserCount/user/${fieldUserId}`).then(res => {
            setTreesInForest(res.data.length)
        }).catch(err => console.log(err))

    }, [])

    //For adding fiel plants asynchronously, to be removed in optimization phase.
    useEffect(() => {
        if (currentPlantToAdd !== null) {
            let tempFieldPlants = [...fieldPlants, currentPlantToAdd]
            setFieldPlants(tempFieldPlants);
        }
    }, [currentPlantToAdd])

    //Populate All Plants
    useEffect(() => {
        setAllPlants([...guidePlants, ...fieldPlants]);
    }, [guidePlants, fieldPlants])



    useEffect(() => {
        setPlantsInGarden(allPlants.length)
    }, [allPlants])

    return (<div className='profile-topleft-container'>
        <div className='sub-content-container field-display'>
            Field
            <div className='profile-field-container'>
                <div className='profile-plant-card-container'>
                    {allPlants.length > 0
                        ?
                        allPlants.map(item =>
                            item.isSeed ? <></> :
                                <PlantCard key={item._id} plantId={item._id} plantName={item.title} plantImg={item.mainImage} seedCard={item.isSeed} />
                        )
                        :
                        <>Nothing here yet!</>}
                </div>
                <div className='visit-field-container'>
                    <a href={`/userfield/?id=${fieldUserId}`}><div className='visit-field-btn'>Visit Field</div></a>
                </div>
            </div>
        </div>
        <div className='sub-content-container follow-count-info'>
            <div className='follow-count-info-item'>
                <div className='forest-info-item-title'>Trees in forest:</div>
                <div className='forest-info-item-count'>{treesInForest}</div>
            </div>
            <div className='follow-count-info-item'>
                <div className='forest-info-item-title'>In forest of:</div>
                <div className='forest-info-item-count'>{forestsAddedTo}</div>
            </div>
            <div className='follow-count-info-item'>
                <div className='forest-info-item-title'>Plants in Garden:</div>
                <div className='forest-info-item-count'>{plantsInGarden}</div>
            </div>
        </div>

    </div>
    )
}



const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile,
    plant: state.plant
})

ProfileField.propTypes = {
    getUserPlants: PropTypes.func.isRequired,
    getFieldPlants: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    plant: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, { getUserPlants, getFieldPlants })(ProfileField);