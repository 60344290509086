/* eslint-disable */

// Library Imports
import React, { useState, useEffect } from 'react'
import Moment from 'react-moment'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { ReactTinyLink } from 'react-tiny-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux';

// Components
import ReplyChain from '../ReplyChain/ReplyChain'

// Assets 
import './post-block.css';
import SunshineIcon from '../../assets/images/custom icons/Feed-Sunshine-Icon.png'
import BarkIcon from '../../assets/images/custom icons/Feed-Bark-Icon.png'
import RootIcon from '../../assets/images/custom icons/Feed-Root-Icon.png'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
// import BlossomIcon from './layout/images/custom icons/Blossom-Icon.png'
// import ThornIcon from './layout/images/custom icons/Thorn-Icon.png'


export default function PostBlock({
    showAttachment,
    userName,
    postTime,
    postContents,
    postType,
    edited,
    postId,
    supportCount,
    supportLimit,
    downVoteCount,
    blossoms,
    thorns,
    potentialGuide,
    targetPlant,
    postUserId,
    postUserAvatar,
    //currentUser,
    taggedUsers,
    attachedImage,
    comments,
    profile,
    autoExpandedComments }) {

    // REDUX
    const currentUser = useSelector((state) => state.auth.user);

    const history = useHistory();

    const [urlAttached, setUrlAttached] = useState(false)
    const [tinyLinkUrl, setTinyLinkUrl] = useState("")
    const [blossomCount, setBlossomCount] = useState(0)
    const [thornCount, setThornCount] = useState(0)
    const [postTarget, setPostTarget] = useState(null)

    const [dropdownMenuOptions, setDropdownMenuOptions] = useState(<>
        <div className='post-block-dropdown-option'>Report Post</div>
    </>)


    
    const [imageWithPost, setImageWithPost] = useState('')


    useEffect(() => {
        checkLink(postContents);
    }, [tinyLinkUrl])


    const handlePlantClick = (target) => {
        window.scrollTo(0, 0);
        history.push(`/Plant/?id=${target}`);
    }

    const handleUserClick = (target) => {
        if (userName !== "Anonymous Mushroom") {
            window.scrollTo(0, 0);
            history.push(`/profile/?id=${target}`);
        } else {
            console.log('nope.')
        }

    }


    const [displayTarget, setDisplayTarget] = useState(<></>)
    const [targetPlantName, setTargetPlantName] = useState('')

    useEffect(() => {
        if (postTarget !== undefined && postTarget !== null) {

            let plantName = 'Err. Loading Data'

            axios.get(`/api/plant/${postTarget}`).then(res => {
                setTargetPlantName(res.data.title)
                setDisplayTarget(
                    <div className='post-target-container'>
                        <div className='post-target-arrow'>&#8594;</div>
                        <div className='post-target-info-container' onClick={() => handlePlantClick(res.data._id)}>
                            <img className='post-target-img' alt='profileImage' src={res.data.mainImage} />
                            <div className='target-name'>{res.data.title}</div>
                        </div>
                    </div>
                )

            }).catch(err => console.log(err))


        }

    }, [postTarget])


    const [currentUserIsAdmin, setCurrentUserIsAdmin] = useState(false)
    const [blossomNames, setBlossomNames] = useState([])
    const [blossomIds, setBlossomIds] = useState([])
    const [thornNames, setThornNames] = useState([])
    const [thornIds, setThornIds] = useState([])
    const [displayText, setDisplayText] = useState(postContents)
    const [postTypeBadge, setPostTypeBadge] = useState(BarkIcon)


    //On load of post, check if user has interacted and set state appropriately.
    useEffect(() => {
        
        setBlossomCount(blossoms.length);
        setThornCount(thorns.length);
        setPostTarget(targetPlant);
        setImageWithPost(attachedImage);

        let tempBlossomIds = []
        let tempBlossomNames = []
        for (var i = 0; i < blossoms.length; i++) {
            tempBlossomNames.push(blossoms[i].user.name)
            tempBlossomIds.push(blossoms[i].user._id)
        }
        setBlossomNames(tempBlossomNames)
        setBlossomIds(tempBlossomIds)


        let tempThornIds = []
        let tempThornNames = []
        for (var i = 0; i < thorns.length; i++) {
            tempThornNames.push(thorns[i].user.name)
            tempThornIds.push(thorns[i].user._id)
        }
        setThornNames(tempThornNames)
        setThornIds(tempThornIds)

        //Determine if user is admin
        axios.get('/api/auth/checkAdmin').then(res => {
            setCurrentUserIsAdmin(res.data.isAdmin)
        }).catch(err => console.log(err));


        //Search post contents for brak string, and change contents if present:
        if (postContents.indexOf('_bark_') > -1) {
            console.log('theres some bark here')
            setDisplayText(
                <>
                    <b style={{ fontSize: '0.9rem', color: '#333' }}>{postContents.split('_bark_')[0]}</b>
                    <br />
                    {postContents.split('_bark_')[1]}
                </>
            )
        }

        //Post Type Badge
        if (postType === 'plant') {
            setPostTypeBadge(SunshineIcon)
        }
        if (postType === 'barkFeedPost') {
            setPostTypeBadge(BarkIcon)
        } else {
        }


    }, [])

    
    const urlRegex = new RegExp('(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$', 'g');

    //Plant Functions
    const handleBlossom = () => {
        axios.put(`/api/posts/blossom/${postId}`)
            .then((response) => {
                //Log Successful Response
                //console.log(response)

                //Update UI
                //--Blossom Count
                setBlossomCount(blossomCount + 1);

                //--Blossom Name
                let tempBlossomNames = [...blossomNames]
                tempBlossomNames.splice(0, 0, currentUser.name)
                setBlossomNames(tempBlossomNames)

                //--Blossom Ids
                let tempCurrentBlossomIds = [...blossomIds]
                tempCurrentBlossomIds.splice(0, 0, currentUser._id)
                setBlossomIds(tempCurrentBlossomIds)

                //Send Notification
                let notificationObj = {
                    sourceUser: currentUser._id,
                    sourceUserName: currentUser.name,
                    sourceUserAvatar: currentUserAvatar,
                    targetUser: postUserId,
                    notificationType: 'userBlossomedPost',
                    targetPost: targetPlant,
                    targetName: targetPlantName,
                }

                //console.log(notificationObj);
                axios.post('/api/notifications/', notificationObj).then(res => console.log(res)).catch(err => console.log(err))

            })
            .catch((error) => {

                //If error was that we've already blossomed this post, send an unblossom
                if (error.response.status === 400) {
                    console.log('already blossomed post! Running unblossom')
                    axios.put(`/api/posts/unblossom/${postId}`)
                        .then((response) => {
                            //Log Successful Response
                            //console.log('res', response);

                            //Update UI
                            //--Blossom Count
                            setBlossomCount(blossomCount - 1);

                            //--Blossom Names
                            let tempCurrentBlossoms = [...blossomNames]
                            tempCurrentBlossoms.splice(tempCurrentBlossoms.indexOf(currentUser.name), 1)
                            setBlossomNames(tempCurrentBlossoms)

                            //--Blossom Ids
                            let tempCurrentBlossomIds = [...blossomIds]
                            tempCurrentBlossomIds.splice(tempCurrentBlossomIds.indexOf(currentUser._id), 1)
                            setBlossomIds(tempCurrentBlossomIds)


                            //Handle Deletion of Notification
                            //2-18 TODO: Handle Deletion of Notification

                        })
                        .catch((error) => {
                            // If another error
                            console.log(error)
                        })
                } else {
                    // If error was due to any other reason
                    console.log(error.response.status)
                }

            });
    }
    const handleThorn = () => {
        axios.put(`/api/posts/thorn/${postId}`)
            .then((response) => {
                //Log Successful Response
                //console.log('res', response);

                //Update UI
                //--Thorn Count
                setThornCount(thornCount + 1);

                //--Thorn Name
                let tempThornNames = [...thornNames]
                tempThornNames.splice(0, 0, currentUser.name)
                setThornNames(tempThornNames)

                //--Thorn Ids
                let tempCurrentThornIds = [...thornIds]
                tempCurrentThornIds.splice(0, 0, currentUser._id)
                setThornIds(tempCurrentThornIds)

                //Send Notification
                let notificationObj = {
                    sourceUser: currentUser._id,
                    sourceUserName: currentUser.name,
                    sourceUserAvatar: currentUserAvatar,
                    targetUser: postUserId,
                    notificationType: 'userThornedPost',
                    targetPost: targetPlant,
                    targetName: targetPlantName,
                }

                //console.log(notificationObj);
                axios.post('/api/notifications/', notificationObj).then(res => console.log(res)).catch(err => console.log(err))

            })
            .catch((error) => {

                //If error was that we've already thorned this post, send an unthorn
                if (error.response.status === 400) {
                    console.log('already thorned post! Running unthorn')
                    axios.put(`/api/posts/unthorn/${postId}`)
                        .then((response) => {
                            //Log Successful Response
                            //console.log('res', response);

                            //Update UI
                            //--Count
                            setThornCount(thornCount - 1);

                            //--Names
                            let tempCurrentThorns = [...thornNames]
                            tempCurrentThorns.splice(tempCurrentThorns.indexOf(currentUser.name), 1)
                            setThornNames(tempCurrentThorns)

                            //--Ids
                            let tempCurrentThornIds = [...thornIds]
                            tempCurrentThornIds.splice(tempCurrentThornIds.indexOf(currentUser._id), 1)
                            setThornIds(tempCurrentThornIds)

                            //Handle Deletion of Notification
                            //2-18 TODO: Handle Deletion of Notification

                        })
                        .catch((error) => {
                            // If another error
                            console.log(error)
                        })
                } else {
                    // If error was due to any other reason
                    console.log(error.response.status)
                }

            });
    }


    const checkLink = (text) => {
        const testArray = text.split(/\s+/);
        var linkFound = false

        //Loop Through Array
        for (var i = 0; i < testArray.length; i++) {
            if (urlRegex.test(testArray[i])) {
                if (testArray[i].indexOf('http') === 0 || testArray[i].indexOf('www') === 0) {
                    setTinyLinkUrl(testArray[i])
                    setUrlAttached(true)
                    linkFound = true;
                }
            }
        }

        //Run the test on the full string, in case the entire text is a link
        if (urlRegex.test(text) && !linkFound) {
            setTinyLinkUrl(text)
            setUrlAttached(true)
        }
    }

    const [dropdownMenu, toggleDropdownMenu] = useState(false)

    // TODO: Get this fixed in the load process!
    // Temporary Fix for ID Population null protection
    const [currentUserId, setCurrentUserId] = useState(null)
    const [currentUserAvatar, setCurrentUserAvatar] = useState(null)

    //Use Effect to populate dropdown post contents.
    useEffect(() => {
        if (currentUser && currentUser._id === postUserId || currentUserIsAdmin) {
            setCurrentUserId(currentUser._id)
            setCurrentUserAvatar(currentUser.avatar);
            setDropdownMenuOptions(<>
                {postType != 'barkFeedPost' ? <div className='post-block-dropdown-option' onClick={() => handlePostEdit()}>Edit Post</div> : <></>}
                <div className='post-block-dropdown-option' onClick={() => handlePostDelete()}>Delete Post</div>
            </>)
        } else {
            setDropdownMenuOptions(<>
                <a href={`https://docs.google.com/forms/d/e/1FAIpQLScKEI8ciA2xXboYaxLFiv6BWUWti9GZkDqnDt9Z6w_jgKAPQA/viewform?usp=pp_url&entry.1105456013=${postId}`} target='_blank'><div className='post-block-dropdown-option'>Report Post</div></a>
            </>)
        }

    }, [dropdownMenu, currentUserIsAdmin])


    const [editPost, setEditPost] = useState(false)
    const [editedText, setEditedText] = useState(postContents)

    const handlePostEdit = () => {
        console.log('attempting to edit post.')
        toggleDropdownMenu(false);
        setEditPost(!editPost);
    }

    const handleEditText = (e) => {
        setEditedText(e.target.value)
    }

    const handlePostDelete = () => {
        toggleDropdownMenu(false)
        var r = window.confirm("Are you sure you'd like to delete this post? Press OK to delete. This cannot be undone.");
        if (r == true) {

            //If barkFeedPost, we also need to delete the original bark post. Otherwise, we simply delete the post.
            if (postType === 'barkFeedPost') {
                axios.put(`/api/posts/hide/${postId}`).then(res => {
                    axios.put(`/api/posts/hideBark/${res.data.barkPostRef}`).then(res => {
                        console.log(res)
                        window.location.reload();
                    }).catch(err => console.log(err))
                    console.log(res)
                }).catch(err => console.log(err))

            } else {
                axios.put(`/api/posts/hide/${postId}`).then(res => console.log(res)).catch(err => console.log(err))
                window.location.reload();
            }

        } else {
        }
    }

    const handleSaveChanges = () => {
        console.log('saving')
        const updateObj = {
            text: editedText
        }
        axios.put(`/api/posts/editPost/${postId}`, updateObj).then(res => {
            setEditPost(false);
            window.location.reload();
        }).catch(err => console.log(err))
    }

    const handleCancelChanges = () => {
        setEditPost(false);
    }



    return (
        <div className='feed-post-container sub-content-container'>
            <div className='post-type-badge'>
                <img className='post-type-badge-image' alt='post-badge' src={postTypeBadge} />
            </div>
            <div className='feed-post-header'>
                <div className='profile-img-container extrasmall' onClick={() => handleUserClick(postUserId)}>
                    <img alt='profileImg' className='post-profile-img' src={postUserAvatar} />
                </div>
                <div className='user-info-container clickable' onClick={() => handleUserClick(postUserId)}>
                    <div className='username'>{userName}</div>
                    <div className='time'><Moment className='time' format="LLL">{postTime}</Moment>{edited ? <>  -  (edited)</> : <></>}</div>
                </div>
                {displayTarget}
                <div className='post-block-dropdown-menu'>
                    <div className='post-block-dropdown-btn' onClick={() => toggleDropdownMenu(!dropdownMenu)}><FontAwesomeIcon icon={faEllipsisV} /></div>
                    <div className={dropdownMenu ? 'post-block-dropdown-options-container' : 'post-block-dropdown-options-container hide'}>
                        {dropdownMenuOptions}
                    </div>
                </div>
            </div>

            <div className='feed-post-contents'>
                {editPost ? <div className='edit-post-container'>
                    <textarea rows={4} onChange={(e) => handleEditText(e)} className='edit-post-container-textarea'>{editedText}</textarea>
                </div> : displayText}
            </div>


            {urlAttached ? <>
                <ReactTinyLink
                    cardSize="small"
                    showGraphic={true}
                    maxLine={2}
                    minLine={1}
                    url={tinyLinkUrl} />
            </> : <></>}

            {imageWithPost !== undefined && imageWithPost.indexOf('amazonaws') > 0 ?
                <img alt='postImg' className='post-image-attachment' src={imageWithPost} />
                : <></>}

            {taggedUsers.length > 0 ?
                <div className='user-tag-display'>Tagged Trees: {taggedUsers.map((item, idx) => {
                    if (idx === taggedUsers.length - 1) {
                        return <a href={`/profile/?id=${item._id}`}><span>{item.name}</span></a>
                    } else {
                        return <a href={`/profile/?id=${item._id}`}><span>{item.name + ', '}</span></a>
                    }
                })}
                </div>
                : <></>}


            {postType === 'barkFeedPost' ?
                <></>
                :
                <div className='post-actions-section'>
                    <div className='post-actions-container'>
                        {editPost ? <>
                            <div className='post-action-btn btn-support clickable' onClick={() => handleSaveChanges()}>Save Changes</div>
                            <div className='post-action-btn btn-danger clickable' onClick={() => handleCancelChanges()}>Cancel</div>
                        </> :
                            <>
                                {/* Blossom Button */}
                                <div
                                    onClick={() => handleBlossom()}
                                    className={blossomNames.length > 0 ?
                                        blossomIds.indexOf(currentUserId) > -1 ?
                                            'post-action-btn btn-support blossom-btn post-tooltip current-blossom'
                                            :
                                            'post-action-btn btn-support blossom-btn post-tooltip'
                                        :
                                        'post-action-btn btn-support blossom-btn'
                                    }>
                                    {blossomCount}
                                    <div className='thorn-blossom-icon-container'>
                                        Blossom
                                        {/* <img className='thorn-blossom-image' alt='blossom' src={BlossomIcon} /> */}
                                    </div>
                                    {/* Blossom */}
                                    <span className='post-tooltiptext'>
                                        {blossomNames.map(item => <>{item}<br /> </>)}
                                    </span>
                                </div>

                                {/* Thorn Button */}
                                <div
                                    onClick={() => handleThorn()}
                                    className={thornNames.length > 0 ?
                                        thornIds.indexOf(currentUserId) > -1 ?
                                            'post-action-btn btn-support blossom-btn post-tooltip current-thorn'
                                            :
                                            'post-action-btn btn-danger thorn-btn post-tooltip'
                                        :
                                        'post-action-btn btn-danger thorn-btn'
                                    }>
                                    {thornCount}
                                    <div className='thorn-blossom-icon-container'>
                                        Thorn
                                        {/* <img className='thorn-blossom-image' alt='thorn' src={ThornIcon} /> */}
                                    </div>
                                    {/* Thorn */}
                                    <span className='post-tooltiptext'>
                                        {thornNames.map(item => <>{item}<br /> </>)}
                                    </span>
                                </div>
                            </>}
                    </div>
                    <div className='post-actions-container'>
                        <ReplyChain
                            currentUser={currentUser}
                            comments={comments.filter(comment => comment.showComment)}
                            postId={postId}
                            profile={profile}
                            postUserId={postUserId}
                            targetPlantName={targetPlantName}
                            autoExpanded={autoExpandedComments}
                            currentUserAvatar={currentUserAvatar}
                            />
                    </div>
                </div>

            }


        </div>
    )
}