import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import AvatarImageCropper from 'react-avatar-image-cropper';

import '../css/profile-photo-select.css'

const ProfilePhotoSelect = ({ auth }) => {

    // State Definition
    const [displayFile, setDisplayFile] = useState(null);
    const [displayError, setDisplayError] = useState(null);
    const [waitingForUpload, setWaitingForUpload] = useState(false);
    const [uploadCompleted, setUploadCompleted] = useState(false);

    const history = useHistory();


    //Calls Single Image upload from API
    const singleUploadHandler = (currentFile) => {
        if (currentFile != null) {

            setWaitingForUpload(true);

            // Create an object of formData 
            const data = new FormData();

            // Update the formData object 
            data.append('image', currentFile, currentFile.name);

            //Post to proper route
            axios.post('/api/files/profile-img-upload', data, {
                headers: {
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                }
            })
                .then((response) => {
                    if (200 === response.status) {
                        // If file size is larger than expected.
                        if (response.data.error) {
                            if ('LIMIT_FILE_SIZE' === response.data.error.code) {
                                setDisplayError('File too large. Max size: 2MB');
                            } else {
                                setDisplayError(response.data.error); // If not the given file type
                            }
                        } else {
                            // Success
                            let fileName = response.data;
                            console.log('fileName', fileName);
                            console.log('File Uploaded');
                            setDisplayFile(fileName.location);
                            addProfileImageToProfile(fileName.location)
                            setUploadCompleted(true);
                        }
                    }
                }).catch((error) => {
                    setDisplayError(error); // If another error
                });

        } else {
            setDisplayError('Please upload file'); // If file not selected throw error
        }
    }

    //Rendering Functions

    //Displays Photo once it has been uploaded to s3.
    const displayUploadedPhoto = () => {
        if (displayFile != null) {
            return (
                <div>
                    <img alt='profilePhoto' src={displayFile} style={{ maxHeight: '300px', maxWidth: '300px' }} />
                </div>
            );
        } else {
            return (
                <div style={{ height: '300px', width: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                    Uploading...
                </div>
            );
        }
    };

    //Displays Any errors if they are present.
    const displayAnyErrors = () => {
        return (
            <div style={{
                width: '200px',
                color: 'red',
                fontSize: '0.9rem',
                textAlign: 'center',
                position: 'absolute',
                bottom: '40px',
                left: '50%',
                transform: 'translateX(-50%)'
            }}>
                {displayError}
            </div>
        );

    };

    // Effect Hooks
    // Forces a re-render since React won't do it for the errors.
    useEffect(() => {
        displayAnyErrors();
    }, [displayError])


    // Image Cropper Definitions
    // Details at https://www.npmjs.com/package/react-avatar-image-cropper

    const apply = (blobFile) => {
        var fileForUpload = new File([blobFile], `ProfileImage.png`, { type: "image/png", lastModified: Date.now() }); //File Ready to Upload to Server
        console.log(fileForUpload);
        singleUploadHandler(fileForUpload);
    }

    const actions = [<button className='avatar-btn' key={0}>Cancel</button>, <button className='upload-btn' key={1} >Upload!</button >]
    const sliderStyle = { marginTop: '25px' }
    const sliderChildrenDivStyle = { backgroundColor: 'white', display: 'flex', flexDirection: 'column' }
    const initialIconStyle = { width: '200px', height: '300px', padding: '4rem', marginTop: '-125px' }

    const initialTextStyle = {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '1rem',
        backgroundColor: '#00916e',
        color: 'white',
        padding: '0.5rem 2rem',
        borderRadius: '5px',
        textAlign: 'center',
        cursor: 'pointer',
        marginTop: '-105px'
    }

    const errorHandler = (type) => {
        if (type == 'not_image') setDisplayError('Error: Please upload png, jpeg, or jpeg');
        if (type == 'maxsize') setDisplayError('File too large. Max size: 2MB');
    }

    //TODO: Get profile picture populating
    const addProfileImageToProfile = (imageUrl) => {

        let updateObj = {
            avatar: imageUrl
        }

        console.log('about to add to profile!')
        axios.put(`/api/users/profileImage/${auth.user._id}`, updateObj).then(res => console.log(res)).catch(err => console.log(err))
    }

    return (
        <div className='root-select-container'>
            <div className='base-camp-static-bg' />

            <div className='root-select-content content-container'>

                <div className='welcome-title'>Upload your Profile Picture</div>

                <div className='image-upload-container sub-content-container'>
                    <div className='profile-photo-upload-description'>Choose your profile photo, then zoom/drag to crop.</div>

                    <div style={{ width: '300px', height: '300px', margin: '0 auto', border: '1px solid gray', position: 'relative' }}>
                        {waitingForUpload ?
                            <>
                                {displayUploadedPhoto()}
                            </> : <>
                                <AvatarImageCropper
                                    apply={apply}
                                    actions={actions}
                                    sliderConStyle={sliderStyle}
                                    iconStyle={initialIconStyle}
                                    textStyle={initialTextStyle}
                                    sliderChildrenDiv={sliderChildrenDivStyle}
                                    text="Choose Photo"
                                    className='image-cropper'
                                    errorHandler={errorHandler}
                                />
                            </>}

                        {displayAnyErrors()}
                    </div>
                    {uploadCompleted ?
                        <>
                            <a href='/basecamp'><div className='continue-btn clickable'>Continue to SolutionTrees</div></a>
                            <a href='/profileimageupload'><div className='try-again-btn clickable'>Not the picture you wanted? Click here to try again.</div></a>
                        </> : <>
                            <a href='/basecamp' className='skip-btn clickable'>Skip for now</a>
                        </>}
                </div>

            </div>

        </div>

    )
}


const mapStateToProps = state => ({
    auth: state.auth
})

ProfilePhotoSelect.propTypes = {
    auth: PropTypes.object.isRequired
}

export default connect(mapStateToProps, {})(ProfilePhotoSelect);