//Library Imports
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import axios from 'axios'
import { withRouter } from 'react-router-dom'

// Redux Actions
import { createForest } from '../../redux/actions/forest'

// Components
import SingleImageUpload from '../../utils/SingleImageUpload';

// Assets
import './modal.css'


const ImageUploadModal = ({ closeModal, parentCallback }) => {

    const [imageUrl, setImageUrl] = useState('')
    const getMainPhoto = (mainPhotoUrl) => {
        setImageUrl(mainPhotoUrl)
    }

    const handleImageAttachment = () => {
        parentCallback(imageUrl)
    }

    useEffect(() => {
        console.log('inside upload modal attachedImageUrl')
        console.log(imageUrl)
    }, [imageUrl])


    return (
        <div className='modal-content-container'>
            <div className='modal-title'>Upload Image</div>
            <div className='modal-description'>Upload an image to attach to your post.</div>

            <div className='modal-content'>
                <SingleImageUpload mainPhotoFunc={getMainPhoto} apiTarget='bark-img-upload' />
            </div>

            <div className='modal-control-container'>
                <button className='modal-cancel-btn clickable' onClick={closeModal}>Cancel</button>
                <button
                    className={imageUrl === '' ? 'modal-continue-btn-inactive' : 'modal-continue-btn clickable'}
                    onClick={() => {
                        if (imageUrl !== '') {
                            handleImageAttachment();
                            closeModal();
                        }
                    }}>
                    Attach to Post
                </button>
            </div>


        </div >
    )
}

const mapStateToProps = state => ({
    forests: state.forest.forests
})

ImageUploadModal.propTypes = {
    createForest: PropTypes.func.isRequired,
    forests: PropTypes.array.isRequired,
}

export default connect(mapStateToProps, { createForest })(withRouter(ImageUploadModal))