// Library Imports
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// Redux Actions
import { getUserPlants, getFieldPlants } from '../../../redux/actions/plant'

// Components
import PlantCard from '../../../components/Cards/PlantCard/PlantCard'

// Assets
import '../css/field-block.css'

const FieldBlock = ({ auth, plant, profile, getUserPlants }) => {

    const [guidePlants, setGuidePlants] = useState([])
    const [fieldPlants, setFieldPlants] = useState([])
    const [newPlants, setNewPlants] = useState([])

    const [currentPlantToAdd, setCurrentPlantToAdd] = useState(null);

    useEffect(() => {
        // Do something ...
    }, [])


    //Once user is established, populate plants within redux
    useEffect(() => {
        getUserPlants(); //Get plants the user owns
    }, [auth.loading]);

    //Once plants have been established, if there are plants to be displayed, grab the plants ready to be displayed.
    useEffect(() => {
        if (plant !== null) {
            preparePlantRenders();
            if (plant.plants !== null) {
                preparePlantRenders();
            }
        } else {
            console.log('user owns no plants.')
        }
    }, [plant])

    //Get Field Plants for user, which are stored in their local profile
    //TODO: Refactor so that we can call the profile with plants populated.
    useEffect(() => {
        if (profile.profile) {
            if (profile.profile.plants.length > 0) {
                for (var i = 0; i < profile.profile.plants.length; i++) {
                    axios.get(`/api/plant/${profile.profile.plants[i]}`)
                        .then(res => {
                            setCurrentPlantToAdd(res.data);
                        }).catch(err => console.log(err))
                }
            }
        }
    }, [profile])

    useEffect(() => {
        if (currentPlantToAdd !== null) {
            let tempFieldPlants = [...fieldPlants, currentPlantToAdd]
            setFieldPlants(tempFieldPlants);
        }
    }, [currentPlantToAdd])


    //Render Functions
    const preparePlantRenders = () => {
        var tempGuidePlants = []
        var tempNewPlants = []
        if (plant.plants !== null) {
            for (var i = 0; i < plant.plants.length; i++) {
                if (plant.plants[i].isSeed) {
                    tempNewPlants.push(plant.plants[i])
                } else {
                    tempGuidePlants.push(plant.plants[i])
                }
            }
            setGuidePlants(tempGuidePlants);
            setNewPlants(tempNewPlants);
            //setUserPlantsLoaded(true);
        } else {
            //console.log('Plants not ready yet')
        }
    }


    // Rendering Plants Logic
    const [renderedPlants, setRenderedPlants] = useState([])

    useEffect(() => {
        let allPlants = [...newPlants, ...guidePlants, ...fieldPlants]

        //Remove Repeated Plants
        let filteredPlants = []
        for (var i = 0; i < allPlants.length; i++) {
            let alreadyInArray = false
            for (var j = 0; j < filteredPlants.length; j++) {
                if (filteredPlants[j]._id === allPlants[i]._id) alreadyInArray = true
            }

            if (!alreadyInArray) filteredPlants.push(allPlants[i])
        }

        setRenderedPlants(filteredPlants);
    }, [fieldPlants, guidePlants, newPlants])


    return (
        <div className='basecamp-fieldblock-container'>
            <div className='block-header'>
                <Link className='link' to="/field">
                    <div className='title'>Your Field</div>
                </Link>

                <div className='block-options'>
                    {/* <ul className='block-options-list'>
                        <li className='block-options-list-item clickable selected'>All</li>
                        <li className='block-options-list-item clickable'>Notifications</li>
                        <li className='block-options-list-item clickable'>Needs Water</li>
                    </ul> */}
                </div>
            </div>
            <div className='field-card-wrapper'>
                <div className='field-plant-card-container'>
                    {renderedPlants.map(item => <PlantCard key={item._id} plantId={item._id} plantName={item.title} plantImg={item.mainImage} seedCard={item.isSeed} />)}
                </div>
            </div>
        </div>
    )
}



const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile,
    plant: state.plant
})

FieldBlock.propTypes = {
    getUserPlants: PropTypes.func.isRequired,
    getFieldPlants: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    plant: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, { getUserPlants, getFieldPlants })(FieldBlock);