// Library Imports
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from "react-router-dom";
import axios from 'axios'
import { connect } from 'react-redux'

// Redux Actions
import { getCurrentPlant } from '../../../redux/actions/plant'

// Components
import PlantGalleryUpload from '../../plant/components/PlantGalleryUpload'
import AdminMenu from '../../plant/components/AdminMenu'

// Assets
import './create-plant.css'
import './edit-plant.css'


const EditPlant = ({ getCurrentPlant }) => {

    //TODO: Create real plant creation logic etc.
    // Plants created from manual plant creation will all be created from a unique ID,
    // as if it was a breeze. But if it's that ID it goes through a different process.
    // Must come from a user who isAdmin.

    //Set Redirect to field after Plant is cultivated
    //TODO: Set to go to plant.
    const history = useHistory();
    const navigateTo = () => history.push('/field');

    //Get Plant on Load
    useEffect(() => {
        axios.get(`/api/plant/${window.location.href.split('?id=')[1]}`).then(res => {
            setCurrentPlant(res.data);
        }).catch(err => console.log(err))

    }, [])

    // This page will always have an ID passed to it, and that is the breeze for which the plant is being created.
    // Information will be pre-populated based on the breeze being put in.
    const emptyPlant = {
        isSeed: false,
        title: '',
        description: '',
        images: [],
        mainImage: '',
        tags: [],
        plantId: window.location.href.split('?id=')[1],
        // owner: '',
        // cultivators: []
    }

    const [currentPlant, setCurrentPlant] = useState(emptyPlant)

    const getGalleryInfo = (galleryInfo) => {
        let tempGalleryInfo = [...galleryInfo]
        let tempPlant = { ...currentPlant }
        tempPlant.images = tempGalleryInfo;
        setCurrentPlant(tempPlant)
    }

    const getMainPhoto = (selectedImage) => {
        let tempMainImage = selectedImage;
        let tempPlant = { ...currentPlant };
        tempPlant.mainImage = tempMainImage;
        setCurrentPlant(tempPlant);
    }

    const getTags = (tags) => {
        let tempTags = tags;
        let tempPlant = { ...currentPlant };
        tempPlant.tags = tempTags;
        setCurrentPlant(tempPlant);
    }

    const handleChange = (e) => {
        let tempPlant = { ...currentPlant, [e.target.name]: e.target.value }
        setCurrentPlant(tempPlant)
    }

    const [plantLocked, togglePlantLocked] = useState(false)
    useEffect(() => {
        currentPlant.noMoreCultivators = plantLocked
    }, [plantLocked])

    const handleCultivatePlant = () => {
        let plantObj = { ...currentPlant }
        plantObj.plantId = currentPlant._id
        axios.post('/api/plant', plantObj).then(res => console.log(res)).catch(err => console.log(err))

        //TODO: Redirect you to the plant page once you've submitted this!
        navigateTo();

    }


    //Populates plant lock status on load
    useEffect(() => {
        togglePlantLocked(currentPlant.noMoreCultivators);
    }, [currentPlant])
    //noMoreCultivators

    return (
        <div className='create-plant-container'>
            <div className='base-camp-static-bg' />

            <div className='create-plant-content content-container'>
                <div className='welcome-title'>Edit Plant</div>

                <div className='sub-content-container'>
                    <div className='input-title-text'>Lock Plant Cultivators</div>
                    <div className='input-description-text'>If you choose to lock this plant, nobody else will be allowed to place it in their field.</div>
                    <div className='input-cultivators-container'>
                        <div className={plantLocked ? 'input-cultivators-option' : 'input-cultivators-option selected'} onClick={() => togglePlantLocked(false)}>Allow People to Add this Plant To Their Field</div>
                        <div className={plantLocked ? 'input-cultivators-option selected' : 'input-cultivators-option'} onClick={() => togglePlantLocked(true)}>Lock Plant</div>
                    </div>
                </div>

                <div className='sub-content-container'>
                    <div className='input-title-text'>Plant Name</div>
                    <div className='input-description-text'>This is the name which will appear on your Plant Cards across SolutionTrees</div>
                    <input type='text' className='create-plant-title-input' name='title' onChange={(e) => handleChange(e)} value={currentPlant.title} />
                </div>
                <div className='sub-content-container'>
                    <div className='input-title-text'>Plant Description</div>
                    <div className='input-description-text'>This is the Summary which will appear on your plant page. Make sure it gives a good idea of what the plant is all about!</div>
                    <textarea className='create-plant-description-input' name='description' onChange={(e) => handleChange(e)} value={currentPlant.description} />
                </div>

                <div className='sub-content-container plant-image-upload-area'>
                    <div className='input-title-text'>Upload Images</div>
                    <div className='input-description-text'>Upload new images which will replace the current plant's image set.</div>
                    <div className='plant-gallery-upload-container'>
                        <PlantGalleryUpload galleryInfoFunc={getGalleryInfo} mainPhotoFunc={getMainPhoto} />
                    </div>
                </div>

                <div className='sub-content-container plant-category-tag'>
                    <div className='input-title-text'>Choose Tags</div>
                    <div className='input-description-text'>Choosing relevant categories for your plant ensures it appears in users' feeds who are likely to be interested in it.</div>
                    <AdminMenu tagFunc={getTags} />
                </div>

                <div className='continue-btn clickable' onClick={() => handleCultivatePlant()}>Save Changes to Plant</div>

            </div>

        </div>
    )
}

EditPlant.propTypes = {
    getCurrentPlant: PropTypes.func.isRequired,

}

const mapStateToProps = state => ({
    forest: state.forest,
    posts: state.posts.posts,
    auth: state.auth,
    profile: state.profile,
})


export default connect(mapStateToProps, { getCurrentPlant })(EditPlant);