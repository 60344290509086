// Library Imports
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import Moment from 'react-moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

// Components
import PlantCard from '../../components/Cards/PlantCard/PlantCard'
import Navbar from '../../components/Navbar/Navbar'

// Assets
import '../basecamp/css/your-latest-breezes.css'
import './breeze-history.css'




const BreezeHistory = ({ auth }) => {

    //Set user breezes on load
    const [userBreezes, setUserBreezes] = useState([])
    const [userId, setUserId] = useState('')

    const [successfulPlants, setSuccessfulPlants] = useState([])
    const [currentPlantToAdd, setCurrentPlantToAdd] = useState(null);

    useEffect(() => {
        if (userId !== '') {
            axios.get(`/api/posts/latestBreezes/${userId}`).then(res => {
                setUserBreezes(res.data);
            }).catch(err => console.log(err))
        }
    }, [userId])


    useEffect(() => {
        if (auth.user !== null && auth.user._id !== undefined) {
            setUserId(auth.user._id)
        }
    }, [auth])

    useEffect(() => {
        console.log('gettin breezes?')
        if (userBreezes.length > 0) {
            console.log('gettin breezes!!!')
            for (var i = 0; i < userBreezes.length; i++) {
                axios.get(`/api/plant/byBreeze/${userBreezes[i]._id}`).then(res => {
                    setCurrentPlantToAdd(res.data)
                }).catch(err => console.log(err))
            }
        }
    }, [userBreezes])


    useEffect(() => {
        console.log('addin plant!')
        if (currentPlantToAdd !== null) {
            let tempSuccessPlants = [...successfulPlants, currentPlantToAdd]
            setSuccessfulPlants(tempSuccessPlants);
            console.log(tempSuccessPlants)
        }
    }, [currentPlantToAdd])



    return (
        <div className='breeze-history-container content-container'>
            <div className='base-camp-static-bg' />
            <Navbar />

            <a href={`/basecamp`}><div className='back-to-profile-btn'><FontAwesomeIcon icon={faArrowLeft} /> Back to Basecamp</div></a>
            <div className='breeze-history-item-container'>
                {userBreezes.map(item => {
                    return (
                        <div className='breeze-history-item sub-content-container' key={item._id}>
                            <div><Moment className='time' format="LLL">{item.date}</Moment></div>
                            <div className='breeze-history-item-text'>"{item.text}"</div>
                            <div className='seed-plant-status-container'>

                                {successfulPlants.filter(p => p.breeze.toString() === item._id.toString()).length > 0 ?
                                    <>
                                        {successfulPlants.filter(p => p.breeze.toString() === item._id.toString())[0].isSeed
                                            && successfulPlants.filter(p => p.breeze.toString() === item._id.toString())[0].owner !== userId ?
                                            <div className='seed-status-desc dropped'>Seed has dropped and is pending cultivation from another tree!</div>
                                            :
                                            <PlantCard
                                                key={successfulPlants.filter(p => p.breeze.toString() === item._id.toString())[0]._id}
                                                plantId={successfulPlants.filter(p => p.breeze.toString() === item._id.toString())[0]._id}
                                                plantName={successfulPlants.filter(p => p.breeze.toString() === item._id.toString())[0].title}
                                                plantImg={successfulPlants.filter(p => p.breeze.toString() === item._id.toString())[0].mainImage}
                                                seedCard={successfulPlants.filter(p => p.breeze.toString() === item._id.toString())[0].isSeed}
                                            />
                                        }

                                    </>


                                    :
                                    item.breezeFailed ?
                                        <div className='seed-status-desc failed'>This Breeze failed to gain enough support to become a seed.</div> :
                                        <div className='seed-status-desc'>This Breeze is still blowing through the forest...</div>
                                }
                            </div>
                            <div className='breeze-stats-container'>

                                <div className='breeze-stats-item sub-content-container'>
                                    <div className='breeze-stats-item-title'>Target Forests</div>
                                    <div className='breeze-stats-item-content'>
                                        <ul className='breeze-stats-targetforests-list'>
                                            {item.targetForests.map(item => {
                                                return <li className='breeze-stats-targetforests-list-item'>{item.title}</li>
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className='breeze-stats-item sub-content-container'>
                                    <div className='breeze-stats-item-title'>Seed Drop Conditions:</div>
                                    <div className='breeze-stats-item-content'>
                                        <div>Forest Support: {Math.ceil(item.forestSupportLimit)} Trees</div>
                                        <div>Outside Forest Support: {Math.ceil(item.othersSupportLimit)} Trees</div>
                                    </div>
                                </div>
                                <div className='breeze-stats-item sub-content-container'>
                                    <div className='breeze-stats-item-title'>Responses</div>
                                    <div className='breeze-stats-item-content'>
                                        <div>Total Responses: {item.supportingUsers.length + item.downvoteUsers.length} / {item.targetForestUsers.length + item.targetOtherUsers.length}</div>
                                        <div>Total Supporting Trees: {item.supportingUsers.length} </div>
                                        <div>Support Ratio: {Math.floor((item.supportingUsers.length / (item.supportingUsers.length + item.downvoteUsers.length)) * 100)}%</div>
                                        <div>From Forests: {item.supportingUsers.filter(r => item.targetForestUsers.includes(r)).length} / {Math.ceil(item.forestSupportLimit)}</div>
                                        <div>From Others: {item.supportingUsers.filter(r => item.targetOtherUsers.includes(r)).length} / {Math.ceil(item.othersSupportLimit)}</div>
                                    </div>
                                </div>
                            </div>



                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
})


export default connect(mapStateToProps, {})(BreezeHistory);