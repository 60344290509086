// Library Imports
import React, { useState, useEffect } from 'react'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import axios from 'axios'

// Components
import AdminMenu from '../../plant/components/AdminMenu'
import SingleImageUpload from '../../../utils/SingleImageUpload';
import Navbar from '../../../components/Navbar/Navbar'

// Assets
import './create-bark-post.css'

const CreateBarkPost = ({ auth }) => {

    //Set Redirect to field after Post is made
    const [currentUserId, setCurrentUserId] = useState('none')
    const history = useHistory();
    const navigateTo = () => history.push(`/profile/?id=${currentUserId}`);

    useEffect(() => {
        if (auth.user !== null) {
            setCurrentUserId(auth.user._id)
        }
    }, [auth.loading])

    //If there is no eventDate for a bark post, use date, which is the date the post was created.
    const emptyBarkPost = {
        user: currentUserId,
        isMushroom: false,
        visibleTo: [],
        showPost: true,
        title: '',
        text: '',
        attachment: '',
        tags: [],
    }

    const handleMakePost = () => {
        let barkObj = { ...currentBarkPost }
        barkObj.user = currentUserId
        if (selectedPrivacyOption === 'private') {
            barkObj.isMushroom = true
            barkObj.visibleTo = [currentUserId]
        }

        if (barkObj.tags.length < 1) {
            barkObj.tags = ['---']
        }

        // console.log('Making Post:')
        // console.log(barkObj)

        axios.post('/api/posts/bark', barkObj).then(res => {

            //If user is not posting as mushroom, push post to forest feeds.
            if (!barkObj.isMushroom) {

                let barkFeedPost = {
                    user: auth.user._id,
                    name: auth.user.name,
                    avatar: auth.user.avatar,
                    text: res.data.title + '_bark_' + res.data.text,
                    targetPlant: auth.user._id,
                    postType: 'barkFeedPost',
                    isMushroom: false,
                    attachedImage: res.data.attachment,
                    taggedUsers: [],
                    barkPostRef: res.data._id
                }

                //console.log(barkFeedPost)

                axios.post('/api/posts/feedbark', barkFeedPost).then(res => {
                    //console.log(res)
                }).catch(err => console.log(err))

            }


            //console.log(res)
            navigateTo();
        }).catch(err => console.log(err))

    }

    const [currentBarkPost, setCurrentBarkPost] = useState(emptyBarkPost)
    const [selectedPrivacyOption, setSelectedPrivacyOption] = useState('public')

    const getMainPhoto = (selectedImage) => {
        let tempMainImage = selectedImage;
        let tempBarkPost = { ...currentBarkPost };
        tempBarkPost.attachment = tempMainImage;
        setCurrentBarkPost(tempBarkPost);
    }

    const getTags = (tags) => {
        let tempTags = tags;
        let tempBarkPost = { ...currentBarkPost };
        tempBarkPost.tags = tempTags;
        setCurrentBarkPost(tempBarkPost);
    }

    const handleChange = (e) => {
        let tempBarkPost = { ...currentBarkPost, [e.target.name]: e.target.value }
        setCurrentBarkPost(tempBarkPost)
    }

    useEffect(() => {
        //console.log(currentBarkPost);

    }, [currentBarkPost])



    return (
        <div className='create-bark-container'>
            <div className='base-camp-static-bg' />
            {/* <div className='white-bg' /> */}
            <Navbar />

            <div className='create-bark-content content-container'>

                <div className='welcome-container sub-content-container'>
                    <div className='welcome-title'>Create Bark Post</div>
                    <div className='bark-welcome-description'>Customize your tree with some bark! Choose which content you'd like people to see when they visit your tree profile.</div>
                </div>


                <div className='sub-content-container'>
                    <div className='input-title-text'>Post Title</div>
                    <div className='input-description-text'>This is the title which will appear at the top your Bark Card.</div>
                    <input type='text' className='create-bark-title-input' name='title' onChange={(e) => handleChange(e)} />
                </div>
                <div className='sub-content-container'>
                    <div className='input-title-text'>Post Contents</div>
                    <div className='input-description-text'>This is the text that will appear on your post (you can leave this blank if you like).</div>
                    <textarea className='create-bark-description-input' name='text' onChange={(e) => handleChange(e)} />
                </div>

                <div className='sub-content-container create-bark-date-container'>
                    <div className='input-title-text'>Post Date</div>
                    <div className='input-description-text'>If you're posting about a past event, let your fellow trees know when it happened. If you skip this, the date will default to today.</div>
                    <input type='date' className='create-bark-date-input' name='eventDate' onChange={(e) => handleChange(e)} />
                </div>

                <div className='sub-content-container bark-image-upload-area'>
                    <div className='input-title-text'>Upload Image</div>
                    <div className='input-description-text'>Use this space to upload an image if you'd like to do so.</div>
                    <div className='bark-image-upload-container'>
                        <SingleImageUpload mainPhotoFunc={getMainPhoto} apiTarget='bark-img-upload' />
                    </div>
                </div>

                <div className='sub-content-container bark-category-tag'>
                    <div className='input-title-text'>Choose Tags</div>
                    <div className='input-description-text'>Your post will have a small tag, indicating what type of post it is. Choose one which represents your post!</div>
                    <AdminMenu tagFunc={getTags} />
                </div>

                <div className='sub-content-container bark-category-tag'>
                    <div className='input-title-text'>Privacy Settings</div>
                    <div className='input-description-text'>Your post can be visible to everyone, or you can make a private post users must request to see. Choose one!</div>
                    <div className='bark-privacy-select-container'>
                        <div
                            className={selectedPrivacyOption === 'public' ? 'bark-privacy-option selected' : 'bark-privacy-option'}
                            onClick={() => setSelectedPrivacyOption('public')}
                        >Visible to Public</div>
                        <div
                            className={selectedPrivacyOption === 'private' ? 'bark-privacy-option selected' : 'bark-privacy-option'}
                            onClick={() => setSelectedPrivacyOption('private')}
                        >Appears as Mushroom<br /> (users will have to request to view)</div>
                    </div>
                </div>

                <div className='continue-btn clickable' onClick={() => handleMakePost()}>Add Bark to Tree</div>

            </div>

        </div>
    )
}


const mapStateToProps = state => ({
    auth: state.auth,
})

CreateBarkPost.propTypes = {
    auth: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, {})(CreateBarkPost);