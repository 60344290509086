import React from 'react'
import NetworkPhoto from '../images/Network_Photo.png'
import SolutionTreesGreenLogo from '../images/Solutiontrees Logo_Green_1000px.png'

import RecordBulb from '../images/RecordBulb.png'
import Canteen from '../images/Canteen.png'
import EarBulb from '../images/EarBulb.png'
import FlowerBulb from '../images/FlowerBulb.png'

import '../css/sign-up-header.css'

export default function SignUpHeader() {
    return (
        <div className="sign-up-header-container">
            <div className='static-bg'></div>
            <div className="header-logo">
                <img className="header-logo-img" alt='SolutionTrees Logo' src={SolutionTreesGreenLogo} />
            </div>
            <div>
                <a href='/signin'><div className='continue-btn clickable'>Click Here to Sign In</div></a>
            </div>

            <div className="header-caption-item img-left">
                <div className="header-caption-img-container">
                    <img className="header-caption-img" alt='Make a Difference' src={RecordBulb} height='100px' />
                </div>
                <div className="header-caption-item-description-right">A place for people who want to <b>make a difference</b>, but aren't sure where to start.</div>
            </div>

            <div className="header-caption-item highlight img-right">
                <div className="header-caption-item-description-left">A place where your idea can garner the <b>support</b> it needs to scale beyond your bedroom.</div>
                <div className="header-caption-img-container">
                    <img className="header-caption-img" alt='Share Ideas' src={Canteen} height='100px' />
                </div>
            </div>

            <div className="header-caption-item img-left">
                <div className="header-caption-img-container" >
                    <img className="header-caption-img" alt='Collaboration' src={EarBulb} height='100px' />
                </div>
                <div className="header-caption-item-description-right">A place for companies and individuals who would rather hear <b>collaboration</b>, than competition.</div>
            </div>

            <div className="header-caption-item highlight img-right">
                <div className="header-caption-item-description-left">A place for ideas to be shared, solutions to be explored, and changes to be made in real life, and the digital world.</div>
                <div className="header-caption-img-container">
                    <img className="header-caption-img" alt='Amplify Humans' src={FlowerBulb} height='100px' />
                </div>
            </div>

            <div className="header-mission-statement-container">
                <div className="header-mission-statement">Our Mission</div>
                {/* <div className='video-container'>[Video Container]</div> */}
                <div className="header-mission-statement-description">
                    <p>Our mission is to provide a place where the individual can connect to the true power behind social media- the power of the people.</p>
                    <p>While other social media platforms can overload users with information and develop a false digital narrative, the ingenuity of SolutionTrees is in the way it gives the power directly to you.  We guide you to organize, prioritize, and categorize your social interactions online. We give you a digital space to ask questions, dialogue, express yourself without judgement or comment, be vulnerable knowing that anonymity is an option, explore your duality, and so much more.</p>
                    <p>Our goal is to provide people, our forest, the tree, you, an experience that ignites creativity and a sense of purpose. With deep roots, transparency, and trust, we will grow.</p>
                    <p>It is our belief that change happens in communities and local connections. We streamline you to the people and organizations in your area that match your passions and life goals.</p>
                    <p>Through your own personalization and selections, you can voice your opinions, bring light to problems you see in your community, and open a productive dialogue to find solutions.</p>
                </div>


            </div>
            <div className="finish-line-container">
                <p>Join Thousands of others who seek to make the world a better place--one solution at a time.</p>
                <div className="finish-line-img-container">
                    <img src={NetworkPhoto} alt='Network' />
                    <div className="header-finish-line">Because supported<br></br>ideas bloom</div>
                    <div className="header-finish-line-emphasis">change.</div>
                </div>
            </div>
        </div>
    )
}
