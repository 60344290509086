/* eslint-disable */

import React from 'react';
import { useCanvas } from './useCanvas';

export default function RootsCanvas() {

    const [coordinates, setCoordinates, clickedCoordinates, setClickedCoordinates, canvasRef, canvasWidth, canvasHeight] = useCanvas();
    const center_x = canvasWidth / 2;
    const center_y = canvasHeight / 2;

    const handleCanvasClick = (event) => {
        const currentCoord = { x: event.clientX, y: event.clientY };
        setClickedCoordinates([currentCoord]);
    };

    const handleMouseMove = (e) => {
        const currentCoord = { x: e.clientX, y: e.clientY };
        setCoordinates([currentCoord]);
    }

    return (
        <div className="roots-canvas-container" >
            <canvas
                className="roots-canvas"
                ref={canvasRef}
                width={canvasWidth}
                height={canvasHeight}
                onClick={handleCanvasClick}
                onMouseMove={handleMouseMove} />
        </div>
    );

};