import axios from 'axios'

//Currently unused, might use in the future.

//Create a notification, and dispatch redux update if necessary.

export const createNotification = (notificationObj) => async dispatch => {

    //Notification Object should contain:
    //sourceUser:'',//Source User Id
    //sourceUserName:'',//Source User Name
    //sourceUserAvatar:'',//Source User Avatar
    //targetUser:'',//Target User Id
    //notificationType:'',//Notification type
    //targetPost:'',//Target Post Id
    //targetName:'',//Target Post Title

    axios.post('/api/notifications/', notificationObj)

}

// Potentially try this syntax:     //      = () => async dispatch => {

export const notifyBreezeSupportersPlantCultivated = async (baseObj, originalBreezeId) => {
    try {
        const res = await axios.get(`/api/posts/${originalBreezeId}`)
        let targetArray = res.data[0].supportingUsers

        for (var i = 0; i < targetArray.length; i++) {
            let notificationObj = { ...baseObj }
            notificationObj.targetUser = targetArray[i]
            axios.post('/api/notifications/', notificationObj)
        }

    } catch (err) {
        console.log(err)
    }
}

export const notifyOwnerPlantAddedToField = () => {

}

export const notifyPostTag = () => {

}

export const notifyPostEngagement = () => {

}