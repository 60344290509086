import React, { useState, useEffect } from 'react'
import Moment from 'react-moment'
import axios from 'axios'
import { useSelector } from 'react-redux'

import './reply-chain.css'
import { ConnectContactLens } from 'aws-sdk';

export default function ReplyChain({ autoExpanded = false,
    comments,
    postId,
    profile,
    postUserId,
    targetPlant,
    currentUserAvatar }) {

    const currentUser = useSelector((state) => state.auth.user);

    const [commentInput, setCommentInput] = useState("");
    const [inputRowCount, setInputRowCount] = useState(1)
    const [characterLimit, setScaleCharacterLimit] = useState(40)
    const handleChange = (e) => {
        if (window.innerWidth < 720) {
            if (e.target.value.length > characterLimit) {
                setScaleCharacterLimit(characterLimit + 40)
                setInputRowCount(inputRowCount + 2)
            }
        }
        setCommentInput(e.target.value);
    };


    const [currentlyMushroomMode, setCurrentlyMushroomMode] = useState(false)
    useEffect(() => {
        if (profile.profile !== null && profile.profile !== undefined) {
            setCurrentlyMushroomMode(profile.profile.mushroomMode)
        }
    }, [profile])


    const [commentsForDisplay, setCommentsForDisplay] = useState([])
    useEffect(() => {
        if (comments.length > 0) {
            if (autoExpanded) {
                setViewAllComments(true)
                setCommentsForDisplay(comments)
            } else {
                setCommentsForDisplay([comments[0]])
            }
        }
    }, [comments])

    const [currentTime, setCurrentTime] = useState('0:00:00')
    useEffect(() => {
        var d = new Date();
        var n = d.getTime();
        setCurrentTime(n)
    }, [])

    //Logic for expanding to show all comments.
    const [viewAllComments, setViewAllComments] = useState(false)
    const handleViewAll = () => {
        setViewAllComments(true)
        setCommentsForDisplay(comments)
    }

    const [currentlyActiveUser, setCurrentlyActiveUser] = useState({
        _id: '',
        name: '',
        avatar: ''
    })

    useEffect(() => {
        if (currentUser!=null){
            setCurrentlyActiveUser(currentUser)
        }
    }, [currentUser])

    useEffect(() => {
        let tempComments = [...commentsForDisplay]
        setCommentsForDisplay([])
        setCommentsForDisplay(tempComments)
    }, [currentlyActiveUser])



    //Handle Delete
    const handleDelete = (id) => {
        var r = window.confirm("Are you sure you'd like to delete this comment? Press OK to delete. This cannot be undone.");
        if (r === true) {
            //Hide Comment
            axios.put(`/api/posts/comment/delete/${id}`).then(res => {
                console.log(res)

                //Update UI
                window.location.reload(false)

            }).catch(err => console.log(err))

        }
    }


    const handleNewComment = () => {
        let commentObj = {
            user: currentUser._id,
            name: currentUser.name,
            avatar: currentUser.avatar,
            targetPost: postId,
            text: commentInput,
            isMushroom: currentlyMushroomMode, //Whether or not the post should be considered a mushroom post or not (hide user information if true)
        }

        //axios post request.
        console.log('commentObj')
        console.log(commentObj)
        axios.post(`/api/posts/comment/${postId}`, commentObj).then(res => {
            console.log(res)

            //Send Notification if not on our own post.
            if (currentUser._id !== postUserId) {

                let notificationObj = {}

                if (currentlyMushroomMode) {
                    notificationObj = {
                        sourceUser: currentUser._id,
                        sourceUserName: "Anonymous Mushroom",
                        sourceUserAvatar: "https://freevintageillustrations.com/wp-content/uploads/2017/11/mushroom-illustrations-public-domain-19th-century.jpg",
                        targetUser: postUserId,
                        notificationType: 'userCommentedOnPost',
                        targetPost: postId,
                        targetName: '',
                    }
                } else {
                    notificationObj = {
                        sourceUser: currentUser._id,
                        sourceUserName: currentUser.name,
                        sourceUserAvatar: currentUser.avatar,
                        targetUser: postUserId,
                        notificationType: 'userCommentedOnPost',
                        targetPost: postId,
                        targetName: '',
                    }
                }


                //console.log(notificationObj);
                axios.post('/api/notifications/', notificationObj).then(res => console.log(res)).catch(err => console.log(err))

            }


            setCommentInput('')

            //Update UI
            let tempNewComments = [...commentsForDisplay, res.data]
            setCommentsForDisplay(tempNewComments)


        }).catch(err => console.log(err))




    }

    const calculateTimeSince = (inputDate) => {
        var d = new Date(inputDate);
        var n = d.getTime();
        var seconds = Math.floor((currentTime - n) / 1000)

        var interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) + " years ago";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " months ago";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " days ago";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hours ago";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " minutes ago";
        }
        if (isNaN(seconds)) {
            return "Just Now";
        }
        return Math.floor(seconds) + " seconds ago";
    }





    ///Handle CommentBlossom / CommentThorn Logic.
    const handleBlossom = (id) => {
        axios.put(`/api/posts/comment/blossom/${id}`)
            .then((response) => {
                //Log Successful Response
                //console.log(response)

                //Update UI by updating comments for display with the new data
                let tempComments = [...commentsForDisplay]
                for (var i = 0; i < tempComments.length; i++) {
                    if (tempComments[i]._id === response.data._id) {
                        tempComments[i] = response.data
                    }
                }
                setCommentsForDisplay(tempComments);


                //Send Notification
                if (response.data.user !== currentUser._id) {
                    let notificationObj = {
                        sourceUser: currentUser._id,
                        sourceUserName: currentUser.name,
                        sourceUserAvatar: currentUser.avatar,
                        targetUser: response.data.user,
                        notificationType: 'userBlossomedComment',
                        targetPost: postId,
                        targetName: 'comment',
                    }
                    //console.log(notificationObj);
                    axios.post('/api/notifications/', notificationObj).then(res => console.log(res)).catch(err => console.log(err))
                }


            })
            .catch((error) => {

                //If error was that we've already blossomed this post, send an unblossom
                if (error.response.status === 400) {
                    console.log('already blossomed post! Running unblossom')
                    axios.put(`/api/posts/comment/unblossom/${id}`)
                        .then((response) => {
                            //Log Successful Response
                            console.log('res', response);

                            //Update UI
                            //--Blossom Count
                            //Update UI by updating comments for display with the new data
                            let tempComments = [...commentsForDisplay]
                            for (var i = 0; i < tempComments.length; i++) {
                                if (tempComments[i]._id === response.data._id) {
                                    tempComments[i] = response.data
                                }
                            }
                            setCommentsForDisplay(tempComments);


                            //Handle Deletion of Notification
                            //2-18 TODO: Handle Deletion of Notification

                        })
                        .catch((error) => {
                            // If another error
                            console.log(error)
                        })
                } else {
                    // If error was due to any other reason
                    console.log(error.response.status)
                }

            });
    }
    const handleThorn = (id) => {
        axios.put(`/api/posts/comment/thorn/${id}`)
            .then((response) => {
                //Log Successful Response
                console.log('res', response);

                //Update UI
                //Update UI by updating comments for display with the new data
                let tempComments = [...commentsForDisplay]
                for (var i = 0; i < tempComments.length; i++) {
                    if (tempComments[i]._id === response.data._id) {
                        tempComments[i] = response.data
                    }
                }
                setCommentsForDisplay(tempComments);

                //Send Notification
                if (response.data.user !== currentUser._id) {
                    let notificationObj = {
                        sourceUser: currentUser._id,
                        sourceUserName: currentUser.name,
                        sourceUserAvatar: currentUser.avatar,
                        targetUser: response.data.user,
                        notificationType: 'userThornedComment',
                        targetPost: postId,
                        targetName: 'comment',
                    }
                    //console.log(notificationObj);
                    axios.post('/api/notifications/', notificationObj).then(res => console.log(res)).catch(err => console.log(err))
                }

            })
            .catch((error) => {

                //If error was that we've already thorned this post, send an unthorn
                if (error.response.status === 400) {
                    console.log('already thorned post! Running unthorn')
                    axios.put(`/api/posts/comment/unthorn/${id}`)
                        .then((response) => {
                            //Log Successful Response
                            console.log('res', response);

                            //Update UI
                            //Update UI by updating comments for display with the new data
                            let tempComments = [...commentsForDisplay]
                            for (var i = 0; i < tempComments.length; i++) {
                                if (tempComments[i]._id === response.data._id) {
                                    tempComments[i] = response.data
                                }
                            }
                            setCommentsForDisplay(tempComments);

                            //Handle Deletion of Notification
                            //2-18 TODO: Handle Deletion of Notification

                        })
                        .catch((error) => {
                            // If another error
                            console.log(error)
                        })
                } else {
                    // If error was due to any other reason
                    console.log(error.response.status)
                }

            });
    }





    return (
        <div className='reply-chain-container'>

            {/* comments area */}
            <div className='comment-area'>

                {commentsForDisplay.map(item => {
                    let userBlossomed = false
                    let userThorned = false

                    for (var i = 0; i < item.blossoms.length; i++) {
                        if (currentlyActiveUser._id === item.blossoms[i].user) {
                            userBlossomed = true
                        }
                    }

                    for (var i = 0; i < item.thorns.length; i++) {
                        if (currentlyActiveUser._id === item.thorns[i].user) {
                            userThorned = true
                        }
                    }

                    return (
                        <div className='individual-comment'>

                            <img className='individual-comment-photo' alt='profileimg' src={item.avatar} />

                            <div className='individual-comment-body'>
                                <div className='individual-comment-body-name'>
                                    {item.name}
                                </div>

                                <div className='individual-comment-body-text'>
                                    {item.text}
                                </div>


                                <div className='individual-comment-body-actions'>
                                    {currentlyActiveUser._id === item.user ?
                                        <>
                                            <span onClick={() => { handleBlossom(item._id) }}
                                                className={userBlossomed
                                                    ? 'blossomed-comment-text'
                                                    : ''}
                                            >
                                                {item.blossoms.length} Blossom
                                </span> | <span onClick={() => handleThorn(item._id)}
                                                className={userThorned
                                                    ? 'thorned-comment-text'
                                                    : ''}
                                            >
                                                {item.thorns.length} Thorn
                                </span> | <span className='no-underline no-pointer'>{calculateTimeSince(item.date)}</span>
                                            <br />
                                            <span onClick={() => handleDelete(item._id)}>
                                                Delete Comment
                                            </span>
                                        </>
                                        :
                                        <>
                                            <span onClick={() => { handleBlossom(item._id) }}
                                                className={userBlossomed
                                                    ? 'blossomed-comment-text'
                                                    : ''}
                                            >
                                                {item.blossoms.length} Blossom
                                        </span> | <span onClick={() => handleThorn(item._id)}
                                                className={userThorned
                                                    ? 'thorned-comment-text'
                                                    : ''}
                                            >
                                                {item.thorns.length} Thorn
                                        </span> | <span className='no-underline no-pointer'>{calculateTimeSince(item.date)}</span>
                                        </>
                                    }

                                </div>

                            </div>
                        </div>
                    )
                })}

                {comments.length > 1 && !viewAllComments ?
                    <div className='view-more-container'>
                        <div className='view-more-btn' onClick={() => handleViewAll()}>
                            View All Comments
                        </div>
                    </div>
                    :
                    <></>
                }

            </div>

            {/* write comment area */}
            <div className='response-box'>
                <img
                    className='individual-comment-photo'
                    alt='profileimg'
                    src={currentlyMushroomMode ?
                        'https://freevintageillustrations.com/wp-content/uploads/2017/11/mushroom-illustrations-public-domain-19th-century.jpg'
                        : currentUser ? currentUser.avatar : ''} />
                <textarea
                    className='make-comment-text-input'
                    rows={inputRowCount}
                    style={{ resize: 'none' }}
                    placeholder="Say Something..."
                    value={commentInput}
                    onChange={(e) => handleChange(e)}
                />
                <div className='post-comment-btn' onClick={() => handleNewComment()}>{currentlyMushroomMode ? 'Post As Mushroom' : 'Post'}</div>
            </div>

        </div >
    )
}
