// Library Imports
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { connect, useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlag } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-modal'

// Redux Actions
import { getUserForests } from '../../../redux/actions/forest'
import { makePost, getPosts } from '../../../redux/actions/posts'
import { getCurrentProfile } from '../../../redux/actions/profile'
import { getLatestBreezes } from '../../../redux/actions/breezes'

// Components
import BreezeIcon from '../../basecamp/images/breeze-icon.png'
import BreezeModal from '../../../components/Modals/BreezeModal'

// Assets
import '../css/breeze-block.css'
import '../css/forest-block.css'
import BreezeDisplay from './BreezeDisplay'


const BreezeBlock = ({ getUserForests, forest, makePost, getPosts, posts, profile, getCurrentProfile, auth }) => {

    // REDUX
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.auth.user)
    const breezesLoading = useSelector(state => state.breezes.latestBreezesLoading)
    const latestBreezes = useSelector(state => state.breezes.latest)

    // Use effect once we've got a user to grab the breezes
    useEffect(() => {
        if (currentUser!=null){
            dispatch(getLatestBreezes(currentUser._id));
        }
    }, [currentUser])

    // Populate Breezes once they are loaded
    const [currentBreezes, setCurrentBreezes] = useState([])

    useEffect(() => {
        if (!breezesLoading){
            console.log('Breezes stopped loading')
            if (latestBreezes.length>0) {
                setAllBreezes(latestBreezes)
                setCurrentlyDisplayedBreeze('"' + latestBreezes[0].text + '"')
            } else {
                setCurrentlyDisplayedBreeze('No more new breezes!')
            }
        }
    }, [breezesLoading])


    

    //Determine whether or not profile is currently in mushroom mode.
    const [postAsMushroom, togglePostAsMushroom] = useState(false);
    useEffect(() => {
        if (profile.profile) {
            togglePostAsMushroom(profile.profile.mushroomMode);
        }
    }, [profile]);


    const [characterCount, setCharacterCount] = useState(0);
    const [breezeInput, setBreezeInput] = useState("");

    const handleChange = (event) => {
        setBreezeInput(event.target.value);

        if (breezeInput.length < 140) {
            setCharacterCount(breezeInput.length);
        }
        else {
            setBreezeInput(breezeInput.substring(0, 139));
            setCharacterCount(event.target.value.length);
        }

    };


    //Get Breeze Data
    const [allBreezes, setAllBreezes] = useState([]);
    const [currentlyDisplayedBreeze, setCurrentlyDisplayedBreeze] = useState('No more new breezes!')
    const [displayBreezeIndex, setDisplayBreezeIndex] = useState(0)
    


    //Change Post Id on initial displayed breeze.
    useEffect(() => {
        if (allBreezes[displayBreezeIndex] !== undefined) setCurrentPostId(allBreezes[displayBreezeIndex]._id)
    }, [currentlyDisplayedBreeze]);

    const handleBreezeInteraction = (type) => {
        if (type === 'support') {
            handleSupportBreeze();
        } else if (type === 'downvote') {
            handleStopBreeze();
        } else if (type === 'report') {
            axios.put(`/api/posts/hide/${currentPostId}`) //Hide Breeze from all feeds
        } else {

        }
        setDisplayBreezeIndex(displayBreezeIndex + 1);
    }

    const [currentPostId, setCurrentPostId] = useState(0)

    useEffect(() => {
        if (displayBreezeIndex < allBreezes.length) {
            setCurrentlyDisplayedBreeze('"' + allBreezes[displayBreezeIndex].text + '"')
        } else {
            setCurrentlyDisplayedBreeze('No more new breezes!')
        }

    }, [displayBreezeIndex]);



    //TODO: Breeze Seed Dropping Stuff
    const [postedABreeze, setPostedABreeze] = useState(false)
    const breezeHasPosted = () => {
        setBreezeInput("");
        setCharacterCount(0);
        setPostedABreeze(true)
    }


    const handleSupportBreeze = () => { 
        axios.put(`/api/posts/breeze/support/${currentPostId}`)
            .then(res => {
                //Handle Seed Drop

                //If supporting users is longer than forest support limit, we can check if we should seed drop
                if (res.data.supportingUsers.length > res.data.forestSupportLimit) {
                    //Determine outside vs inside support
                    let othersSupportCount = 0
                    for (let i = 0; i < res.data.supportingUsers.length; i++) {
                        if (res.data.targetOtherUsers.indexOf(res.data.supportingUsers[i]) !== -1) othersSupportCount++;
                    }

                    //Determine outside vs inside downvotes
                    let othersDownvoteCount = 0
                    for (let i = 0; i < res.data.downvoteUsers.length; i++) {
                        if (res.data.targetOtherUsers.indexOf(res.data.downvoteUsers[i]) !== -1) othersDownvoteCount++;
                    }

                    //Collectively determine if the seed will drop based on rules
                    if ( // If nobody has interacted with it (counts as 100% positive response rate), or Others' support ratio of received responses is above limit
                        ((othersSupportCount === 0 && othersDownvoteCount === 0)
                            || othersSupportCount / (othersDownvoteCount + othersSupportCount) > res.data.othersSupportLimit)
                        && (res.data.supportingUsers.length - othersSupportCount > res.data.forestSupportLimit) //Isolated forest support is above forest support limit
                    ) {
                        //Handle Seed Drop
                        const seedData = {
                            title: 'New Seed Dropped!',
                            description: res.data.text,
                            breeze: res.data._id,
                            breezeText: res.data.text,
                            owner: res.data.user,
                            showPlant: false
                        }

                        //Post notification to originator of breeze that the seed has dropped
                        let notificationObj = {
                            sourceUser: auth.user._id,
                            sourceUserName: auth.user.name,
                            sourceUserAvatar: auth.user.avatar,
                            targetUser: res.data.user,
                            notificationType: 'seedDrop',
                            targetName: res.data.text,
                        }

                        //Create Plant; hidden until it is cultivated.
                        //Plant response is taken to create notification for user
                        axios.post('/api/plant/', seedData).then(plantResponse => {
                            notificationObj.targetPost = plantResponse.data._id
                            axios.post('/api/notifications/', notificationObj)
                        }).catch(err => console.log(err))

                        //Hide Breeze from all feeds
                        axios.put(`/api/posts/hide/${res.data._id}`)
                    }
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    console.log(error.response.status, 'already supported breeze')
                } else {
                    console.log(error)
                }

            });
    }

    const handleStopBreeze = () => {
        axios.put(`/api/posts/breeze/downvote/${currentPostId}`)
            .then(res => {
                console.log(res); //Log Successful Response

                // This could be fleshed out in any number of ways.
                // Simple for now; room for expansion.
                console.log('about to check if failed seed:')

                //Determine outside vs inside support and downvotes
                let othersSupportCount = 0
                for (let i = 0; i < res.data.supportingUsers.length; i++) {
                    if (res.data.targetOtherUsers.indexOf(res.data.supportingUsers[i]) !== -1) othersSupportCount++;
                }
                let othersDownvoteCount = 0
                for (let i = 0; i < res.data.downvoteUsers.length; i++) {
                    if (res.data.targetOtherUsers.indexOf(res.data.downvoteUsers[i]) !== -1) othersDownvoteCount++;
                }

                let forestSupportCount = res.data.supportingUsers.length - othersSupportCount;
                let forestDownvoteCount = res.data.downvoteUsers.length - othersDownvoteCount;
                let remainingForestResponses = res.data.targetForestUsers.length - (forestSupportCount + forestDownvoteCount)
                let remainingOtherResponses = res.data.targetOtherUsers.length - (othersSupportCount + othersDownvoteCount)

                //If we are out of responses and didn't hit the threshold, we die.
                if (remainingForestResponses < res.data.forestSupportLimit - forestSupportCount
                    || remainingOtherResponses < res.data.othersSupportLimit - othersSupportCount) {
                    //Handle Seed Fail
                    axios.put(`/api/posts/breezeFail/${res.data._id}`) //Hide Breeze from all feeds, and mark as failed breeze.

                    //Post notification to originator of breeze that the seed has failed
                    let notificationObj = {
                        sourceUser: auth.user._id,
                        sourceUserName: auth.user.name,
                        sourceUserAvatar: auth.user.avatar,
                        targetUser: res.data.user,
                        notificationType: 'seedFail',
                        targetPost: res.data._id,
                        targetName: res.data.text,
                    }

                    axios.post('/api/notifications/', notificationObj)

                } else {
                    console.log('seed survived');
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    console.log(error.response.status, 'already downvoted breeze')
                } else {
                    console.log(error)
                }

            });
    }




    //Modal Functions
    Modal.setAppElement(document.getElementById('root'));

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '2rem'
        },
        overlay: { zIndex: 1001 }
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const openModal = () => {
        setIsOpen(true);
    }

    const afterOpenModal = () => {
        //Action to carry out after open
    }

    const closeModal = () => {
        setIsOpen(false);
    }


    return (
        <div className='breezeblock'>
            <div className='sub-content-container'>
                <div className='write-post'>
                    <div className='profile-img-small'><img className='post-breeze-icon' alt='Breeze Icon' src={BreezeIcon}></img></div>
                    <textarea
                        className='post-input-box'
                        placeholder="What's your next big idea?"
                        rows={3}
                        value={breezeInput}
                        onChange={(e) => {
                            handleChange(e)
                            setCharacterCount(e.target.value.length)
                        }} />
                </div>

                <div className='post-btn-container'>
                    <div className='post-char-count'>{characterCount}/140</div>
                    {postedABreeze ?
                        <button className='successful-post-breeze-btn'>Post Success!</button>
                        :
                        <button className={postAsMushroom ? 'post-btn mushroom clickable' : 'post-btn clickable'} onClick={() => openModal()}>{postAsMushroom ? 'Post As Mushroom' : 'Post'}</button>
                    }

                </div>
            </div>

            <div className='sub-content-container'>
                <div className='latest-breeze-container'>
                    <BreezeDisplay />

                    {/* <div className='current-breeze-container'>
                        <div className='current-breeze-header'>
                            <div className='breeze-title'>Latest Breeze</div>
                            <a href={`https://docs.google.com/forms/d/e/1FAIpQLScKEI8ciA2xXboYaxLFiv6BWUWti9GZkDqnDt9Z6w_jgKAPQA/viewform?usp=pp_url&entry.1105456013=${currentPostId}`} target='_blank' rel="noopener noreferrer">
                                <div onClick={() => handleBreezeInteraction('report')} className='btn-report clickable-danger tooltip'>
                                    <FontAwesomeIcon icon={faFlag} />
                                    <span className='tooltiptext'>Report Breeze</span>
                                </div>
                            </a>
                        </div>

                        

                        <div className='breeze-content'>{currentlyDisplayedBreeze}</div>
                        <div className='breeze-btn-container'>
                            <div className='btn-like btn-support clickable' onClick={() => handleBreezeInteraction('support')}>Keep It Going</div>
                            <div className='btn-like btn-danger clickable' onClick={() => handleBreezeInteraction('downvote')}>Stop The Breeze</div>
                        </div>
                    </div> */}

                </div>
            </div>

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Choose Forests Modal"
            >
                <BreezeModal closeModal={closeModal} breezeContent={breezeInput} currentMushroomMode={postAsMushroom} parentCB={breezeHasPosted} />
            </Modal>
        </div>
    )
}


BreezeBlock.propTypes = {
    getUserForests: PropTypes.func.isRequired,
    userForests: PropTypes.object.isRequired,
    makePost: PropTypes.func.isRequired,
    getPosts: PropTypes.func.isRequired,
    getCurrentProfile: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    forest: state.forest,
    posts: state.posts.posts,
    profile: state.profile,
    auth: state.auth,
})


export default connect(mapStateToProps, { getUserForests, makePost, getPosts, getCurrentProfile })(BreezeBlock);