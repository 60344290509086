// Library Imports
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { connect, useSelector, useDispatch } from 'react-redux'

// Redux Actions
import { getUserForests } from '../../../redux/actions/forest'
import { makePost, getPosts,getPostsInForest } from '../../../redux/actions/posts'

// Components
import UseDebounceEffect from '../../../utils/UseDebounceEffect'
import ForestSelectorMVP from '../../forest/ForestSelectorMVP'
import PostBlock from '../../../components/PostBlock/PostBlock'

// Assets
import ForestIcon from '../images/forest-icon.png'
import '../css/forest-block.css'
import '../../../components/PostBlock/post-block.css'
import RootBadge from '../../../assets/images/custom icons/Feed-Root-Icon.png'
import LoadingSpinner from '../../../assets/images/loading-spinner.gif'
import '../../../css/buttons.css'
import ForestSelector from './ForestSelector'

const ForestBlock = ({ getUserForests, forest, makePost, getPosts, posts, auth, profile }) => {

    // REDUX
    const dispatch = useDispatch();
    const currentPosts = useSelector((state) => state.posts.posts);
    const postsLoading = useSelector((state) => state.posts.loading);

    // ON LOAD
    const [allAds, setAllAds] = useState([])

    const [currentForestId, setCurrentForestId] = useState('ALL_TREES_EVERYWHERE')
    const [postCount, setPostCount] = useState(20); // For controlling API load numbers
    const [noMorePosts, setNoMorePosts] = useState(false); // To notify when no more posts
    useEffect(() => {

        // Dispatch Get posts for currently selected forest.
        // TODO: Get this dispatch pointing to All trees everywhere
        dispatch(getPostsInForest('ALL_TREES_EVERYWHERE', postCount));

        // TODO: Get ads call into redux action
        axios.get('/api/ads').then(res => {
            setAllAds(res.data)
        }).catch(err => console.log(err))

    }, [])



    const getForestFromChild = (forestId) => {
        // Reset Post Count
        setPostCount(20);

        // Store ID for future loads
        setCurrentForestId(forestId)

        // GET Data
        dispatch(getPostsInForest(forestId, 20));
    }

    const handleLoadMore = () => {
        // Add More to Post Count
        setPostCount(postCount+20);

        // GET Data
        dispatch(getPostsInForest(currentForestId, postCount+20, true));        
    }

    // If current Posts is < requested posts, set no more posts
    useEffect(() => {
        console.log('CURRRENT POSTS')
        console.log(currentPosts)
        if (currentPosts.length<postCount){
            setNoMorePosts(true)
        } else {
            setNoMorePosts(false)
        }
    }, [currentPosts])


    return (
        <div className='forest-block-container'>
            <div className='sub-content-container forest-header'>
                <div className='forest-selector-img-container'><img className='forest-selector-img' src={ForestIcon} /></div>
                <ForestSelector parentCB={getForestFromChild} includeAllTreesEverywhere={true} />
            </div>

            <div className='forest-body'>
                <div className='forest-body-left'>

                    <div className='forest-feed'>
                        <div className='feed-block-display'>
                        {
                            currentPosts.length > 0 ? (<>
                                {currentPosts.map(item => {
                                    //Display if not Breeze
                                    if (item.showPost && item.postType !== 'breeze') {

                                        //Display if not bark post which has been hidden.
                                        if (item.postType === 'barkFeedPost' && item.barkPostRef !== undefined && !item.barkPostRef.showPost) {
                                            return <></>
                                        } else {
                                            return (
                                                //NOTE: ANY CHANGES HERE ALSO HAPPEN IN PLANTMVP
                                                <PostBlock
                                                    postId={item._id}
                                                    key={item._id}
                                                    userName={item.name}
                                                    postUserId={item.user._id}
                                                    postUserAvatar={item.avatar}
                                                    postTime={item.date}
                                                    postContents={item.text}
                                                    supportCount={item.supportingUsers.length}
                                                    potentialGuide={item.user}
                                                    downVoteCount={item.downvoteUsers.length}
                                                    blossoms={item.blossoms}
                                                    thorns={item.thorns}
                                                    postType={item.postType}
                                                    supportLimit={item.supportLimit}
                                                    targetPlant={item.targetPlant}
                                                    currentUser={auth.user}
                                                    taggedUsers={item.taggedUsers}
                                                    attachedImage={item.attachedImage}
                                                    comments={item.comments}
                                                    profile={profile}
                                                />
                                            )
                                        }

                                    } else if (item.targetUrl !== undefined) {
                                        return (
                                            <div className='feed-ad'>
                                                
                                                <a target='_blank' rel="noopener noreferrer" href={item.targetUrl}>
                                                    <div className='card root-card clickable'>
                                                    <div className='post-type-badge'>
                                                    <img className='post-type-badge-image' alt='post-badge' src={RootBadge} />
                                                </div>
                                                        <div className='root-card-img-container'>
                                                            <img className='root-card-img' alt='adImg' src={item.mainImage} />
                                                        </div>
                                                        <div className='card-text-container'>
                                                            <div className='card-title'>{item.title}</div>
                                                            <div className='card-text'> {item.description}</div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        )
                                    } else {
                                        return <></>
                                    }
                                }
                                )}
                            </>) :
                                <></>
                            }

                            {
                                postsLoading ?
                                <img style={{width:'120px'}} src={LoadingSpinner}/>
                                : currentPosts.length > 0 && !noMorePosts ?
                                        <button onClick={()=>handleLoadMore()} className='list-bottom-btn'>Load More</button>
                                        : currentPosts.length == 0 && postsLoading == false ?
                                            <>Nothing here, yet!</>
                                            : <></>
                            }
                        </div>
                        
                        
                    </div>
                </div>
            </div>

        </div>
    )
}

ForestBlock.propTypes = {
    getUserForests: PropTypes.func.isRequired,
    userForests: PropTypes.object.isRequired,
    makePost: PropTypes.func.isRequired,
    getPosts: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    forest: state.forest,
    posts: state.posts.posts,
    auth: state.auth,
    profile: state.profile
})


export default connect(mapStateToProps, { getUserForests, makePost, getPosts })(ForestBlock);