import axios from 'axios';
import { REGISTER_SUCCESS, REGISTER_FAIL, USER_LOADED, AUTH_ERROR, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT } from './types'
import setAuthToken from '../../utils/setAuthToken'

import { createForest } from './forest'

//Load User
export const loadUser = () => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token)
    }

    try {
        const res = await axios.get('/api/auth')
        dispatch({
            type: USER_LOADED,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: AUTH_ERROR
        })

    }
}

//Register User
export const register = ({ name, email, password }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ name, email, password });

    try {
        const res = await axios.post('/api/users', body, config);
        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data
        })

        dispatch(loadUser());

        //Create initial Forest for User
        const defaultForestData = { //formData input we normally get from create forest
            title: 'My Trees',
            description: 'This is your default forest, where all trees you add will go, first.',
            users: []
        }

        const forestRes = await axios.post('/api/forest', defaultForestData, config);

        //Get forest ID which was just added, to update profile
        const forestId = forestRes.data._id

        const profileUpdateObj = {
            forests: [forestId],
            mushroomMode: false
        }

        const profileRes = await axios.post('/api/profile', profileUpdateObj, config);

        dispatch(loadUser());

    } catch (err) {
        dispatch({
            type: REGISTER_FAIL
        })
    }
}

//Login User
export const login = (email, password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ email, password });

    try {
        const res = await axios.post('/api/auth', body, config);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        })
        dispatch(loadUser());
    } catch (err) {
        dispatch({
            type: LOGIN_FAIL
        })
    }
}

//Logout / Clear Profile
export const logout = () => dispatch => {
    dispatch({ type: LOGOUT })
}