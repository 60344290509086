//This is a template built which connects to the s3 backend! Use and modify as necessary.

import React, { useState, useEffect } from 'react'
import axios from 'axios'

import '../css/plant-photo-select.css'

import '../css/plant-gallery.css'

export default function PlantGallery({ imageArray }) {

    // State Definition 
    const [displayFile, setDisplayFile] = useState(null);
    const [displayError, setDisplayError] = useState(null);
    const [currentGalleryURLs, setCurrentGalleryURLS] = useState([])
    const [currentlySelectedGallery, setCurrentlySelectedGallery] = useState([true, false, false, false, false, false])


    const handleThumbnailClick = (index) => {
        setDisplayFile(currentGalleryURLs[index]);
        var tempSelected = [false, false, false, false, false, false]
        tempSelected[index] = true
        setCurrentlySelectedGallery(tempSelected);
    }


    //Rendering Functions
    //Displays Photo once it has been uploaded to s3.
    const displayUploadedPhoto = () => {
        if (displayFile != null) {
            return (
                <div className='plant-photo-container'>
                    <img alt='Currently Selected Main Photo' src={displayFile} style={{ maxHeight: '100%', maxWidth: '100%' }} />
                </div>
            );
        } else {
            return (
                <div className='plant-photo-container'>
                    No Photos, yet.
                </div>
            );
        }
    };

    //Displays Any errors if they are present.
    const displayAnyErrors = () => {
        if (displayFile != null) {
            return (<div style={{ color: 'red', margin: '1rem' }}>{' '}</div>);
        } else {
            return (<div style={{ color: 'red', margin: '1rem', textAlign: 'center' }}>{displayError}</div>);
        }
    };

    // Effect Hooks
    // Forces a re-render since React won't do it for the errors.
    useEffect(() => {
        displayAnyErrors();
    }, [displayError])


    useEffect(() => {
        setCurrentGalleryURLS(imageArray)
        setDisplayFile(imageArray[0])
    }, [imageArray])



    return (
        <div className='plant-gallery-container'>
            {displayAnyErrors()}
            {displayUploadedPhoto()}
            <div className='thumbnail-display-container'>
                {currentGalleryURLs.length > 0 ?
                    (<>
                        {currentGalleryURLs.map((url, index) => (<>
                            <div key={index} className='thumbnail-img-container' onClick={() => handleThumbnailClick(index)}>
                                <img
                                    className={currentlySelectedGallery[index] ? 'plant-gallery-thumbnail selected' : 'plant-gallery-thumbnail'}
                                    key={url}
                                    alt='Thumbnail Image'
                                    src={url} />
                            </div></>))
                        }

                    </>
                    ) : <></>
                }
            </div>
        </div>
    )
}
