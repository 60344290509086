// Libraries
import React, {useState, useEffect} from 'react'
import axios from 'axios'

// Components
import Navbar from '../../components/Navbar/Navbar'


import './reset-password.css'

export default function ResetPassword() {



    const [email, setEmail] = useState('')
    const [emailSent, setEmailSent] = useState(false);


    const handleSendReset = (e) => {
        e.preventDefault();

        setError('')
        
        let body = JSON.stringify({
            email,
        })

        let config = {
            headers:{
                "Content-Type":"application/json"
            },
        }

        axios.post('/api/email/resetPasswordRequest', body, config)
            .then(res=>{
                console.log(res)
                setEmailSent(true)
            })
            .catch(err=>{
                setError('Error Sending Email') 
                setEmailSent(false)
            })
    }


    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')

    const resetId = window.location.href.split('?id=')[1]

    const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);

    const [error, setError]= useState('')

    const resetPassword = (e) => {
        e.preventDefault();
        setError('');

        if (password == passwordConfirm && passwordStrengthDisplay == '(Very Strong)') {
            let body = JSON.stringify({
                password,
                resetId
            })
    
            let config = {
                headers:{
                    "Content-Type":"application/json"
                },
            }
    
            axios.put('/api/email/resetPassword', body, config)
                .then(res=>{
                    console.log(res);
                    setPasswordResetSuccess(true);
                })
                .catch(err=>{
                    setError('Expired Password Reset Link')
                })
        } else {
            setError('Passwords must match.')
        }

        
    }


    // Password Strength Stuff


    useEffect(() => {
        checkPassMatch();
    }, [password, passwordConfirm])

    const [passwordStrengthDisplay, setPasswordStrengthDisplay] = useState('')
    const [passwordStrengthMeter, setPasswordStrengthMeter] = useState(
        {
            border: '1px solid #e4e8f0;'
        }
    )

    const hasNumber = value => {
        return new RegExp(/[0-9]/).test(value);
    }
    const hasMixed = value => {
        return new RegExp(/[a-z]/).test(value) &&
            new RegExp(/[A-Z]/).test(value);
    }
    const hasSpecial = value => {
        return new RegExp(/[!#@$%^&*)(+=._-]/).test(value);
    }
    const strengthColor = count => {
        if (count < 2) {
            return 'red';
        } else if (count < 3) {
            return 'orange';
        } else if (count < 4) {
            return 'yellowgreen';
        } else if (count < 5) {
            return 'green';
        } else {
            return 'green'
        }
    }

    const strengthIndicator = value => {
        let strengths = 0;
        if (value.length > 7) strengths++;
        if (hasNumber(value)) strengths++;
        if (hasSpecial(value)) strengths++;
        if (hasMixed(value)) strengths++;

        return strengths;
    }

    const showPasswordStrength = (e) => {
        const newStyle = {
            color: strengthColor(strengthIndicator(e.target.value)),
            outline: 'none'
        }

        if (strengthIndicator(e.target.value) === 0) {
            setPasswordStrengthDisplay('')
        } else if (strengthIndicator(e.target.value) === 1) {
            setPasswordStrengthDisplay('(Weak)')
        } else if (strengthIndicator(e.target.value) === 2) {
            setPasswordStrengthDisplay('(Medium)')
        } else if (strengthIndicator(e.target.value) === 3) {
            setPasswordStrengthDisplay('(Strong)')
        } else {
            setPasswordStrengthDisplay('(Very Strong)')
        }

        setPasswordStrengthMeter(newStyle)



        return '2px solid ' + strengthColor(strengthIndicator(e.target.value))

    }

    const [passwordsMatch, setPasswordsMatch] = useState({
        color: 'gray'
    })

    const checkPassMatch = () => {
        const newStyle = {
            color: 'gray',
            outline: 'none'
        }
        if (password === passwordConfirm) {
            if (password.length > 1) {
                newStyle.color = 'green'
            }
        } else {
            newStyle.color = 'red'
        }

        setPasswordsMatch(newStyle)
    }
    
    return (
        
        <div className='single-post-page-container'>
            <div className='base-camp-static-bg' />
            <Navbar />

            

            {
            (resetId === undefined || emailSent ) ? 
                emailSent ? 
                <div className='sub-content-container password-reset-form confirm-text'>
                    <div>Password Reset link sent! Please check your spam folder if you don't see it in your inbox.</div>
                </div>
                :
                <div className='sub-content-container password-reset-form'>
                <div >Enter email to reset password:</div>
                <input style={{fontSize: '0.9rem', padding: '0.2rem'}} type='text' placeholder='email' value={email} onChange={(e)=>setEmail(e.target.value)} />
                <button onClick={(e)=>{
                    handleSendReset(e)
                    setEmailSent(true)
                    }} style={{padding: '0.2rem 2rem', marginTop: '1rem'}} className='clickable submit-btn'> Submit </button>
                <div
                style={{
                    fontSize: '0.9rem',
                    padding: '0.2rem',
                    width: '200px',
                    color: 'red',
                    alignSelf: 'center',
                    textAlign: 'center'
                }}
                >{error}</div>
                </div>
            

            : passwordResetSuccess ? 
            <div className='sub-content-container password-reset-form'>
                <div >Password successfully reset!</div>
                <a href='/signin'><div className='clickable' style={{color:'#00916e'}}>Click here to sign in</div></a>

            </div>
            
            : <form className='sub-content-container password-reset-form'>
                <div >Please enter your new password:</div>
                <input style={{fontSize: '0.9rem', padding: '0.2rem', width: '200px'}} type='password' placeholder='New Password' value={password}
                    onChange={e => {
                            setPassword(e.target.value);
                            showPasswordStrength(e);
                            checkPassMatch();
                        }}
                    />
                <input
                    style={{
                        fontSize: '0.9rem',
                        padding: '0.2rem',
                        width: '200px',
                    }}
                    type='password'
                    placeholder='Confirm New Password'
                    value={passwordConfirm}
                    onChange={(e)=>{
                        setPasswordConfirm(e.target.value)
                        checkPassMatch();
                        }
                    } />
                

                <div className='password-requirements' style={passwordStrengthMeter}>
                    &#8226; Password must contain one capital letter, one lowercase letter, a number, and a special character such as !, ?, #, or $
                </div>
                <div className='password-requirements' style={passwordsMatch}>
                    &#8226; Passwords must match.
                </div>

                <button onClick={(e)=>resetPassword(e)} style={{padding: '0.2rem 2rem', marginTop: '1rem'}} className='clickable submit-btn'> Submit </button>
                <div
                style={{
                    fontSize: '0.9rem',
                    padding: '0.2rem',
                    width: '200px',
                    color: 'red',
                    alignSelf: 'center',
                    textAlign: 'center'
                }}
                >{error}</div>
            </form>
            }

            
        </div>
    )
}
