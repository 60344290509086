import { CREATE_POST,
    GET_POSTS,
    POST_ERROR,
    GET_POSTS_IN_FOREST_REQUESTED,
    GET_POSTS_IN_FOREST_SUCCESS,
    GET_POSTS_IN_FOREST_FAILED,
    GET_POSTS_IN_PLANT_REQUESTED,
    GET_POSTS_IN_PLANT_SUCCESS,
    GET_POSTS_IN_PLANT_FAILED,
    LOAD_MORE_POSTS_IN_FOREST_REQUESTED,
    LOAD_MORE_POSTS_IN_FOREST_SUCCESS,
    LOAD_MORE_POSTS_IN_FOREST_FAILED,
    LOAD_MORE_POSTS_IN_PLANT_REQUESTED,
    LOAD_MORE_POSTS_IN_PLANT_SUCCESS,
    LOAD_MORE_POSTS_IN_PLANT_FAILED
 } from "../actions/types";

const initialState = {
    posts: [],
    loading: true,
    error: {}
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_POSTS:
            return {
                ...state,
                posts: payload,
                loading: false
            }
        // case GET_USER_FORESTS:
        //     return {
        //         ...state,
        //         forests: payload,
        //         loading: false
        //     }
        case POST_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }

        // ! NEW API CALLS!
        case GET_POSTS_IN_FOREST_REQUESTED:
        case GET_POSTS_IN_PLANT_REQUESTED:
            return {
                ...state,
                loading: true
            }
        case LOAD_MORE_POSTS_IN_FOREST_REQUESTED:
        case LOAD_MORE_POSTS_IN_PLANT_REQUESTED:
            return {
                ...state,
                posts: state.posts,
                loading: true
            }
        case GET_POSTS_IN_FOREST_SUCCESS:
        case GET_POSTS_IN_PLANT_SUCCESS:
        case LOAD_MORE_POSTS_IN_FOREST_SUCCESS:
        case LOAD_MORE_POSTS_IN_PLANT_SUCCESS:
            return {
                ...state,
                posts: payload,
                loading: false
            }
        case GET_POSTS_IN_FOREST_FAILED:
        case GET_POSTS_IN_PLANT_FAILED:
        case LOAD_MORE_POSTS_IN_FOREST_FAILED:
        case LOAD_MORE_POSTS_IN_PLANT_FAILED:
            return {
                ...state,
                posts: [],
                loading: false
            }
        default:
            return state
    }
}