// Library Imports
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Redux Actions
import { getCurrentProfile } from '../../redux/actions/profile'
import { getUserForests } from '../../redux/actions/forest'

// Components
import Navbar from '../../components/Navbar/Navbar'
import ProfileCard from '../../components/Cards/ProfileCard/ProfileCard'
import YourLatestBreezes from './components/YourLatestBreezes'
import BreezeBlock from './components/BreezeBlock'
import FieldBlock from './components/FieldBlock'
import ForestBlock from './components/ForestBlock'

// Assets
import './base-camp.css'


const BaseCamp = ({ getCurrentProfile, getUserForests, auth, profile }) => {


    const [currentUserName, setCurrentUserName] = useState('Loading...')
    const [currentUserAvatar, setCurrentUserAvatar] = useState('')
    const [currentUserId, setCurrentUserId] = useState('')

    useEffect(() => {
        getCurrentProfile();
        getUserForests();

        if (auth.user !== null && auth.user !== undefined) {
            setCurrentUserName(auth.user.name)
            setCurrentUserAvatar(auth.user.avatar)
            setCurrentUserId(auth.user._id)
        }

    }, [auth.loading])


    const [mushroomMode, setMushroomMode] = useState(false)
    useEffect(() => {
        if (profile.profile != undefined) {
            setMushroomMode(profile.profile.mushroomMode);
            setProfileDetails(profile.profile)
        }
    }, [profile]);

    const [profileDetails, setProfileDetails] = useState({
        profilePhoto: '',
        name: 'loading...',
        memberSince: 'loading...',
    })

    return (
        <div className='base-camp-container content-container'>
            <div className={mushroomMode ? 'base-camp-static-bg mushroom' : 'base-camp-static-bg'} />
            <Navbar selected='basecamp' />
            <div className='base-camp-section top'>
                <div className='content-container breeze-block'>
                    <BreezeBlock />
                </div>
                <div className='content-container base-camp-block'>
                    <ProfileCard
                        summaryOnly={true}
                        userId={currentUserId}
                        image={currentUserAvatar}
                        profile={profile}
                    />
                    <YourLatestBreezes auth={auth} userId={currentUserId} />

                </div>
            </div>

            <div className='base-camp-section'>
                <div className='feed-section'>
                    <div className='content-container'>
                        <FieldBlock />
                    </div>
                    <div className='content-container main-feed-block'>
                        <div className='title'>Forest Feed</div>
                        <ForestBlock />
                    </div>
                </div>
                {/* <div className='content-container roots-block'>
                    <RootsBlock />
                </div> */}
            </div>
        </div>
    )
}

BaseCamp.propTypes = {
    getCurrentProfile: PropTypes.func.isRequired,
    getUserForests: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile
})


export default connect(mapStateToProps, { getCurrentProfile, getUserForests })(BaseCamp);