// Library Imports
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faSearch, faBell } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'

// Redux Actions
import { getUserPlants } from '../../redux/actions/plant'
import { logout } from '../../redux/actions/auth'
import { getCurrentProfile } from '../../redux/actions/profile'

import { getPostsInForest,getPostsInPlant } from '../../redux/actions/posts'

// Components
import NotificationList from '../../components/Notifications/NotificationList'

// Assets
import './navbar.css'

const Navbar = ({ selected, profile, auth, logout, getCurrentProfile }) => {

    // This system for loading posts works exactly as intended. Super smooth!
    const dispatch = useDispatch();

    const currentPosts = useSelector((state) => state.posts.posts);

    const handleAPITest = () => {
        dispatch(getPostsInForest('5fb0476049da6d0ef5a6896d', 20));
        //dispatch(getPostsInPlant('5fdd43ff9f685c06e5682e18', 20));
    }

    const handleAnotherAPITest = () => {
        dispatch(getPostsInForest('5fb0476049da6d0ef5a6896d', currentPosts.length + 10));
        //dispatch(getPostsInPlant('5fdd43ff9f685c06e5682e18', currentPosts.length+10));
    }

    // End post loading chunk.

    const [mushroomModeActive, setMushroomModeActive] = useState(false);
    const [selectedPage, setSelectedPage] = useState(selected);
    const [menu, showMenu] = useState(false);
    const [username, setUserName] = useState('(User Name)');
    const [avatar, setAvatar] = useState('');
    const [justLoggedOut, setJustLoggedOut] = useState(false);

    const history = useHistory();
    const navigateToSearch = () => history.push('/search');

    const logoutFunction = () => {
        logout();
        setJustLoggedOut(true);
    }

    const [showNotificationsList, toggleNotificationsList] = useState(false);
    const [notificationDotDisplay, toggleNotificationDotDisplay] = useState(false)
    const [notifications, setNotifications] = useState([])
    const [totalNotifications, setTotalNotifications] = useState(0);

    const handleNotifications = (notificationsFromChild) => {
        if (notificationsFromChild !== undefined) {
            setNotifications(notificationsFromChild);
        }

    }

    useEffect(() => {
        let notificationNum = 0

        for (var i = 0; i < notifications.length; i++) {
            if (!notifications[i].userHasSeen) notificationNum++;
        }
        setTotalNotifications(notificationNum);

        if (notificationNum === 0) {
            toggleNotificationDotDisplay(false);
        } else {
            toggleNotificationDotDisplay(true);
        }

    }, [notifications])

    const handleMushroomMode = () => {
        let mushroomUpdate = {
            mushroomMode: !mushroomModeActive
        }
        axios.post('/api/profile/', mushroomUpdate).then(res => {
            getCurrentProfile()
        }).catch(error => console.log(error));
        setMushroomModeActive(!mushroomModeActive);
    }

    //Once redux has finished updating user state (loading ends), update the component as necessary.
    const [userId, setUserId] = useState('')
    useEffect(() => {
        if (auth.user !== null) {
            setUserName(auth.user.name)
            setUserId(auth.user._id)
            setAvatar(auth.user.avatar)
        }
    }, [auth])


    useEffect(() => {
        if (profile !== null) {
            if (profile.profile !== null) {
                setMushroomModeActive(profile.profile.mushroomMode);
                handleNotifications();
            }
        }
    }, [profile])

    return (
        (justLoggedOut) ? <Redirect to='/signin' /> :
            <div className={mushroomModeActive ? 'navbar-container mushroom' : 'navbar-container'}>


                <div className='nav-left-container'>
                    <div className={mushroomModeActive ? 'search-btn dropdown-btn-container clickable mushroom' : 'search-btn dropdown-btn-container clickable'}
                        onClick={() => navigateToSearch()}>
                        <FontAwesomeIcon icon={faSearch} />
                    </div>
                </div>



                <div className='nav-center-container'>
                    <ul className={mushroomModeActive ? 'navigation-item-list mushroom' : 'navigation-item-list'}>
                        <a href='/forestorganizer'><li className={selectedPage === 'forest' ? 'navigation-item clickable selected' : 'navigation-item clickable'}>Your Forests</li></a>
                        <a href='/basecamp'><li className={selectedPage === 'basecamp' ? 'navigation-item clickable selected' : 'navigation-item clickable'}>Base Camp</li></a>
                        <a href='/field'><li className={selectedPage === 'field' ? 'navigation-item clickable selected' : 'navigation-item clickable'}>Your Field</li></a>
                    </ul>
                </div>




                <div className='nav-right-container'>
                    {userId === '' ?
                        <a className='signin-nav-btn clickable' href='/signin'>Click here to sign in</a>
                        :
                        <>
                            <div className={mushroomModeActive ? 'notifications dropdown-btn-container clickable mushroom' : 'notifications dropdown-btn-container clickable'}
                                onClick={() => {
                                    toggleNotificationsList(!showNotificationsList)
                                    showMenu(false)
                                }}>
                                <div className='notification-bell-container'>
                                    <FontAwesomeIcon className='bell-icon' icon={faBell} />
                                    <div className={notificationDotDisplay ? 'notification-bubble' : 'notification-bubble hidden'}>{totalNotifications}</div>
                                </div>


                            </div>

                            <div className='profile-box-container'>
                                <div className={mushroomModeActive ? 'browsing-toggle clickable mushroom' : 'browsing-toggle clickable'}
                                    onClick={() => handleMushroomMode()}>
                                    <div className='profile-img-container navbar'>
                                        <img className='navbar-profile-img' alt='profileImg' src={avatar} />
                                    </div>
                                    <div className='profile-name-container'>
                                        {mushroomModeActive ? 'Anonymous Mushroom' : username}
                                    </div>
                                </div>

                                <div className={mushroomModeActive ? 'dropdown-btn-container clickable mushroom' : 'dropdown-btn-container clickable'}
                                    onClick={() => {
                                        showMenu(!menu)
                                        toggleNotificationsList(false)
                                    }}>
                                    <FontAwesomeIcon icon={faCaretDown} />
                                </div>
                                <div className={menu ? 'dropdown-menu-container' : 'dropdown-menu-container hidden'}>
                                    <div className='browsing-mode-title'>Browsing As:</div>
                                    <div className='browsing-mode-selector'>
                                        <div className={mushroomModeActive ? 'browsing-mode-selection tree' : 'browsing-mode-selection selected tree'}
                                            onClick={() => handleMushroomMode()}>Tree</div>
                                        <div className={mushroomModeActive ? 'browsing-mode-selection mushroom selected' : 'browsing-mode-selection mushroom'}
                                            onClick={() => handleMushroomMode()}>Mushroom</div>
                                    </div>
                                    <ul className='dropdown-menu-list'>
                                        <a href={`/profile/?id=${userId}`}><li className='dropdown-menu-item clickable'>View Profile</li></a>
                                        <a href={`/profileImageUpload`}><li className='dropdown-menu-item clickable'>Change Profile Picture</li></a>
                                        <a href='https://forms.gle/bRyB6hBiyk3yxT3q8' target='_blank'><li className='dropdown-menu-item clickable'>Provide Feedback</li></a>
                                        <li className='dropdown-menu-item clickable' onClick={() => logoutFunction()}>Sign Out</li>
                                        {/* <li className='dropdown-menu-item clickable' onClick={() => handleAPITest()}>Test API</li>
                                        <li className='dropdown-menu-item clickable' onClick={() => handleAnotherAPITest()}>Test API Load More</li> */}
                                    </ul>
                                </div>
                            </div>
                        </>
                    }

                </div>


                <div className={showNotificationsList ? 'notification-navbar-container' : 'notification-navbar-container hidden'}>
                    <NotificationList parentCB={handleNotifications} />
                </div>
            </div >
    )
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile
})

Navbar.propTypes = {
    getUserPlants: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    getCurrentProfile: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { getUserPlants, logout, getCurrentProfile })(Navbar);