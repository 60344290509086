import React from 'react';
import UIButtons from './Buttons/UIButtons';
import Header from './Header/Header';
import Banner from './Banner/Banner';
import StackedLayout from './StackedLayout/StackedLayout';
import SettingsList from './SettingsList/SettingsList';
import TableView from './TableView/TableView';

import './ui-library.css';

import './style/main.css';
import BasicCard from './Cards/BasicCard';

// MASSIVE Load of resources here:
// https://github.com/aniftyco/awesome-tailwindcss#ui-libraries-components--templates

//Tailwind official components: https://tailwindui.com/

export default function UILibrary() {
  return (
    <div className="ui-library-container">
      <UIButtons />

      <hr className="mt-12" />

      <h1 class="m-3 mt-6 text-2xl text-gray-900">Stacked Layout</h1>
      <StackedLayout />

      <hr className="mt-12" />
      <h1 class="m-3 mt-6 text-2xl text-gray-900">Banner</h1>
      <Banner />

      <hr className="mt-12" />
      <h1 class="m-3 mt-6 text-2xl text-gray-900">Dropdown Menus</h1>
      <Header />

      <hr className="mt-12" />
      <h1 class="m-3 mt-6 text-2xl text-gray-900">Settings List</h1>
      <SettingsList />

      <hr className="mt-12" />
      <h1 class="m-3 mt-6 text-2xl text-gray-900">Table View</h1>
      <TableView />

      <hr className="mt-12" />
      <h1 class="m-3 mt-6 text-2xl text-gray-900">Basic Card</h1>
      <BasicCard />
    </div>
  );
}
