import axios from 'axios'

import { 
    CREATE_POST, 
    GET_POSTS, 
    POST_ERROR,
    GET_POSTS_IN_FOREST_REQUESTED,
    GET_POSTS_IN_FOREST_SUCCESS,
    GET_POSTS_IN_FOREST_FAILED,
    GET_POSTS_IN_PLANT_REQUESTED,
    GET_POSTS_IN_PLANT_SUCCESS,
    GET_POSTS_IN_PLANT_FAILED,
    LOAD_MORE_POSTS_IN_FOREST_REQUESTED,
    LOAD_MORE_POSTS_IN_FOREST_SUCCESS,
    LOAD_MORE_POSTS_IN_FOREST_FAILED } from './types'

//Get Post Data
export const getPosts = (type, Id) => async dispatch => {

    try {
        const res = await axios.get(`/api/posts/${type}/${Id}`);
        dispatch({
            type: GET_POSTS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: POST_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}


//Create Post
export const makePost = (postContent, type, Id) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try {
        const res = await axios.post(`/api/posts/${type}/${Id}`, postContent, config);
        dispatch({
            type: GET_POSTS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: POST_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}


// Note: Remove above whenever we've finished implementing the new ones throughout site!
// ! NEW API CALLS IMPLEMENTED HERE
// ! NEW API CALLS IMPLEMENTED HERE
// ! NEW API CALLS IMPLEMENTED HERE

export const getPostsInForest = (forestId, limit, loadMore) => async dispatch => {

    // Set headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    // Redux Dispatch for loading state
    if (loadMore) { // If we are making a "load more" call, keep existing posts in queue.
        dispatch({type: LOAD_MORE_POSTS_IN_FOREST_REQUESTED})
    } else {
        dispatch({type: GET_POSTS_IN_FOREST_REQUESTED})
    }

    try {
        // API Call
        const res = await axios.get(`/api/forest/getPosts/${forestId}/${limit}`, config);

        // Redux Dispatch for successful Response 
        if (loadMore) {
            dispatch({type: LOAD_MORE_POSTS_IN_FOREST_SUCCESS, payload: res.data })
        } else {
            dispatch({type: GET_POSTS_IN_FOREST_SUCCESS, payload: res.data })
        }

    } catch (err) {
        // Redux Dispatch for Error.
        if (loadMore){
            dispatch({
                type: LOAD_MORE_POSTS_IN_FOREST_FAILED,
                payload: { 
                    msg: err.response.statusText,
                    status: err.response.status 
                }
            })
        } else {
            dispatch({
                type: GET_POSTS_IN_FOREST_FAILED,
                payload: { 
                    msg: err.response.statusText,
                    status: err.response.status 
                }
            })    
        }
        
    }
}

export const getPostsInPlant = (plantId, limit) => async dispatch => {

    // Set headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    // Redux Dispatch for loading state
    dispatch({type: GET_POSTS_IN_PLANT_REQUESTED})

    try {
        // API Call
        const res = await axios.get(`/api/plant/getPosts/${plantId}/${limit}`, config);

        // Redux Dispatch for successful Response 
        dispatch({type: GET_POSTS_IN_PLANT_SUCCESS, payload: res.data })
    } catch (err) {
        // Redux Dispatch for Error.
        dispatch({
            type: GET_POSTS_IN_PLANT_FAILED,
            payload: { 
                msg: err.response.statusText,
                status: err.response.status 
            }
        })
    }
}

// TODO: Get Latest Breezes

// TODO: Get All Trees Everywhere

// TODO: Get all Forests



// getRecentBreezes
// loadMoreRecentBreezes

// getProfileBark
// loadMoreProfileBark