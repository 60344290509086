// This file is for making API calls to the server, and dispatching the appropriate actions to Redux.
// This handles all Breeze-related API Calls.

import axios from 'axios'
import * as types from './types';


// This route gets latest breezes from all users everywhere.
export const getLatestBreezes = (currentUserId) => async dispatch => {
    dispatch({
        type: types.GET_LATEST_BREEZES_REQUESTED,
    })

    try {
        const res = await axios.get(`/api/breezes/latest/${currentUserId}`);
        dispatch({
            type: types.GET_LATEST_BREEZES_SUCCESS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: types.GET_LATEST_BREEZES_FAILED,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// This handles getting the user's posted breezes, and can handle limited amounts.
export const getUserBreezes = (currentUserId, limit) => async dispatch => {

    // Determine if we are loading more or not
    let loadMore = false;
    if (limit > 20) {
        loadMore = true;
    }

    // If loading more, we need to keep the current breezes in state on the request
    if (loadMore){
        dispatch({
            type: types.LOAD_MORE_USER_BREEZES_REQUESTED,
        })
    
        try {
            const res = await axios.get(`/api/breezes/${currentUserId}/${limit}`);
            dispatch({
                type: types.LOAD_MORE_USER_BREEZES_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            dispatch({
                type: types.LOAD_MORE_USER_BREEZES_FAILED,
                payload: { msg: err.response.statusText, status: err.response.status }
            })
        }

    // If NOT loading more, we simply populate breezes as the first time.
    } else {
        dispatch({
            type: types.GET_USER_BREEZES_REQUESTED,
        })
    
        try {
            const res = await axios.get(`/api/breezes/${currentUserId}/${limit}`);
            dispatch({
                type: types.GET_USER_BREEZES_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            dispatch({
                type: types.GET_USER_BREEZES_FAILED,
                payload: { msg: err.response.statusText, status: err.response.status }
            })
        }
    }
}

export const handleSupportBreeze = (currentPostId, currentUser) => async dispatch => {

    axios.put(`/api/posts/breeze/support/${currentPostId}`)
        .then(res => {
            //Handle Seed Drop

            //If supporting users is longer than forest support limit, we can check if we should seed drop
            if (res.data.supportingUsers.length > res.data.forestSupportLimit) {
                //Determine outside vs inside support
                let othersSupportCount = 0
                for (let i = 0; i < res.data.supportingUsers.length; i++) {
                    if (res.data.targetOtherUsers.indexOf(res.data.supportingUsers[i]) !== -1) othersSupportCount++;
                }

                //Determine outside vs inside downvotes
                let othersDownvoteCount = 0
                for (let i = 0; i < res.data.downvoteUsers.length; i++) {
                    if (res.data.targetOtherUsers.indexOf(res.data.downvoteUsers[i]) !== -1) othersDownvoteCount++;
                }

                //Collectively determine if the seed will drop based on rules
                if ( // If nobody has interacted with it (counts as 100% positive response rate), or Others' support ratio of received responses is above limit
                    ((othersSupportCount === 0 && othersDownvoteCount === 0)
                        || othersSupportCount / (othersDownvoteCount + othersSupportCount) > res.data.othersSupportLimit)
                    && (res.data.supportingUsers.length - othersSupportCount > res.data.forestSupportLimit) //Isolated forest support is above forest support limit
                ) {
                    //Handle Seed Drop
                    const seedData = {
                        title: 'New Seed Dropped!',
                        description: res.data.text,
                        breeze: res.data._id,
                        breezeText: res.data.text,
                        owner: res.data.user,
                        showPlant: false
                    }

                    //Post notification to originator of breeze that the seed has dropped
                    let notificationObj = {
                        sourceUser: currentUser._id,
                        sourceUserName: currentUser.name,
                        sourceUserAvatar: currentUser.avatar,
                        targetUser: res.data.user,
                        notificationType: 'seedDrop',
                        targetName: res.data.text,
                    }

                    //Create Plant; hidden until it is cultivated.
                    //Plant response is taken to create notification for user
                    axios.post('/api/plant/', seedData).then(plantResponse => {
                        notificationObj.targetPost = plantResponse.data._id
                        axios.post('/api/notifications/', notificationObj)
                    }).catch(err => console.log(err))

                    //Hide Breeze from all feeds
                    axios.put(`/api/posts/hide/${res.data._id}`)
                    .then(res=>{
                        //console.log(res)
                    })
                }
            }
        })
        .catch((error) => {
            if (error.response.status === 400) {
                console.log(error.response.status, 'already supported breeze')
            } else {
                console.log(error)
            }

        });

}



export const handleDownvoteBreeze = (currentPostId, currentUser) => async dispatch => {
    axios.put(`/api/posts/breeze/downvote/${currentPostId}`)
    .then(res => {

        //Determine outside vs inside support and downvotes
        let othersSupportCount = 0
        for (let i = 0; i < res.data.supportingUsers.length; i++) {
            if (res.data.targetOtherUsers.indexOf(res.data.supportingUsers[i]) !== -1) othersSupportCount++;
        }
        let othersDownvoteCount = 0
        for (let i = 0; i < res.data.downvoteUsers.length; i++) {
            if (res.data.targetOtherUsers.indexOf(res.data.downvoteUsers[i]) !== -1) othersDownvoteCount++;
        }

        let forestSupportCount = res.data.supportingUsers.length - othersSupportCount;
        let forestDownvoteCount = res.data.downvoteUsers.length - othersDownvoteCount;
        let remainingForestResponses = res.data.targetForestUsers.length - (forestSupportCount + forestDownvoteCount)
        let remainingOtherResponses = res.data.targetOtherUsers.length - (othersSupportCount + othersDownvoteCount)

        //If we are out of responses and didn't hit the threshold, we die.
        if (remainingForestResponses < res.data.forestSupportLimit - forestSupportCount
            || remainingOtherResponses < res.data.othersSupportLimit - othersSupportCount) {
            //Handle Seed Fail
            axios.put(`/api/posts/breezeFail/${res.data._id}`) //Hide Breeze from all feeds, and mark as failed breeze.

            //Post notification to originator of breeze that the seed has failed
            let notificationObj = {
                sourceUser: currentUser._id,
                sourceUserName: currentUser.name,
                sourceUserAvatar: currentUser.avatar,
                targetUser: res.data.user,
                notificationType: 'seedFail',
                targetPost: res.data._id,
                targetName: res.data.text,
            }

            axios.post('/api/notifications/', notificationObj)

        } else {
            console.log('seed survived');
        }
    })
    .catch((error) => {
        if (error.response.status === 400) {
            console.log(error.response.status, 'already downvoted breeze')
        } else {
            console.log(error)
        }

    });
}