import React from 'react'
import Spinner from '../../assets/images/loading-spinner.gif'

export default function LoadingSpinner({size}) {

    let styleObj = {
        display: 'flex',
        width: size + 'px',
        height: size + 'px'
    }

    return (
        <img style={styleObj} alt='loading' src={Spinner}/>
    )
}
