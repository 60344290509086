/* eslint-disable */

import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLeaf, faTree, faCannabis, faSeedling, faGlobeAmericas, faSpa, faTemperatureHigh, faCertificate, faCheck } from '@fortawesome/free-solid-svg-icons'

import axios from 'axios'

import moment from 'moment';

import './profile-card.css'

//export default function ProfileCard({ name = 'John Doe', addedDate = 'July 2020', image = '[Img]', summaryOnly = false, modalFunc, userId = 'none' }) {
export default function ProfileCard({ userId, image, summaryOnly, modalFunc, profile }) {

    const history = useHistory();

    const [userInfo, setUserInfo] = useState({
        name: 'Loading...',
        avatar: 'none',
        date: 'Loading...',
        profileLink: 'none',
    })

    //Load for profile input
    useEffect(() => {
        if (profile !== undefined && profile !== null && profile.profile !== undefined && profile.profile !== null) {
            setUserInfo({
                name: profile.profile.user.name,
                avatar: profile.profile.user.avatar,
                date: profile.profile.user.date
            })
        }
    }, [profile])

    //Load for userId input
    useEffect(() => {
        if (userId !== 'none') {
            axios.get(`/api/users/populateUser/${userId}`).then(res => {
                setUserInfo(res.data);
            }).catch(err => console.log(err));
        }

    }, [userId])

    const openProfile = () => {
        if (summaryOnly) {
            window.scrollTo(0, 0);
            history.push(`/Profile/?id=${userId}`);
        }
    }

    return (
        <div className={summaryOnly ? 'card profile-card summary-only' : 'card profile-card'} onClick={() => openProfile()}>
            <div className='profile-card-summary-header'>
                <div className='profile-summary-img-container'>
                    <img className='profile-summary-img' alt='profileImg' src={userInfo.avatar} />
                </div>
                <div>
                    <div className='profile-name'>{userInfo.name}</div>
                    <div className='profile-member-since'>Member Since: {moment(userInfo.date).format('MMM YYYY')}</div>
                    {/* <div className='badges-container'>
                        <FontAwesomeIcon className='clickable badge-green' icon={faLeaf} />
                        <FontAwesomeIcon className='clickable badge-green' icon={faTree} />
                        <FontAwesomeIcon className='clickable badge-yellow' icon={faCannabis} />
                        <FontAwesomeIcon className='clickable badge-red' icon={faTemperatureHigh} />
                        <FontAwesomeIcon className='clickable' icon={faSeedling} />
                        <FontAwesomeIcon className='clickable' icon={faSpa} />
                        <FontAwesomeIcon className='clickable' icon={faGlobeAmericas} />
                    </div> */}
                </div>
                {/* <div className='profile-summary-cert-container'>
                    <FontAwesomeIcon className='card-cert-badge-check' icon={faCheck} />
                    <FontAwesomeIcon className='card-cert-badge' icon={faCertificate} />
                </div> */}
            </div>
            {
                summaryOnly ? <></> : <>
                    <div className='current-forests-container'>
                        Current Forests:
                <ul className='current-forests-list'>
                            <li className='current-forests-list-item'>Forest 1</li>
                            <li className='current-forests-list-item'>Forest 2</li>
                        </ul>
                    </div>
                    <div className='add-to-forest-container' onClick={() => modalFunc()}>
                        <div className='add-to-new-forest-btn' >+ Add to New Forest</div>
                    </div>
                </>
            }


        </div >
    )
}
