import { combineReducers } from 'redux';
import auth from './auth'
import breezes from './breezes'
import profile from './profile'
import forest from './forest'
import posts from './posts'
import plant from './plant'

export default combineReducers({
    auth,
    profile,
    forest,
    posts,
    plant,
    breezes,
});
