// Library Imports
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import axios from 'axios'

// Assets
import './modal.css'
import './breeze-modal.css'

const BreezeModal = ({ closeModal, forests, breezeContent, currentUser, currentMushroomMode, parentCB }) => {

    const [selectionArray, setSelectionArray] = useState([])

    const [selectionCount, setSelectionCount] = useState(0)

    useEffect(() => {
        let tempSelectionArray = []
        for (var i = 0; i < forests.length; i++) {
            tempSelectionArray.push(false)
        }
        setSelectionArray(tempSelectionArray);
    }, [])

    const selectionHandler = (index) => {
        let tempSelectionArray = [...selectionArray]
        tempSelectionArray[index] = !tempSelectionArray[index]
        setSelectionArray(tempSelectionArray);
    }

    useEffect(() => {
        console.log(selectionArray)
        let tempSelectionCount = 0;
        for (var i = 0; i < selectionArray.length; i++) {
            if (selectionArray[i]) tempSelectionCount++;
        }
        setSelectionCount(tempSelectionCount)
    }, [selectionArray])

    const handleMakePost = () => {

        var selectedForests = []

        for (var i = 0; i < selectionArray.length; i++) {
            if (selectionArray[i]) selectedForests.push(forests[i]._id)
        }

        //Determine targetUsers from forest


        let breezeObj = {
            user: currentUser._id,
            name: currentUser.name,
            avatar: currentUser.avatar,
            text: breezeContent,
            targetForests: selectedForests,
            postType: 'breeze',
            isMushroom: currentMushroomMode,
        }

        if (currentMushroomMode) {
            breezeObj.name = "Anonymous Mushroom"
            breezeObj.avatar = "https://freevintageillustrations.com/wp-content/uploads/2017/11/mushroom-illustrations-public-domain-19th-century.jpg"
        }


        console.log('breezeObj:')
        console.log(breezeObj)

        axios.post('/api/posts/breeze', breezeObj).then(res => {
            parentCB();
            console.log(res)
        }).catch(err => console.log(err));




    }



    return (
        <div className='modal-content-container'>
            <div className='modal-title'>Choose Your Forests</div>
            <div className='modal-description'>Choose which forests you'd like to blow this breeze into:</div>

            <div className='modal-content'>
                <form>

                    {forests.map((item, index) => (
                        <div
                            className={selectionArray[index] ? 'modal-selection-option selected clickable' : 'modal-selection-option clickable'}
                            onClick={() => selectionHandler(index)}
                            key={index}>
                            <div className='modal-selection-title' key={index}>{item.title}</div>
                        </div>
                    ))}


                </form>

            </div>

            <div className='modal-control-container'>
                <button className='modal-cancel-btn clickable' onClick={closeModal}>Cancel</button>
                {selectionCount === 0 ?
                    <button
                        className='modal-continue-btn inactive'>
                        Select Forest(s)
                    </button>
                    :
                    <button
                        className='modal-continue-btn clickable'
                        onClick={() => {
                            handleMakePost();
                            closeModal();
                        }}>
                        Post to {selectionCount} {selectionCount === 1 ? 'Forest' : 'Forests'}
                    </button>

                }

            </div>
            <div className='all-trees-everywhere-breeze-container'>
                <div
                    className='all-trees-everywhere-breeze-button clickable'
                    onClick={() => {
                        handleMakePost();
                        closeModal();
                    }}
                >Or Click Here to Post to All Trees Everywhere</div>
            </div>

        </div>
    )
}

const mapStateToProps = state => ({
    forests: state.forest.forests,
    currentUser: state.auth.user,
    currentMushroomMode: state.profile.profile.mushroomMode,
})

BreezeModal.propTypes = {
    forests: PropTypes.array.isRequired,
}

export default connect(mapStateToProps, {})(BreezeModal);