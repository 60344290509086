

import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons'

import Navbar from '../../components/Navbar/Navbar'


import './roots.css'
import RootsCanvas from './RootsCanvas'


// ! Currently Unused, though it is a desired feature to add.
export default function Roots() {
    return (
        <div className='roots-container'>
            <div className='base-camp-static-bg' />
            <Navbar selected='roots' />
            <div className='roots-content content-container'>
                <div className='roots-title'>You're In Control.</div>
                <RootsCanvas />

                <div className='roots-selector-tool sub-content-container'>
                    <div className='btn-arrow clickable'><FontAwesomeIcon className='btn-arrow' icon={faCaretLeft} /></div>
                    <div className='selected-forest clickable'>Selected Tree Rings</div>
                    <div className='btn-arrow clickable'><FontAwesomeIcon className='btn-arrow' icon={faCaretRight} /></div>
                </div>

            </div>
        </div>
    )
}
