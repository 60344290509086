// Library Imports
import React, { useState, useEffect } from 'react'
import axios from 'axios'

// Assets
import '../css/roots-block.css'

// ! I think this is unused now that we've moved it into the feed ? Check to make sure.

export default function RootsBlock() {

    const [allAds, setAllAds] = useState([])

    useEffect(() => {
        axios.get('/api/ads').then(res => {
            setAllAds(res.data)
        }).catch(err => console.log(err))
    }, [])

    //Add another Get API command where it looks for similar tags to user interests.

    return (
        <div className='roots-block-container'>
            <div className='title'>Roots</div>

            {allAds.map(ad => {
                return (<a target='_blank' href={ad.targetUrl}>
                    <div className='card root-card clickable'>
                        <div className='root-card-img-container'>
                            <img className='root-card-img' alt='adImg' src={ad.mainImage} />
                        </div>
                        <div className='card-text-container'>
                            <div className='card-title'>{ad.title}</div>
                            <div className='card-text'> {ad.description}</div>
                        </div>
                    </div>
                </a>)
            })}


        </div>
    )
}
