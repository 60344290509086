import axios from 'axios'

//Get current user's forests
export const createAd = (adObj) => async dispatch => {

    try {
        let user = await axios.get('api/auth/checkAdmin');
        console.log('got user:')
        console.log(user)
        if (user.data.isAdmin) {
            console.log('is admin!');
            console.log(adObj);
            const res = await axios.post(`api/ads`, adObj);
            return res.status(200).json({ msg: 'Ad Posted Successfully!' })

        } else {
            console.log('is NOT admin!');
            console.log(adObj);
            return
        }
    } catch (err) {
        console.log(err)
    }
}