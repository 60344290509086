import React from 'react'

import '../ui-library.css'
import './button.css'

// TODO: Blossom / Thorn Buttons!

export default function UIButtons() {
    return (
        <div className='ui-element-container'>
            <div className='ui-element-title'>Buttons</div>
            <div className='ui-element-row'>
                <div className='btn-background pill primary'>
                    <div className='btn-text'>Primary Pill</div>
                </div>

                <div className='btn-background rounded primary'>
                    <div className='btn-text'>Primary Rounded</div>
                </div>

                <div className='btn-background uncut primary'>
                    <div className='btn-text'>Primary Uncut</div>
                </div>
            </div>

            <div className='ui-element-row'>
                <div className='btn-background pill mushroom'>
                    <div className='btn-text'>Mushroom Pill</div>
                </div>

                <div className='btn-background rounded mushroom'>
                    <div className='btn-text'>Mushroom Rounded</div>
                </div>

                <div className='btn-background uncut mushroom'>
                    <div className='btn-text'>Mushroom Uncut</div>
                </div>
            </div>

            <div className='ui-element-row'>
                <div className='btn-background pill secondary'>
                    <div className='btn-text'>Secondary Pill</div>
                </div>

                <div className='btn-background rounded secondary'>
                    <div className='btn-text'>Secondary Rounded</div>
                </div>

                <div className='btn-background uncut secondary'>
                    <div className='btn-text'>Secondary Uncut</div>
                </div>
            </div>

            <div className='ui-element-row'>
                <div className='btn-background pill danger'>
                    <div className='btn-text'>Danger Pill</div>
                </div>

                <div className='btn-background rounded danger'>
                    <div className='btn-text'>Danger Rounded</div>
                </div>

                <div className='btn-background uncut danger'>
                    <div className='btn-text'>Danger Uncut</div>
                </div>
            </div>

            <div className='ui-element-row'>
                <div className='btn-background pill light'>
                    <div className='btn-text'>Light Pill</div>
                </div>

                <div className='btn-background rounded light'>
                    <div className='btn-text'>Light Rounded</div>
                </div>

                <div className='btn-background uncut light'>
                    <div className='btn-text'>Light Uncut</div>
                </div>
            </div>

            <div className='ui-element-row'>
                <div className='btn-background pill inactive'>
                    <div className='btn-text'>Inactive Pill</div>
                </div>

                <div className='btn-background rounded inactive'>
                    <div className='btn-text'>Inactive Rounded</div>
                </div>

                <div className='btn-background uncut inactive'>
                    <div className='btn-text'>Inactive Uncut</div>
                </div>
            </div>

            

        </div>
    )
}
