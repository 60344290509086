import React, { useState, useEffect } from 'react'
import axios from 'axios'

import './single-image-upload.css'

export default function SingleImageUpload({ mainPhotoFunc, apiTarget }) {

    // State Definition 
    const [currentFile, setCurrentFile] = useState(null);
    const [displayFile, setDisplayFile] = useState(null);
    const [displayError, setDisplayError] = useState(null);

    const [waitingForUpload, setWaitingForUpload] = useState(false);

    // Called on File Select, sets state.
    const onFileChange = (e) => {
        setDisplayError(null);
        setCurrentFile(e.target.files[0]);
        if (e.target.files[0].size > 2000000) {
            setDisplayError('File too large. Max size: 2MB')
            return
        }
    }

    // Called on Submit, handles which protocol to use (single or multi)
    const onFileUpload = () => { //Submitted for Upload
        singleUploadHandler();
    };

    //Calls Single Image upload from API 
    const singleUploadHandler = () => {
        if (currentFile != null) {
            setWaitingForUpload(true);

            // Create an object of formData 
            const data = new FormData();

            // Update the formData object 
            data.append('image', currentFile, currentFile.name);

            //Post to proper route
            axios.post(`/api/files/${apiTarget}`, data, {
                headers: {
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                }
            })
                .then((response) => {
                    if (200 === response.status) {
                        // If file size is larger than expected.
                        if (response.data.error) {
                            if ('LIMIT_FILE_SIZE' === response.data.error.code) {
                                setDisplayError('File too large. Max size: 2MB');
                            } else {
                                setDisplayError(response.data.error); // If not the given file type
                            }
                        } else {
                            // Success
                            let fileName = response.data;
                            console.log('fileName', fileName);
                            console.log('File Uploaded');
                            setDisplayFile(fileName.location);
                            setWaitingForUpload(false);
                        }
                    }
                }).catch((error) => {
                    // If another error
                    setDisplayError(error);
                    setWaitingForUpload(false);
                });

        } else {
            // if file not selected throw error
            setDisplayError('Please select one or more files to upload');
        }

    }


    //Rendering Functions
    //Displays Photo once it has been uploaded to s3.
    const displayUploadedPhoto = () => {
        if (displayFile != null) {
            return (
                <div className='main-photo-container'>
                    <img alt='Currently Selected' src={displayFile} style={{ maxHeight: '400px', maxWidth: '600px' }} />
                </div>
            );
        } else {
            return (
                <div className='main-photo-container'>
                    Photo preview will appear here.
                </div>
            );
        }
    };

    //Displays Any errors if they are present.
    const displayAnyErrors = () => {
        if (displayFile != null) {
            return (<div style={{ color: 'red', margin: '1rem' }}>{' '}</div>);
        } else {
            return (<div style={{ color: 'red', margin: '1rem', textAlign: 'center' }}>{displayError}</div>);
        }
    };

    // Effect Hooks
    // Forces a re-render since React won't do it for the errors.
    useEffect(() => {
        displayAnyErrors();
    }, [displayError])

    //Sends Data to parent any time it is changed
    // useEffect(() => {
    //     galleryInfoFunc(currentGalleryURLs);
    // }, [currentGalleryURLs])

    useEffect(() => {
        if (displayFile !== null && displayFile !== undefined) {
            mainPhotoFunc(displayFile);
        }

    }, [displayFile])


    return (
        <div className='single-image-upload-container'>
            <div className='single-image-upload-input'>
                <input type="file" onChange={(e) => onFileChange(e)} />
                {waitingForUpload ?
                    <div className='centered-text'>
                        Uploading...
                    </div>
                    :
                    <button className='upload-btn' onClick={() => onFileUpload()}>
                        Click To Upload!
                    </button>
                }
            </div>
            {displayAnyErrors()}
            {displayUploadedPhoto()}

        </div>
    )
}
