import React, { useState } from 'react'

import axios from 'axios'

import './notifications.css'
import { set } from 'mongoose';

export default function NotificationItem({ type, currentUser, userHasSeen, userResponse, sourceUser, sourceUserName, sourceUserAvatar, targetPost, targetName, notificationId }) {

    const [barkTitle, setBarkTitle] = useState('');

    const handleUserAction = (userResponse) => {

        let updateObj = { userHasSeen: true }

        if (userResponse === 'approve') {
            updateObj.userResponse = 'approved'

            //Kind of confusing, but we're sending a notification back to the original person, from our current person.
            let notificationObj = {
                sourceUser: currentUser._id,
                sourceUserName: currentUser.name,
                sourceUserAvatar: currentUser.avatar,
                targetUser: sourceUser,
                notificationType: 'barkPostApproved',
                targetPost: targetPost,
                targetName: targetName,
            }

            console.log(notificationObj);
            axios.post('/api/notifications/', notificationObj).then(res => console.log(res)).catch(err => console.log(err))
            axios.put(`/api/posts/bark/approveUser/${targetPost}/${sourceUser}`).then(res => console.log(res)).catch(err => console.log(err))

        } else if (userResponse === 'decline') {
            updateObj.userResponse = 'declined'
            axios.put(`/api/posts/bark/removeUser/${targetPost}/${sourceUser}`).then(res => console.log(res)).catch(err => console.log(err))

        } else if (userResponse === 'cultivate') {
            updateObj.userResponse = 'cultivatedPlant'
            // Completed route to pass notification etc. in seed drop protocol.
        } else if (userResponse === 'visitProfile') {
            // Link to profile is in 'a' tag wrapping source div
        } else if (userResponse === 'visitPlant') {
            // Link to plant is in 'a' tag wrapping source div
        } else if (userResponse === 'visitPost') {
            // Link to post is in 'a' tag wrapping source div
        } else {
            updateObj.userResponse = ''
        }

        console.log('updateObj to send:');
        console.log(updateObj);

        axios.put(`/api/notifications/${notificationId}`, updateObj).then(res => console.log(res)).catch(err => console.log(err));
    }

    const renderBtns = (type) => {

        //Approval Type Posts (Bark only for now)
        if (type === 'barkRequest') {
            if (userResponse === 'approved') {
                return (<>
                    <div className='notification-action-btn selected'>Approved</div>
                    <div className='notification-action-btn inactive'
                        onClick={() => handleUserAction('decline')}>
                        Decline
                    </div>
                </>)

            } else if (userResponse === 'declined') {
                return (<>
                    <div className='notification-action-btn inactive'
                        onClick={() => handleUserAction('approve')}>
                        Approve
                    </div>
                    <div className='notification-action-btn selected'>
                        Declined
                    </div>
                </>)

            } else {
                return (<>
                    <div className='notification-action-btn' onClick={() => handleUserAction('approve')}>Approve</div>
                    <div className='notification-action-btn' onClick={() => handleUserAction('decline')}>Decline</div>
                </>)
            }
        }

        //Seed Drop Type Posts
        else if (type === 'seedDrop' || type === 'seedPass') {
            if (userResponse === 'cultivatedPlant') {
                return (<div className='notification-action-btn no-action'>Plant Cultivated</div>)

            } else if (userResponse === 'passedPlant') {
                return (<div className='notification-action-btn no-action'>Plant Passed</div>)

            } else {
                return (<>
                    <a href={`/createplant/?id=${targetPost}`}>
                        <div className='notification-action-btn'
                            onClick={() => handleUserAction('cultivate')}>
                            Click to Cultivate
                            </div>
                    </a>
                </>)
            }

        }

        else if (type === 'seedFail') {
            return (
                <a href='/breezehistory'>
                    <div className='notification-action-btn green-no-action clickable'
                        onClick={() => handleUserAction('viewAllBreezes')}>
                        View All Breezes
                        </div>
                </a>)


        }

        // Visit Plant Types
        else if (
            type === 'tagNotification' ||
            type === 'plantCultivated' ||
            type === 'plantAddedToField' ||
            type === 'userBlossomedPost' ||
            type === 'userThornedPost'
        ) {
            return (
                <a href={`/plant/?id=${targetPost}`}>
                    <div className='notification-action-btn'
                        onClick={() => handleUserAction('visitPlant')}>
                        Visit Plant
                    </div>
                </a>
            )

        }

        //Visit Profile Types
        else if (type === 'barkPostApproved') {
            return (
                <a href={`/profile/?id=${sourceUser}`}>
                    <div className='notification-action-btn'
                        onClick={() => handleUserAction('visitProfile')}>
                        Visit Profile
                    </div>
                </a>
            )

        }

        //Visit Post Types
        else if (type === 'userCommentedOnPost' || type === 'userBlossomedComment' || type === 'userThornedComment') {
            return (
                <a href={`/post/?id=${targetPost}`}>
                    <div className='notification-action-btn'
                        onClick={() => handleUserAction('visitPost')}>
                        View Post
                    </div>
                </a>
            )
        }

        //Generic Default
        else {
            return (
                <div className='notification-action-btn' onClick={() => handleUserAction('visitPlant')}>
                    Visit Plant
                </div>
            )
        }
    }

    const renderText = () => {
        if (type === 'barkRequest') {
            axios.get(`/api/posts/bark/getPost/${targetPost}`).then(res => {
                setBarkTitle(res.data[0].title);
            }).catch(err => console.log(err))
            return (`${sourceUserName} has requested to view your mushroom post "${barkTitle}."`)

        } else if (type === 'barkPostApproved') {
            return (`${sourceUserName} has approved your request to view "${targetName}."`)

        } else if (type === 'tagNotification') {
            return (`${sourceUserName} has tagged you in ${targetName}`)

        } else if (type === 'seedDrop') {
            return ('Congratulations! Your Breeze has become a seed!')

        } else if (type === 'seedFail') {
            return ('Sorry, your Breeze failed to drop a seed.')

        } else if (type === 'plantCultivated') {
            return ('A Breeze you supported just became a plant!')

        } else if (type === 'seedPass') {
            return ('Someone offered you a seed to cultivate!')

        } else if (type === 'plantAddedToField') {
            return (`${sourceUserName} has added ${targetName} to their field!`)

        } else if (type === 'userBlossomedPost') {
            return (`${sourceUserName} blossomed your post in ${targetName}!`)

        } else if (type === 'userThornedPost') {
            return (`${sourceUserName} thorned your post in ${targetName}`)

        } else if (type === 'userCommentedOnPost') {
            return (`${sourceUserName} commented on your post!`)

        } else if (type === 'userBlossomedComment') {
            return (`${sourceUserName} blossomed your comment!`)

        } else if (type === 'userThornedComment') {
            return (`${sourceUserName} thorned your comment`)

        } else {
            return (`${sourceUserName} has posted in ${targetName}`)
        }
    }


    return (
        <div className={userHasSeen === false ? 'notification-item-container sub-content-container' : 'notification-item-container seen'}>
            <div className='notification-icon-container'>
                {userHasSeen === false ? <div className='notification-dot' /> : <div className='notification-dot white' />}
            </div>
            <div className='notification-content-container'>
                <div className='notification-text-container'>
                    {renderText()}
                </div>
                <div className={userHasSeen === false ? 'notification-action-container' : 'notification-action-container seen'}>
                    {renderBtns(type)}
                </div>

            </div>


        </div>
    )
}
