import axios from 'axios'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import PostBlock from '../../components/PostBlock/PostBlock'
import Navbar from '../../components/Navbar/Navbar'

import { connect } from 'react-redux'

import './single-post-view.css'

const SinglePostView = ({ auth, profile }) => {

    const [post, setPost] = useState({})
    const [postId, setPostId] = useState()
    useEffect(() => {
        setPostId(window.location.href.split('?id=')[1])
    }, [])


    useEffect(() => {
        axios.get(`/api/posts/${postId}`).then(res => {
            console.log(res)
            setPost(res.data[0])
        }).catch(err => console.log(err))
    }, [postId])

    const [currentlyMushroomMode, setCurrentlyMushroomMode] = useState(false)
    useEffect(() => {
        if (profile.profile != null && profile.profile != undefined) {
            setCurrentlyMushroomMode(profile.profile.mushroomMode)
        }
    }, [profile])

    const [currentUser, setCurrentUser] = useState({ _id: '', name: '', avatar: '' })
    useEffect(() => {
        if (auth != null && auth != undefined) {
            if (auth.user != null && auth.user != undefined) {
                setCurrentUser(auth.user)
            }
        }
    }, [auth])

    useEffect(() => {
        console.log(post)
    }, [post])


    return (
        <div className='single-post-page-container'>
            <div className={currentlyMushroomMode ? 'base-camp-static-bg mushroom' : 'base-camp-static-bg'} />
            <Navbar />

            {post.text == undefined ? <>Loading...</> :
                <PostBlock
                    postId={post._id}
                    key={post._id}
                    userName={post.name}
                    postUserId={post.user._id}
                    postUserAvatar={post.avatar}
                    postTime={post.date}
                    postContents={post.text}
                    supportCount={post.supportingUsers.length}
                    potentialGuide={post.user}
                    downVoteCount={post.downvoteUsers.length}
                    blossoms={post.blossoms}
                    thorns={post.thorns}
                    postType={post.postType}
                    supportLimit={post.supportLimit}
                    targetPlant={post.targetPlant}
                    currentUser={currentUser}
                    taggedUsers={post.taggedUsers}
                    attachedImage={post.attachedImage}
                    comments={post.comments}
                    profile={profile}
                    autoExpandedComments={true}
                />
            }
        </div>
    )
}


const mapStateToProps = state => ({
    forest: state.forest,
    posts: state.posts.posts,
    auth: state.auth,
    profile: state.profile
})


export default connect(mapStateToProps, null)(SinglePostView);