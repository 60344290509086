// Library Imports
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import axios from 'axios'

// Components
import Navbar from '../../components/Navbar/Navbar'
import BarkPost from './components/BarkPost'
import LargeProfileCard from '../../components/Cards/ProfileCard/LargeProfileCard'
import ProfileField from './components/ProfileField'

// Assets
import './css/profile.css'


export default function Profile() {

    const [profileDetails, setProfileDetails] = useState({
        profilePhoto: '',
        name: 'loading...',
        memberSince: 'loading...',
    })
    const userId = window.location.href.split('?id=')[1]
    const [currentUserId, setCurrentUserId] = useState('')
    const [currentUserName, setCurrentUserName] = useState('')
    const [currentUserAvatar, setCurrentUserAvatar] = useState('')


    const [userIsInForest, setUserIsInForest] = useState(false)
    const [mushroomMode, setMushroomMode] = useState(false)
    const [userBark, setUserBark] = useState([])


    //On Page load, get all data
    useEffect(() => {
        axios.get(`/api/profile/user/${userId}`).then(res => {
            console.log(res.data)
            var formattedDate = moment(res.data.date).format('MMM YYYY');
            setProfileDetails({
                profilePhoto: res.data.user.avatar,
                name: res.data.user.name,
                memberSince: formattedDate
            })


        }).catch(err => {
            console.log(err)
            setProfileDetails({
                profilePhoto: '',
                name: 'Error',
                memberSince: 'Error'
            })
        });

        axios.get('/api/profile/me').then(res => {
            console.log('response from user profile stuff')
            console.log(res.data);
            console.log(res.data.forests[0]);

            const usersInForest = [...res.data.forests[0].users];
            const forestId = res.data.forests[0]._id

            setCurrentUserId(res.data.user._id)
            setCurrentUserName(res.data.user.name)
            setCurrentUserAvatar(res.data.user.avatar)


            setMushroomMode(res.data.mushroomMode)

            if (usersInForest.indexOf(userId) !== -1) {
                setUserIsInForest(true)
            }

        }).catch(err => console.log(err))

        axios.get(`/api/posts/bark/${userId}`).then(res => {
            console.log('got dat user bark')
            console.log(res.data);
            setUserBark(res.data)
        }).catch(err => console.log(err))

    }, [])


    const handleForestAdd = () => {
        axios.get('/api/profile/me').then(res => {
            console.log(res.data.forests[0]);
            const usersInForest = [...res.data.forests[0].users];
            const forestId = res.data.forests[0]._id

            let updateObj = {}
            //If user is in forest, then we want to remove. Otherwise, add.
            if (userIsInForest) {
                const filterArray = usersInForest.filter((id) => id !== userId);
                updateObj.users = filterArray
            } else {
                usersInForest.push(userId);
                updateObj.users = usersInForest
            }
            axios.post(`/api/forest/addUser/${forestId}`, updateObj).then(res => {
                console.log(res)
                setUserIsInForest(!userIsInForest)
            }).catch(err => console.log(err))

        }).catch(err => console.log(err))
    }

    const [barkToRender, setBarkToRender] = useState([])
    useEffect(() => {
        console.log(`Bark changed to:`)
        console.log(userBark)
        if (userBark.length > 0) {
            let tempBarkToRender = []

            for (var i = 0; i < userBark.length; i++) {
                let currentBark = { ...userBark[i] }
                if (currentBark.showPost) {
                    if (currentBark.isMushroom) { //If post is mushroom post
                        console.log('inside isMushroom protocol')
                        console.log(currentBark.visibleTo)
                        console.log(currentUserId)
                        if (!currentBark.visibleTo.includes(currentUserId)) { //If user is not whitelisted to view mushroom post, hide everything.
                            currentBark.title = 'Mushroom Post'
                            currentBark.attachment = 'https://freevintageillustrations.com/wp-content/uploads/2017/11/mushroom-illustrations-public-domain-19th-century.jpg'
                            currentBark.text = ''
                            currentBark.eventDate = '---'
                            currentBark.tags = ['']
                        }
                    }
                    tempBarkToRender.push(currentBark)
                }
            }

            setBarkToRender(tempBarkToRender)

        }
    }, [currentUserId]) //Triggered once UserId is retreived.


    //For Testing
    // useEffect(() => {
    //     console.log(`Bark changed to:`)
    //     console.log(userBark)
    // }, [userBark])


    useEffect(() => {
        console.log('Bark to Render:')
        console.log(barkToRender)
    }, [barkToRender])

    let history = useHistory();

    const handleAddBark = () => {
        history.push('/createBark')
    }


    return (
        <div className='profile-page-container content-container'>
            <div className={mushroomMode ? 'base-camp-static-bg mushroom' : 'base-camp-static-bg'} />
            <Navbar />
            <div className='profile-top row'>
                <ProfileField />
                <div className='profile-name-container sub-content-container'>
                    <div className='profile-summary-container'>

                        <LargeProfileCard
                            name={profileDetails.name}
                            joinedDate={profileDetails.memberSince}
                            profileImage={profileDetails.profilePhoto}
                        />


                    </div >
                    {currentUserId === userId
                        ?
                        <div className='add-to-forest-btn clickable' onClick={() => handleAddBark()}>+ Add Bark To Your Profile</div>
                        :
                        userIsInForest
                            ?
                            <div className='remove-from-forest-btn clickable' onClick={() => handleForestAdd()}>Remove From Forest</div>
                            :
                            <div className='add-to-forest-btn clickable' onClick={() => handleForestAdd()}>+ Add to My Forest</div>
                    }
                </div>
            </div>
            <div className='row'>
                <div className='sub-content-container bark-parent'>
                    Bark
                    <div className='bark-container'>
                        {barkToRender.length > 0
                            ?
                            <>
                                {barkToRender.map(item =>
                                    <BarkPost
                                        currentUser={currentUserId}
                                        currentUserName={currentUserName}
                                        currentUserAvatar={currentUserAvatar}
                                        postId={item._id}
                                        postUser={item.user}
                                        postTitle={item.title}
                                        postImg={item.attachment}
                                        postText={item.text}
                                        postTag={item.tags[0]}
                                        postTime={item.eventDate || item.date}
                                        showAttachment={false}
                                        isMushroom={item.isMushroom}
                                        visibleTo={item.visibleTo}
                                        postContents='This is a Bark post' />)}
                            </>
                            :
                            <div className='nothing-container'>Nothing here yet!</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
