// Library Imports
import React, { useState, useEffect } from 'react'
import Moment from 'react-moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { ReactTinyLink } from 'react-tiny-link';
import axios from 'axios'

// Assets
import '../css/barkpost.css';

export default function BarkPost({ showAttachment, postTime, postContents, postType, postText, postImg, postTitle, postTag, isMushroom, currentUser, currentUserName, currentUserAvatar, postUser, postId, visibleTo }) {

    const [attachment, setAttachment] = useState(showAttachment)
    const [urlAttached, setUrlAttached] = useState(false)
    const [tinyLinkUrl, setTinyLinkUrl] = useState("")

    useEffect(() => {
        checkLink(postContents);
    }, [tinyLinkUrl])

    // eslint-disable-next-line 
    const urlRegex = new RegExp('(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$', 'g');


    const checkLink = (text) => {
        const testArray = text.split(' ');
        var linkFound = false

        //Loop Through Array
        for (var i = 0; i < testArray.length; i++) {
            if (urlRegex.test(testArray[i])) {
                setTinyLinkUrl(testArray[i])
                setUrlAttached(true)
                linkFound = true;
            }
        }

        //Run the test on the full string, in case the entire text is a link
        if (urlRegex.test(text) && !linkFound) {
            setTinyLinkUrl(text)
            setUrlAttached(true)
        }
    }

    const handleRequestToView = () => {

        let notificationObj = {
            sourceUser: currentUser,
            sourceUserName: currentUserName,
            sourceUserAvatar: currentUserAvatar,
            targetUser: postUser,
            notificationType: 'barkRequest',
            targetPost: postId,
            targetName: postTitle
        }

        console.log(notificationObj);

        axios.post('/api/notifications', notificationObj).then(res => console.log(res)).catch(err => console.log(err))

    }

    //Dropdown menu stuff:
    const [dropdownMenu, toggleDropdownMenu] = useState(false);
    const [dropdownMenuOptions, setDropdownMenuOptions] = useState(<>
        <div className='post-block-dropdown-option'>Report Post</div>
    </>)
    const [currentUserIsAdmin, setCurrentUserIsAdmin] = useState(false)
    //Determine if user is admin
    axios.get('api/auth/checkAdmin').then(res => {
        setCurrentUserIsAdmin(res.data.isAdmin)
    }).catch(err => console.log(err));

    //Use Effect to populate dropdown post contents.
    useEffect(() => {
        if (currentUser === postUser || currentUserIsAdmin) {
            setDropdownMenuOptions(<>
                {/* <div className='post-block-dropdown-option'>Edit Post</div> */}
                <div className='post-block-dropdown-option' onClick={() => handlePostDelete()}>Delete Post</div>
            </>)
        } else {
            setDropdownMenuOptions(<>
                <a href={`https://docs.google.com/forms/d/e/1FAIpQLScKEI8ciA2xXboYaxLFiv6BWUWti9GZkDqnDt9Z6w_jgKAPQA/viewform?usp=pp_url&entry.1105456013=${postId}`} rel="noopener noreferrer" target='_blank'><div className='post-block-dropdown-option'>Report Post</div></a>
            </>)
        }

    }, [dropdownMenu, currentUserIsAdmin])

    const handlePostDelete = () => {
        toggleDropdownMenu(false)
        console.log(postId)
        var r = window.confirm("Are you sure you'd like to delete this post? Press OK to delete. This cannot be undone.");
        if (r == true) {
            axios.put(`/api/posts/hideBark/${postId}`).then(res => console.log(res)).catch(err => console.log(err))
            window.location.reload(false)
        } else {
        }

    }


    return (
        <div className='bark-post-container'>
            <div className='bark-card-container sub-content-container'>
                <div className='bark-post-title'>{postTitle}</div>

                <div className='bark-post-dropdown-menu'>
                    <div className='bark-post-dropdown-btn' onClick={() => toggleDropdownMenu(!dropdownMenu)}><FontAwesomeIcon icon={faEllipsisV} /></div>
                    <div className={dropdownMenu ? 'bark-post-dropdown-options-container' : 'bark-post-dropdown-options-container hide'}>
                        {dropdownMenuOptions}
                    </div>
                </div>

                <div className='bark-post-content-container'>
                    <div className='bark-post-img-container'>
                        {postImg !== '' && postImg !== null && postImg !== undefined
                            ?
                            <img className='bark-img' alt='Bark' src={postImg} />
                            :
                            <></>
                        }
                    </div>
                    <div className='bark-post-text-container'>{postText}</div>
                </div>
                {attachment ?
                    (<>
                        <div className='feed-post-attachment-container'>
                            Attachment To Post
                    </div>
                    </>) : (<> </>)
                }
                {urlAttached ? <>
                    <ReactTinyLink
                        cardSize="small"
                        showGraphic={true}
                        maxLine={2}
                        minLine={1}
                        url={tinyLinkUrl} />
                </> : <></>}

                <div className='bark-post-footer'>
                    {(isMushroom && currentUser !== postUser && !visibleTo.includes(currentUser))
                        ?
                        <div className='request-btn' onClick={() => handleRequestToView()}>Request Access To Post</div>
                        :
                        <div><Moment className='time' format="LL">{postTime}</Moment></div>
                    }

                    <div>{postTag}</div>
                </div>
            </div>
        </div>

    )
}