// Library Imports
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import Modal from 'react-modal'
import { connect } from 'react-redux'

// Redux Actions
import { getCurrentProfile } from '../../redux/actions/profile'
import { getUserForestsById } from '../../redux/actions/forest'
import { makePost, getPosts } from '../../redux/actions/posts'

// Components
import PostBlock from '../../components/PostBlock/PostBlock'
import Navbar from '../../components/Navbar/Navbar'
import MakePlantPost from './components/MakePlantPost';
import PlantGallery from './components/PlantGallery';
import RootsBlock from '../basecamp/components/RootsBlock'
import TagUserModal from '../../components/Modals/TagUserModal'
import ImageUploadModal from '../../components/Modals/ImageUploadModal'

// Assets
import './css/plant.css'


const PlantMVP = ({ makePost, getPosts, posts, auth, profile, getUserForestsById }) => {


    //Modal Functions
    Modal.setAppElement(document.getElementById('root'));

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '2rem',
            zIndex: '10000'
        },
        overlay: { zIndex: 1001 }
    };

    const [tagUserModalIsOpen, setTagUserModalIsOpen] = useState(false);
    const [imageUploadModalIsOpen, setImageUploadModalIsOpen] = useState(false);

    const openUserToForestModal = () => {
        setTagUserModalIsOpen(true);
    }

    const openCreateForestModal = () => {
        setImageUploadModalIsOpen(true);
    }

    const closeModal = () => {
        setTagUserModalIsOpen(false);
        setImageUploadModalIsOpen(false);
    }



    const [plantId, setPlantId] = useState(window.location.href.split('?id=')[1]);

    const [currentUserId, setCurrentUserId] = useState('');

    //Determine whether or not profile is currently in mushroom mode.
    const [postAsMushroom, togglePostAsMushroom] = useState(false);
    useEffect(() => {
        if (profile.profile) {
            togglePostAsMushroom(profile.profile.mushroomMode);

        }
    }, [profile]);

    //Used to change state and trigger a reload
    const [postHandler, setPostHandler] = useState(0);

    //Function sent to child objects to push notifications upwards
    const notifyMeOfChanges = () => {
        console.log('callback received');
        setPostHandler(postHandler + 1);

        //TODO: Get this working!! 
        //Fake adding the post, or fake removing the post.


        //const tempCurrentFeed = [...currentFeed]
        //etc.

    }

    //Initialize with Empty Plant Object
    const [currentPlantObject, setCurrentPlantObject] = useState({
        title: "Loading...",
        description: "Loading...",
        owner: "",
        contributors: [],
        images: [],
        mainImage: "",
        fertilizer: [],
    });


    const [plantOwner, setPlantOwner] = useState({
        name: '',
        avatar: ''
    })

    //Get Plant on Page load
    useEffect(() => {
        axios.get(`/api/plant/${plantId}`).then(res => {
            setCurrentPlantObject(res.data);
            axios.get(`/api/users/populateUser/${res.data.owner}`).then(res => {
                setPlantOwner(res.data)
            })
        }).catch(err => console.log(err))
    }, [plantId])


    //useEffect to refresh feed on change
    useEffect(() => {
        axios.get(`/api/posts/plant/${plantId}`).then(res => {
            setCurrentFeed(res.data);
        })
            .catch(err => console.log(err))
    }, [postHandler])

    //Waits for auth to load, then verifies whether or not this plant belongs to the user, as well as if the user is a site admin
    useEffect(() => {
        if (auth.user !== null) {
            setCurrentUserId(auth.user._id);
            getUserForestsById(auth.user._id);
        }

    }, [auth.loading])

    //Get Feed on Plant Load
    const [currentFeed, setCurrentFeed] = useState([])
    useEffect(() => {
        axios.get(`/api/posts/plant/${plantId}`).then(res => {
            let fullResponse = res.data;
            let feedObj = []
            for (var i = 0; i < fullResponse.length; i++) {
                if (fullResponse[i].showPost) {
                    feedObj.push(fullResponse[i])
                }
            }
            setCurrentFeed(feedObj);
            console.log(feedObj)

        })
            .catch(err => console.log(err))
    }, [currentPlantObject])


    //Populate all data once plant has been grabbed.
    const handleAddPlantToField = () => {
        let tempPlants = [...profile.profile.plants]
        tempPlants.push(plantId);

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const updateData = {
            plants: tempPlants
        }

        axios.post('/api/profile', updateData, config).then(res => {
            getCurrentProfile(); //Refresh Profile once we've got the data

            //Send Notification to owner that plant was added
            let notificationObj = {
                sourceUser: auth.user._id,//Source User Id
                sourceUserName: auth.user.name,//Source User Name
                sourceUserAvatar: auth.user.avatar,//Source User Avatar
                targetUser: currentPlantObject.owner,//Target User Id
                notificationType: 'plantAddedToField',//notificationType//Notification type
                targetPost: plantId,//Target Post Id
                targetName: currentPlantObject.title,//Target Post Title
            }
            axios.post('/api/notifications/', notificationObj).then(res => {
                //Refresh the page to show all changes
                window.location.reload();
            }).catch(err => {
                console.log(err)
            })


        }).catch(err => console.log(err))
    }

    const handleRemovePlantFromField = () => {
        console.log('removing plant.')
        //TODO: Remove plant from field

        let tempPlants = [...profile.profile.plants]
        tempPlants.splice(tempPlants.indexOf(plantId), 1);

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const updateData = {
            plants: tempPlants
        }

        axios.post('/api/profile', updateData, config).then(res => {
            getCurrentProfile(); //Refresh Profile once we've got the data
            window.location.reload(); //Refresh the page to show all changes
        }).catch(err => console.log(err))




    }

    const handlePlantEdit = () => {
        console.log('attempting edit function')

    }

    const handlePlantDelete = () => {
        console.log('attempting delete function')

    }

    //Check whether or not current plant Id is in user's field, and assign this variable appropriately
    const [userIsCultivator, setUserIsCultivator] = useState(false)
    useEffect(() => {
        if (profile.profile !== null && profile.profile !== undefined) {
            if (profile.profile.plants !== null && profile.profile.plants !== undefined) {
                if (profile.profile.plants.indexOf(plantId) !== -1) setUserIsCultivator(true)
            }
        }

    }, [profile])


    useEffect(() => {
        if (currentPlantObject.owner !== '' && currentPlantObject.owner === currentUserId) setUserIsCultivator(true)
    }, [currentUserId, currentPlantObject])


    return (
        <div className='plant-container'>
            <div className={postAsMushroom ? 'base-camp-static-bg mushroom' : 'base-camp-static-bg'} />
            <Navbar />

            <div className='plant-content content-container'>
                <div className='plant-header content-container'>
                    <div className='title'>
                        {currentPlantObject.title}
                    </div>
                    <div className='plant-header-info'>
                        <div className='plant-header-left'>
                            <PlantGallery imageArray={currentPlantObject.images} />
                        </div>

                        <div className='plant-header-summary'>
                            <div className='plant-header-summary-top'>

                                <div className='plant-header-about-plant'>
                                    {currentPlantObject.description}
                                </div>
                                <div className='plant-owner-block'>
                                    <div className='owner-label'>Plant Guide:</div>
                                    <div className='plant-header-about-owner'>
                                        <div className='profile-img-container small'>
                                            <img className='profile-summary-img' alt='Profile Picture' src={plantOwner.avatar} />
                                        </div>
                                        <div className='owner-info-column'>
                                            <div className='owner-name'>{plantOwner.name}</div>
                                            {/* <div>Denver, CO</div> */}
                                        </div>
                                    </div>

                                </div>

                                {currentPlantObject.noMoreCultivators && !userIsCultivator ?
                                    <></>
                                    :
                                    <MakePlantPost
                                        userIsCultivator={userIsCultivator}
                                        makePost={makePost}
                                        parentCallback={notifyMeOfChanges}
                                        currentUser={auth.user}
                                        currentMushroomMode={postAsMushroom}
                                        addToFieldFunc={handleAddPlantToField}
                                        currentPlant={currentPlantObject}
                                    />}

                                {currentPlantObject.owner === currentUserId ?
                                    <div className='plant-admin-btn-container'>
                                        <a href={`/editplant/?id=${plantId}`}><button className='add-btn clickable' onClick={() => handlePlantEdit()}>Edit Plant</button></a>
                                        <button className='delete-btn clickable' onClick={() => handlePlantDelete()}>Delete Plant</button>
                                    </div>
                                    :
                                    currentPlantObject.noMoreCultivators ?
                                        <div className='no-more-cultivators-container'>This plant is not currently accepting any more cultivators.</div>
                                        :
                                        <></>
                                }

                                {userIsCultivator && currentPlantObject.owner !== currentUserId ?
                                    <div className='plant-admin-btn-container'>
                                        <button className='remove-from-field-btn clickable' onClick={() => handleRemovePlantFromField()}>&#10006; Remove Plant from Field</button>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className='plant-detail-section'>
                    <div className='plant-detail-section-left content-container'>
                        Plant Feed
                        <div className='plant-detail-selected-content'>
                            {auth.user == null ? <>Loading...</> :
                                <div className='feed-block-display'>
                                    {currentFeed.length > 0 ? (<>
                                        {currentFeed.map(item => {
                                            return (
                                                //NOTE: ANY CHANGES HERE ALSO HAPPEN IN FORESTBLOCK
                                                <PostBlock
                                                    postId={item._id}
                                                    key={item._id}
                                                    userName={item.name}
                                                    postUserId={item.user._id}
                                                    postUserAvatar={item.avatar}
                                                    postTime={item.date}
                                                    edited={item.edited}
                                                    postContents={item.text}
                                                    supportCount={item.supportingUsers.length}
                                                    potentialGuide={item.supportingUsers[0]}
                                                    downVoteCount={item.downvoteUsers.length}
                                                    blossoms={item.blossoms}
                                                    thorns={item.thorns}
                                                    postType={item.postType}
                                                    supportLimit={item.supportLimit}
                                                    currentUser={auth.user}
                                                    taggedUsers={item.taggedUsers}
                                                    attachedImage={item.attachedImage}
                                                    targetPlant={item.targetPlant}
                                                    comments={item.comments}
                                                    profile={profile}
                                                />
                                            )
                                        }
                                        )}
                                    </>) :
                                        currentUserId === '' ?
                                            <a href='/signin'>Sign in to view posts</a>
                                            :
                                            <>Nothing here yet!</>

                                    }
                                </div>
                            }

                        </div>
                    </div>

                    <div className='plant-detail-section-right content-container'>
                        <RootsBlock />
                    </div>


                </div>


            </div>

            <Modal
                isOpen={tagUserModalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Tag User Modal"
            >
                <TagUserModal closeModal={closeModal} />
            </Modal>

            <Modal
                isOpen={imageUploadModalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Upload Image Modal"
            >
                <ImageUploadModal closeModal={closeModal} />
            </Modal>


        </div>
    )
}


PlantMVP.propTypes = {
    makePost: PropTypes.func.isRequired,
    getPosts: PropTypes.func.isRequired,
    getCurrentProfile: PropTypes.func.isRequired,
    getUserForestsById: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    forest: state.forest,
    posts: state.posts.posts,
    auth: state.auth,
    profile: state.profile,
})


export default connect(mapStateToProps, { makePost, getPosts, getCurrentProfile, getUserForestsById })(PlantMVP);