// Library Imports
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'

// Redux Actions
import { register, login } from '../../redux/actions/auth'

// Assets
import './signin.css'
import Logo from './images/Solutiontrees Logo_300px.png'

const SignIn = ({ register, login, isAuthenticated }) => {

    const [loginPage, setLoginPage] = useState(true)
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        password2: ''
    })

    const { firstname, lastname, email, password, password2 } = formData;


    //On load check for params in url
    useEffect(() => {
        //If we are coming from the mailchimp page
        if (window.location.href.split('?')[1] !== undefined) {

            if (window.location.href.split('?')[1].split('=')[0] === 'mailchimp') {
                const paramArray = window.location.href.split('?')[1].split('=')

                let tempEmail = paramArray[1].split('&')[0]
                let tempFirstName = paramArray[2].split('&')[0]
                let tempLastName = paramArray[3]

                let tempFormData = { ...formData }
                tempFormData.email = tempEmail
                tempFormData.firstname = tempFirstName
                tempFormData.lastname = tempLastName

                setFormData(tempFormData)
                setLoginPage(false)
            } else {
                //Not bounced here from mailchimp form
            }

        }

    }, [])



    useEffect(() => {
        checkPassMatch();
    }, [formData])

    const [passwordStrengthDisplay, setPasswordStrengthDisplay] = useState('')
    const [passwordStrengthMeter, setPasswordStrengthMeter] = useState(
        {
            border: '1px solid #e4e8f0;'
        }
    )

    const history = useHistory();

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const [signInError, setSignInError] = useState('')

    const [passwordsMatch, setPasswordsMatch] = useState({
        color: 'gray'
    })

    useEffect(() => {

    }, [passwordsMatch])

    const onSubmit = async e => {

        if (loginPage) {
            setSignInError('')
            login(email, password)
            setTimeout(() => {
                setSignInError('Username & password do not match.')
            }, 500);


        } else {
            if (password !== password2) {
                alert("Passwords do not match")
            } else {
                var name = firstname + ' ' + lastname
                register({ name, email, password })
            }
        }
    }




    //Redirect if logged in
    if (isAuthenticated) {
        if (loginPage) {
            //TODO: This is weird, fix this.
            if (window.location.href.split('?=')[1] === 'redirect') {
                history.push('/basecamp');
            } else {
                return <Redirect to='/basecamp' />
            }
        } else {
            return <Redirect to='/rootselect' />
        }

    }



    //Password Strength Stuff
    const hasNumber = value => {
        return new RegExp(/[0-9]/).test(value);
    }
    const hasMixed = value => {
        return new RegExp(/[a-z]/).test(value) &&
            new RegExp(/[A-Z]/).test(value);
    }
    const hasSpecial = value => {
        return new RegExp(/[!#@$%^&*)(+=._-]/).test(value);
    }
    const strengthColor = count => {
        if (count < 2) {
            return 'red';
        } else if (count < 3) {
            return 'orange';
        } else if (count < 4) {
            return 'yellowgreen';
        } else if (count < 5) {
            return 'green';
        } else {
            return 'green'
        }
    }

    const strengthIndicator = value => {
        let strengths = 0;
        if (value.length > 7) strengths++;
        if (hasNumber(value)) strengths++;
        if (hasSpecial(value)) strengths++;
        if (hasMixed(value)) strengths++;

        return strengths;
    }

    const showPasswordStrength = (e) => {
        const newStyle = {
            color: strengthColor(strengthIndicator(e.target.value)),
            outline: 'none'
        }

        if (strengthIndicator(e.target.value) === 0) {
            setPasswordStrengthDisplay('')
        } else if (strengthIndicator(e.target.value) === 1) {
            setPasswordStrengthDisplay('(Weak)')
        } else if (strengthIndicator(e.target.value) === 2) {
            setPasswordStrengthDisplay('(Medium)')
        } else if (strengthIndicator(e.target.value) === 3) {
            setPasswordStrengthDisplay('(Strong)')
        } else {
            setPasswordStrengthDisplay('(Very Strong)')
        }

        setPasswordStrengthMeter(newStyle)



        return '2px solid ' + strengthColor(strengthIndicator(e.target.value))

    }

    const checkPassMatch = () => {
        const newStyle = {
            color: 'gray',
            outline: 'none'
        }
        if (formData.password === formData.password2) {
            if (formData.password.length > 1) {
                newStyle.color = 'green'
            }
        } else {
            newStyle.color = 'red'
        }

        setPasswordsMatch(newStyle)
    }





    return (
        <div className='signin-container'>
            <div className='base-camp-static-bg' />
            <div className='white-bg' />

            <div className='signin-content content-container'>

                <div className='welcome-title'>Welcome to SolutionTrees</div>

                <img className='signin-logo' src={Logo} />
                {loginPage ? <></> : <div className='disclaimer'>Your information is safe with us! Privacy is one of our core values at SolutionTrees.</div>}

                <form className='signin-form' onSubmit={() => setLoginPage()}>
                    {loginPage ? <></> : <input value={formData.firstname} className='textbox' type="text" name="firstname" placeholder="First Name" onChange={e => onChange(e)} />}
                    {loginPage ? <></> : <input value={formData.lastname} className='textbox' type="text" name="lastname" placeholder="Last Name" onChange={e => onChange(e)} />}
                    <input value={formData.email} className='textbox' type="email" name="email" placeholder="Enter Email" onChange={e => onChange(e)} />
                    <input className='textbox' type="password" name="password" placeholder="Enter Password" onChange={e => {
                        onChange(e)
                        showPasswordStrength(e);
                        checkPassMatch();
                    }} />
                    {loginPage ? <></> : <input className='textbox' type="password" name="password2" placeholder="Confirm Password" onChange={e => {
                        onChange(e);
                        checkPassMatch();
                    }} />}
                    {loginPage ? <></> : <><div className='password-requirements' style={passwordStrengthMeter}>
                        &#8226; Password must contain one capital letter, one lowercase letter, a number, and a special character such as !, ?, #, or $
                        </div>
                        <div className='password-requirements' style={passwordsMatch}>
                            &#8226; Passwords must match.
                        </div>
                    </>}
                    <div className='login-error'>{signInError}</div>
                    
                    <div className='submit-btn clickable' onClick={() => onSubmit()}>{loginPage ? 'Login' : 'Sign Up'}</div>
                    {/* <div className='forgot-password'><a href='/resetrequest'>Forgot Password</a></div> */}
                </form>
                {loginPage ?
                    <div className='switch-to-signup'>Don't have an account yet? <div className='clickhere-link clickable' onClick={() => setLoginPage(!loginPage)}>Click here</div> to Sign Up</div>
                    :
                    <div className='switch-to-signup'>Already have an account? <div className='clickhere-link clickable' onClick={() => setLoginPage(!loginPage)}>Click here</div> to Log in</div>
                }
                
            </div>

        </div>
    )
}

SignIn.propTypes = {
    register: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, { register, login })(SignIn);