// Library Imports
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

// Redux Actions

// Components
import UseDebounceEffect from '../../utils/UseDebounceEffect'
import Navbar from '../../components/Navbar/Navbar'
import SearchResult from './SearchResult'

// Assets
import './search.css'

export default function Search() {

    const [selectedResults, setSelectedResults] = useState('allResults');
    const [searchQuery, setSearchQuery] = useState('');

    const [typedQuery, setTypedQuery] = useState(false); //Determines whether or not user has begun typing.
    const [responseReceived, setResponseReceived] = useState(false); //Determines whether or not we've searched yet.

    //Raw Results from API Call
    const [rawProfileResults, setRawProfileResults] = useState([])
    const [rawPlantResults, setRawPlantResults] = useState([])

    //Filtered Results by query.
    const [filteredProfileResults, setFilteredProfileResults] = useState([])
    const [filteredPlantResults, setFilteredPlantResults] = useState([])

    //Debounce waits until no changes to search bar for 0.5 seconds
    UseDebounceEffect(() => {
        if (searchQuery.length > 0) {
            //Get All Profiles and Plants, and put them into the current state.
            axios.get('/api/profile').then(res => { setRawProfileResults(res.data); setResponseReceived(true) }).catch(err => { console.log(err); setResponseReceived(true); })
            axios.get('/api/plant').then(res => { setRawPlantResults(res.data); setResponseReceived(true) }).catch(err => { console.log(err); setResponseReceived(true); })
        }
    }, [searchQuery], 500)

    //On load get all the data
    useEffect(() => {
        axios.get('/api/profile').then(res => { setRawProfileResults(res.data); setResponseReceived(true) }).catch(err => { console.log(err); setResponseReceived(true); })
        axios.get('/api/plant').then(res => { setRawPlantResults(res.data); setResponseReceived(true) }).catch(err => { console.log(err); setResponseReceived(true); })
    }, [])

    //Methods for filtering results for display.
    //Profile Filter
    useEffect(() => {
        let tempArray = []
        rawProfileResults.forEach(item => {
            if (item.user.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1) tempArray.push(item);
        });
        setFilteredProfileResults(tempArray);
        setTypedQuery(false);

    }, [rawProfileResults])

    //Plant Filter
    useEffect(() => {
        let tempArray = []
        rawPlantResults.forEach(item => {
            if (!item.isSeed) {
                if (item.title.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1) { tempArray.push(item); } //Search Titles
                item.tags.forEach(tag => { if (tag.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1) { tempArray.push(item); } }) //Search tags
            }

        });
        let uniqueArray = [...new Set(tempArray)]; //Remove duplicates
        setFilteredPlantResults(uniqueArray); //Save
        setTypedQuery(false);
    }, [rawPlantResults])

    //Method for displaying Loading thing
    useEffect(() => {
        if (searchQuery.length > 0) {

            setTypedQuery(true);
        } else {
            setTypedQuery(false);
        }
    }, [searchQuery])

    //Render Methods
    const renderResults = () => {

        if (filteredProfileResults.length === 0 && filteredPlantResults.length === 0) {
            if (typedQuery) {
                return (<div className='pre-results-container'>Loading Results...</div>)
            } else {
                if (responseReceived) {
                    return (<div className='pre-results-container'>No Results</div>)
                } else {
                    return (<div className='pre-results-container'>Begin Typing to Search </div>)
                }

            }
        }

        switch (selectedResults) {
            case 'allResults':
                return (
                    <div className='results-container'>
                        {filteredProfileResults.map(item =>
                            <SearchResult type='Profile' name={item.user.name} targetId={item.user._id} />
                        )}
                        {filteredPlantResults.map(item =>
                            <SearchResult type='Plant' name={item.title} targetId={item._id} />
                        )}
                    </div>
                )
            case 'users':
                return (
                    <div className='results-container'>
                        {filteredProfileResults.map(item =>
                            <SearchResult type='Profile' name={item.user.name} targetId={item.user._id} />
                        )}
                    </div>
                )
            case 'plants':
                return (
                    <div className='results-container'>
                        {filteredPlantResults.map(item =>
                            <SearchResult type='Plant' name={item.title} targetId={item._id} />
                        )}
                    </div>
                )
            default:
                return (<>Error Loading Data</>)
        }
    }


    return (
        <div className='content-container search-container '>
            <div className='base-camp-static-bg' />
            <Navbar />

            <div className='search-view'>
                <div className='title'>Search</div>
                <div className='sub-content-container search-header'>
                    <div className='search-bar-container'>
                        <FontAwesomeIcon icon={faSearch} />
                        <input className='search-input' type='text' placeholder='Type here to search' onChange={(e) => setSearchQuery(e.target.value)} />
                    </div>
                </div>
                <div className='sub-content-container search-results'>
                    <div className='result-selector-container'>
                        <div className={selectedResults === 'allResults' ? 'result-selection-option selected' : 'result-selection-option'} onClick={() => setSelectedResults('allResults')}>All Results</div>
                        <div className={selectedResults === 'users' ? 'result-selection-option selected' : 'result-selection-option'} onClick={() => setSelectedResults('users')}>Users</div>
                        <div className={selectedResults === 'plants' ? 'result-selection-option selected' : 'result-selection-option'} onClick={() => setSelectedResults('plants')}>Plants</div>

                    </div>
                    {/* Results container is returned as part of the render results call. */}
                    {renderResults()}
                </div>
            </div>

        </div>
    )
}
