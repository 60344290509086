import React from 'react'
import { useHistory } from 'react-router-dom'

import './search-result.css'

export default function SearchResult({ type, name, targetId }) {
    const history = useHistory()
    return (
        <div className='sub-content-container search-result-container clickable'
            onClick={() => history.push(`/${type}/?id=${targetId}`)}>
            {type} : {name}
        </div>
    )
}
