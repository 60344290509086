import axios from 'axios'

import { GET_PLANT, PLANT_ERROR, CREATE_PLANT, GET_PROFILE, GET_USER_PLANTS } from './types'

//Get current plant data
export const getCurrentPlant = (plantId) => async dispatch => {
    try {
        const res = await axios.get(`/api/plant/${plantId}`);
        dispatch({
            type: GET_PLANT,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: PLANT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

//Create or update plant
export const createPlant = (formData, history, edit = false) => async dispatch => {

    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const res = await axios.post('/api/plant', formData, config);
        dispatch({
            type: GET_PLANT,
            payload: res.data
        })

        //Get plant ID which was just added
        const plantId = res.data._id

        //Get existing plants array
        const resProfile = await axios.get('/api/profile/me', config);
        const currentPlants = resProfile.data.plants
        var currentPlantsArray = []
        currentPlants.forEach(plant => currentPlantsArray.push(plant._id))
        const newPlantArray = [...currentPlantsArray, plantId]

        //Create object and send for update
        const updateObj = { plants: newPlantArray };
        const resProfileUpdated = await axios.post('/api/profile', updateObj, config);
        dispatch({
            type: GET_PROFILE,
            payload: resProfileUpdated.data
        })

        if (!edit) {
            history.push('/plant')
        }

    } catch (err) {
        dispatch({
            type: PLANT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

//Get current user's plants
export const getUserPlants = () => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try {
        const resProfile = await axios.get('api/profile/me', {}, config);
        const currentUserId = resProfile.data.user._id;
        const res = await axios.get(`api/plant/user/${currentUserId}`, {}, config);
        dispatch({
            type: GET_USER_PLANTS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: PLANT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}



export const passToNextPotentialGuide = (plantId) => async dispatch => {
    console.log('inside pass plant')

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    //Get plant, then breeze from plant
    axios.get(`/api/plant/${plantId}`, {}, config)
        .then(res => {
            let currentOwner = res.data.owner
            axios.get(`/api/posts/${res.data.breeze}`, {}, config)
                .then(res => {
                    //Grabs index of the current owner of the plant, checks if there is another user, and assigns!
                    if (res.data[0].supportingUsers.indexOf(currentOwner) < res.data[0].supportingUsers.length - 2) {
                        let newUserId = res.data[0].supportingUsers[res.data[0].supportingUsers.indexOf(currentOwner) + 1]
                        let updateObj = {
                            plantId: plantId,
                            owner: newUserId,
                            isSeed: true
                        }
                        console.log(updateObj)
                        axios.post(`/api/plant/`, updateObj, config)
                            .then(plantRes => {console.log(plantRes)})

                        //Send notification to next user they've been given a plant
                        let notificationObj = {
                            sourceUser: currentOwner,
                            sourceUserName: currentOwner,
                            sourceUserAvatar: currentOwner,
                            targetUser: newUserId,
                            notificationType: 'seedPass',
                            targetPost: plantId,
                            targetName: plantId,
                        }

                        axios.post('/api/notifications/', notificationObj).then(res => console.log(res)).catch(err => console.log(err))

                    } else {
                        //Destroy plant if there is not a user who is willing to be the guide
                        //TODO: Test this!
                        //TODO: Get "show plant false" to actually not show the plant. i.e. get the plant delete function done.
                        //Notify original user that the plant has failed 
                        let updateObj = {
                            plantId: plantId,
                            showPlant: false
                        }
                    }
                })
        })
        .catch(err => console.log(err));

}


//Get all plants from a particular user
//This is UNUSED and does not work.
//Get owned plants works but get plants by array does not.
export const getAllUserPlants = (userId) => async dispatch => {

    //Get all Plant Id's from the user's profile
    //GET:
    //Plants from user profile (is cultivator)
    //Plants from user's own list (is owner)

    //Return ALL plants. Sorting can be done in the client side, that's fine.

    console.log('inside the API can you hear me???')

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    axios.get(`/api/profile/user/${userId}`).then(resProfile => {



        const profilePlantList = resProfile.data.plants;
        let profilePlants = []

        console.log('got profile response:')
        console.log(resProfile)

        let plantObj = {
            plants: []
        }

        for (var i = 0; i < profilePlantList.length; i++) {
            plantObj.plants.push(profilePlantList[i].toString())
        }

        console.log('made plantObj:')
        console.log(plantObj)

        axios.get(`/api/plant/getPlantsByArray`, plantObj, config).then(tempRes => {
            console.log("got plant array response:")
            console.log(tempRes.data)
            profilePlants.push(tempRes.data)
        }).catch(err => {
            console.log('err in getting array of plants')
            console.log(err)
        });


        // for (var i = 0; i < profilePlantList.length; i++) {
        //     let currentPlantId = profilePlantList[i]
        //     axios.get(`/api/plant/${currentPlantId}`).then(tempRes => {
        //         console.log('found a plant')
        //         console.log(tempRes.data)
        //         profilePlants.push(tempRes.data)
        //     });
        // }

        let allPlants = []
        let ownedPlantList = []

        axios.get(`/api/plant/user/${userId}`).then(resOwner => {
            console.log('got owned plants:')
            console.log(resOwner.data)
            ownedPlantList = resOwner.data;

        })


        allPlants = [...profilePlants, ...ownedPlantList]

        console.log('allPlants:');
        console.log(allPlants);




    }).catch(err => console.log(err))


}





//Get current User's Field plants
//NOT CURRENTLY USED
export const getFieldPlants = () => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try {
        const resProfile = await axios.get('/api/plant/populateField', {}, config);
        const currentUserId = resProfile.data.user._id;
        const res = await axios.get(`api/plant/user/${currentUserId}`, {}, config);
        dispatch({
            type: GET_USER_PLANTS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: PLANT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

