// ! Proper + Refactored code up top!!

// All types should be as follows:
// xxx_REQUESTED -- Triggers "Loading" and makes call.
// xxx_SUCCESS -- On Success.
// xxx_FAILED -- On Fail.

// POSTS
export const GET_POSTS_IN_FOREST_REQUESTED = 'GET_POSTS_IN_FOREST_REQUESTED';
export const GET_POSTS_IN_FOREST_SUCCESS = 'GET_POSTS_IN_FOREST_SUCCESS';
export const GET_POSTS_IN_FOREST_FAILED = 'GET_POSTS_IN_FOREST_FAIL';

export const GET_POSTS_IN_PLANT_REQUESTED = 'GET_POSTS_IN_PLANT_REQUESTED';
export const GET_POSTS_IN_PLANT_SUCCESS = 'GET_POSTS_IN_PLANT_SUCCESS';
export const GET_POSTS_IN_PLANT_FAILED = 'GET_POSTS_IN_PLANT_FAIL';

export const LOAD_MORE_POSTS_IN_FOREST_REQUESTED = 'LOAD_MORE_POSTS_IN_FOREST_REQUESTED';
export const LOAD_MORE_POSTS_IN_FOREST_SUCCESS = 'LOAD_MORE_POSTS_IN_FOREST_SUCCESS';
export const LOAD_MORE_POSTS_IN_FOREST_FAILED = 'LOAD_MORE_POSTS_IN_FOREST_FAILED';

export const LOAD_MORE_POSTS_IN_PLANT_REQUESTED = 'LOAD_MORE_POSTS_IN_PLANT_REQUESTED';
export const LOAD_MORE_POSTS_IN_PLANT_SUCCESS = 'LOAD_MORE_POSTS_IN_PLANT_SUCCESS';
export const LOAD_MORE_POSTS_IN_PLANT_FAILED = 'LOAD_MORE_POSTS_IN_PLANT_FAILED';


// BREEZES
export const GET_LATEST_BREEZES_REQUESTED = 'GET_LATEST_BREEZES_REQUESTED';
export const GET_LATEST_BREEZES_SUCCESS = 'GET_LATEST_BREEZES_SUCCESS';
export const GET_LATEST_BREEZES_FAILED = 'GET_LATEST_BREEZES_FAILED';

export const GET_USER_BREEZES_REQUESTED = 'GET_USER_BREEZES_REQUESTED';
export const GET_USER_BREEZES_SUCCESS = 'GET_USER_BREEZES_SUCCESS';
export const GET_USER_BREEZES_FAILED = 'GET_USER_BREEZES_FAILED';

export const LOAD_MORE_USER_BREEZES_REQUESTED = 'LOAD_MORE_USER_BREEZES_REQUESTED';
export const LOAD_MORE_USER_BREEZES_SUCCESS = 'LOAD_MORE_USER_BREEZES_SUCCESS';
export const LOAD_MORE_USER_BREEZES_FAILED = 'LOAD_MORE_USER_BREEZES_FAILED';




// ! Code to be replaced, below. Still Active! Just remove as replaced.
// (This will need refactoring!)

export const SET_MUSHROOM = 'SET_MUSHROOM';
export const REMOVE_MUSHROOM = 'REMOVE_MUSHROOM';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';

export const CREATE_PROFILE = 'CREATE_PROFILE';
export const GET_PROFILE = 'GET_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';

export const CREATE_FOREST = 'CREATE_FOREST';
export const GET_FOREST = 'GET_FOREST';
export const FOREST_ERROR = 'FOREST_ERROR';
export const GET_USER_FORESTS = 'GET_USER_FORESTS';

export const CREATE_PLANT = 'CREATE_PLANT';
export const GET_PLANT = 'GET_PLANT';
export const PLANT_ERROR = 'PLANT_ERROR';
export const GET_USER_PLANTS = 'GET_USER_PLANTS';


export const CREATE_POST = 'CREATE_POST';
export const GET_POSTS = 'GET_POSTS';
export const POST_ERROR = 'POST_ERROR';

export const ADD_BLOSSOM = 'ADD_BLOSSOM';
export const REMOVE_BLOSSOM = 'REMOVE_BLOSSOM';
export const ADD_THORN = 'ADD_THORN';
export const REMOVE_THORN = 'REMOVE_THORN';

export const SUPPORT_BREEZE = 'SUPPORT_BREEZE';
export const DOWNVOTE_BREEZE = 'DOWNVOTE_BREEZE';

export const UPLOAD_IMAGE = 'UPLOAD_IMAGE'

