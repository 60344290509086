import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Logo from '../../signin/images/Solutiontrees Logo_300px.png'

import '../css/mailchimp-signup.css'

export default function ContinueSignupPage() {

    const history = useHistory()

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
    })

    //On load check for params in url
    useEffect(() => {
        //If we are coming from the mailchimp page
        if (window.location.href.split('?')[1].split('=')[0] === 'mailchimp') {
            const paramArray = window.location.href.split('?')[1].split('=')

            let tempEmail = paramArray[1].split('&')[0]
            let tempFirstName = paramArray[2].split('&')[0]
            let tempLastName = paramArray[3]

            let tempFormData = { ...formData }
            tempFormData.email = tempEmail
            tempFormData.firstname = tempFirstName
            tempFormData.lastname = tempLastName

            setFormData(tempFormData)
        } else {
            //Not bounced here from mailchimp form
        }
    }, [])




    const handleContinueSignup = () => {
        history.push(`/signin?mailchimp=${formData.email}&firstname=${formData.firstname}&lastname=${formData.lastname}`)
    }


    return (


        <div className='signin-container'>
            <div className='base-camp-static-bg' />
            <div className='white-bg' />


            {formData.email === 'redirect' ?
                <div className='signin-content content-container'>
                    <div className='welcome-title'>Thank you for Subscribing</div>
                    Please close this tab
                </div>
                :
                <div className='signin-content content-container'>


                    <div className='welcome-title'>Subscription Confirmed</div>

                    <img alt='logo' className='signin-logo' src={Logo} />
                Thank You for joining!
                <div
                        className='continue-btn clickable'
                        onClick={() => handleContinueSignup()}
                    >
                        Continue to Site
                </div>


                </div>
            }



        </div>
    )
}
