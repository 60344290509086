// Library Imports
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from "react-router-dom";
import axios from 'axios'
import { connect } from 'react-redux'

// Redux Actions
import { passToNextPotentialGuide, getCurrentPlant } from '../../../redux/actions/plant'
import { notifyBreezeSupportersPlantCultivated } from '../../../redux/actions/notifications'

// Components
import PlantGalleryUpload from '../../plant/components/PlantGalleryUpload'
import AdminMenu from '../../plant/components/AdminMenu'

// Assets
import './create-plant.css'


const CreatePlant = ({ passToNextPotentialGuide, getCurrentPlant, auth }) => {

    //TODO: Create real plant creation logic etc.
    // Plants created from manual plant creation will all be created from a unique ID, as if it was a breeze. But if it's that ID it goes through a different process.
    // Must come from a user who isAdmin.

    //Set Redirect to field after Plant is cultivated
    //TODO: Set to go to plant.
    const history = useHistory();
    const navigateTo = () => history.push('/field');

    // This page will always have an ID passed to it, and that is the breeze for which the plant is being created.
    // Information will be pre-populated based on the breeze being put in.
    const emptyPlant = {
        isSeed: false,
        title: '',
        description: '',
        images: [],
        mainImage: '',
        tags: [],
        plantId: window.location.href.split('?id=')[1],
        // owner: '',
        // cultivators: []
    }

    const [currentPlant, setCurrentPlant] = useState(emptyPlant)
    const [plantId, setPlantId] = useState(window.location.href.split('?id=')[1])

    const getGalleryInfo = (galleryInfo) => {
        let tempGalleryInfo = [...galleryInfo]
        let tempPlant = { ...currentPlant }
        tempPlant.images = tempGalleryInfo;
        setCurrentPlant(tempPlant)
    }

    const getMainPhoto = (selectedImage) => {
        let tempMainImage = selectedImage;
        let tempPlant = { ...currentPlant };
        tempPlant.mainImage = tempMainImage;
        setCurrentPlant(tempPlant);
    }

    const getTags = (tags) => {
        let tempTags = tags;
        let tempPlant = { ...currentPlant };
        tempPlant.tags = tempTags;
        setCurrentPlant(tempPlant);
    }

    const handleChange = (e) => {
        let tempPlant = { ...currentPlant, [e.target.name]: e.target.value }
        setCurrentPlant(tempPlant)
    }

    const handlePassPlant = () => {
        var r = window.confirm("Are you sure you'd like to pass this plant? Press OK to pass. This cannot be undone.");
        if (r == true) {
            passToNextPotentialGuide(plantId)
            navigateTo(); //Go to plant page
        } else {
            //Do nothing.
        }

    }

    const handleCultivatePlant = () => {
        let plantObj = { ...currentPlant }
        axios.post('/api/plant', plantObj).then(res => {

            //Notify Supporters
            notifyBreezeSupportersPlantCultivated({
                sourceUser: auth.user._id, //Source User Id
                sourceUserName: auth.user._id, //Source User Name
                sourceUserAvatar: auth.user._id, //Source User Avatar
                targetUser: '', //Target User Id
                notificationType: 'plantCultivated', //Notification type
                targetPost: plantId,//Target Post Id
                targetName: ''//Target Post Title
            }, originalBreezeId)

            navigateTo(); //Go to field
        }).catch(err => console.log(err))
    }





    const [originalBreezeText, setOriginalBreezeText] = useState('No Data')
    const [originalBreezeId, setOriginalBreezeId] = useState('No Data')

    useEffect(() => {
        axios.get(`/api/plant/${plantId}`).then(res => {
            if (res.data.breezeText !== undefined) {
                setOriginalBreezeText('"' + res.data.breezeText + '"')
                setOriginalBreezeId(res.data.breeze)
            }
        })
    }, [plantId])


    useEffect(() => {
        //console.log(originalBreezeId)
    }, [originalBreezeId])

    return (
        <div className='create-plant-container'>
            <div className='base-camp-static-bg' />

            <div className='create-plant-content content-container'>

                <div className='welcome-container sub-content-container'>
                    <div className='welcome-title'>Cultivate Plant</div>
                    <div className='original-breeze'>{originalBreezeText}</div>
                    <div className='welcome-description'>Congratulations! Fill out the remaining information, and your seed will grow into a plant.</div>
                    <div className='cultivate-pass-option' onClick={() => handlePassPlant()}>Not interested in cultivating this idea?<br />Click here to pass this seed to someone else!</div>
                </div>


                <div className='sub-content-container'>
                    <div className='input-title-text'>Plant Name</div>
                    <div className='input-description-text'>This is the name which will appear on your Plant Cards across SolutionTrees</div>
                    <input type='text' className='create-plant-title-input' name='title' onChange={(e) => handleChange(e)} />
                </div>
                <div className='sub-content-container'>
                    <div className='input-title-text'>Plant Description</div>
                    <div className='input-description-text'>This is the Summary which will appear on your plant page. Make sure it gives a good idea of what the plant is all about!</div>
                    <textarea className='create-plant-description-input' name='description' onChange={(e) => handleChange(e)} />
                </div>

                <div className='sub-content-container plant-image-upload-area'>
                    <div className='input-title-text'>Upload Images</div>
                    <div className='input-description-text'>Use this space to upload up to 5 images which will help represent this plant, you can change these at any time.</div>
                    <div className='plant-gallery-upload-container'>
                        <PlantGalleryUpload galleryInfoFunc={getGalleryInfo} mainPhotoFunc={getMainPhoto} />
                    </div>
                </div>

                <div className='sub-content-container plant-category-tag'>
                    <div className='input-title-text'>Choose Tags</div>
                    <div className='input-description-text'>Choosing relevant categories for your plant ensures it appears in users' feeds who are likely to be interested in it.</div>
                    <AdminMenu tagFunc={getTags} />
                </div>

                <div className='continue-btn clickable' onClick={() => handleCultivatePlant()}>Cultivate Plant</div>

            </div>

        </div>
    )
}

CreatePlant.propTypes = {
    passToNextPotentialGuide: PropTypes.func.isRequired,
    getCurrentPlant: PropTypes.func.isRequired,

}

const mapStateToProps = state => ({
    forest: state.forest,
    posts: state.posts.posts,
    auth: state.auth,
    profile: state.profile,
})


export default connect(mapStateToProps, { passToNextPotentialGuide, getCurrentPlant })(CreatePlant);