import { GET_FOREST, FOREST_ERROR, CREATE_FOREST, GET_USER_FORESTS } from "../actions/types";

const initialState = {
    forest: null,
    forests: [],
    loading: true,
    error: {}
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case CREATE_FOREST:
        case GET_FOREST:
            return {
                ...state,
                forest: payload,
                loading: false
            }
        case GET_USER_FORESTS:
            return {
                ...state,
                forests: payload,
                loading: false
            }
        case FOREST_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state
    }
}