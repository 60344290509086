// Library imports
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'

// Components
import NotificationItem from './NotificationItem'

// Assets
import './notifications.css'

const NotificationList = ({ auth, parentCB }) => {

    const [allNotifications, setAllNotifications] = useState([])
    const [displayNotifications, setDisplayNotifications] = useState([])
    const [currentUser, setCurrentUser] = useState('')

    //Get all notifications on load
    useEffect(() => {
        if (auth.user !== null) {
            setCurrentUser(auth.user); //set Current user
            axios.get(`/api/notifications/${auth.user._id}`).then(res => {
                //let allNotificationsResponse = res.data
                setAllNotifications(res.data);
            }).catch(err => console.log(err))
        }
    }, [auth])

    //Set notifications for display
    useEffect(() => {
        let tempDisplayNotifications = []
        for (var i = 0; i < allNotifications.length && i < 20; i++) {
            tempDisplayNotifications.push(allNotifications[i])
        }
        setDisplayNotifications(tempDisplayNotifications)
    }, [allNotifications])

    //Display Proper Notifications.
    useEffect(() => {
        parentCB(displayNotifications) //Sends number of notifications back to navbar to display in notification bubble 
    }, [displayNotifications])

    const markAllNotificationsAsRead = () => {
        //Update DB
        axios.put(`api/notifications/markAllAsRead/${auth.user._id}`).then(res => console.log(res)).catch(err => console.log(err))

        //Update UI
        let tempDisplayNotifications = []
        for (var i = 0; i < displayNotifications.length; i++) {
            let tempNewValue = displayNotifications[i]
            tempNewValue.userHasSeen = true
            tempDisplayNotifications.push(tempNewValue)
        }
        setDisplayNotifications(tempDisplayNotifications)
    }

    return (
        <div className='notification-list-item-container'>
            <div className='notifications-list'>
                {displayNotifications.map(item => {
                    return <NotificationItem
                        key={item._id}
                        notificationId={item._id}
                        type={item.notificationType}
                        currentUser={currentUser}
                        userHasSeen={item.userHasSeen}
                        userResponse={item.userResponse}
                        sourceUser={item.sourceUser}
                        sourceUserName={item.sourceUserName}
                        sourceUserAvatar={item.sourceUserAvatar}
                        targetPost={item.targetPost}
                        targetName={item.targetName}
                    />
                })}
            </div>

            <div className='mark-as-read-container'>
                <div className='mark-as-read-btn' onClick={() => markAllNotificationsAsRead()}>Mark All As Read</div>
            </div>
        </div>
    )
}


const mapStateToProps = state => ({
    auth: state.auth
})

NotificationList.propTypes = {

}

export default connect(mapStateToProps, {})(NotificationList)