import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import '../css/mailchimp-signup.css'

export default function MailchimpSignup() {

    const history = useHistory()



    const handleRegister = () => {

        setTimeout(function () {
            history.push(`/continuesignin?mailchimp=${emailValue}&firstname=${firstNameValue}&lastname=${lastNameValue}`)
        }, 1000);//wait 1 second

    }


    const [emailValue, setEmailValue] = useState('')
    const [firstNameValue, setFirstNameValue] = useState('')
    const [lastNameValue, setLastNameValue] = useState('')
    const [bonusValue, setBonusValue] = useState('')

    const handleChange = (e, target) => {

        if (target === 'email') {
            setEmailValue(e.target.value)
        }
        else if (target === 'firstname') {
            setFirstNameValue(e.target.value)
        }
        else if (target === 'lastname') {
            setLastNameValue(e.target.value)
        }
        else {

        }

    }

    return (
        <div className='mailchimp-container'>

            <form
                action="https://solutiontrees.us7.list-manage.com/subscribe/post?u=027620ff8cc6e11c1b957dbbb&amp;id=5acc4c70e6"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate" target="_blank"
                novalidate>

                <div className='join-title'>Join the Movement</div>
                <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
                <div className="mc-field-group">
                    <label for="mce-EMAIL">Email Address  <span className="asterisk">*</span></label>
                    <input type="email" value={emailValue} name="EMAIL" className="text-input" id="mce-EMAIL" onChange={(e) => handleChange(e, 'email')} />
                </div>
                <div className="mc-field-group">
                    <label for="mce-FNAME">First Name </label>
                    <input type="text" value={firstNameValue} name="FNAME" className="text-input" id="mce-FNAME" onChange={(e) => handleChange(e, 'firstname')} />
                </div>
                <div className="mc-field-group">
                    <label for="mce-LNAME">Last Name </label>
                    <input type="text" value={lastNameValue} name="LNAME" className="text-input" id="mce-LNAME" onChange={(e) => handleChange(e, 'lastname')} />
                </div>
                <div id="mce-responses" className="clear">
                    <div className="response" id="mce-error-response" style={{ display: "none" }}></div>
                    <div className="response" id="mce-success-response" style={{ display: "none" }}></div>
                </div>
                <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true"><input type="text" name="b_027620ff8cc6e11c1b957dbbb_5acc4c70e6" tabindex="-1" value={bonusValue} /></div>
                <div className="clear">
                    <input
                        type="submit"
                        value="Subscribe"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        className="subscribe-button clickable"
                        onClick={() => handleRegister()}
                    />
                </div>
            </form>
            <div className='partner-link-box'>
                <a target="_blank"
                    rel="noopener noreferrer"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSeqQFAFdvB3WsrrlssLFRDxo30nXuKXHYfvTKVmWeJpSXNWVg/viewform?usp=sf_link"
                    className='clickable'
                >
                    <p className="company-sign-up-link">Is your company interested in partnering with SolutionTrees? Click Here to schedule an interview with us.</p>
                </a>
            </div>
            <a href='/signin'><div className='continue-btn clickable'>Already have an account? Click Here to Sign In</div></a>
        </div>
    )
}
