import React from 'react'

import './plantcard.css'

import FlowerBulb from '../../../pages/signup/images/FlowerBulb.png'
import SeedImage from '../../../assets/images/default-seed-img.png'

export default function PlantCard({ seedCard, plantName = "Title", plantImg = { FlowerBulb }, plantId }) {

    var shortPlantName = ''

    if (plantName.length > 25) {
        shortPlantName = plantName.substring(0, 25) + '...'
    }

    if (seedCard) {
        plantImg = SeedImage
    }

    return (
        <a href={seedCard ? `/createplant/?id=${plantId}` : `/plant/?id=${plantId}`}>
            <div className={seedCard ? 'seed-card card plant-card clickable' : 'card plant-card clickable'}>
                <div className='plant-card-img-container'>
                    <img className='plant-card-img' alt='plant image' src={plantImg} />
                </div>
                <div className='card-text-container'>
                    <div className='plant-card-title'>{(shortPlantName.length > 25) ? shortPlantName : plantName}</div>
                </div>
            </div>
        </a>

    )
}
