// Library Imports
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from "react-router-dom";
import axios from 'axios'
import { connect } from 'react-redux'

// Redux Actions
import { passToNextPotentialGuide, getCurrentPlant } from '../../../redux/actions/plant'
import { createAd } from '../../../redux/actions/ads'

// Components
import PlantGalleryUpload from '../../plant/components/PlantGalleryUpload'
import AdminMenu from '../../plant/components/AdminMenu'

// Assets
import './create-ad.css'
import '../plant/create-plant.css'


const CreateAd = ({ createAd }) => {

    //Set Redirect to basecamp after ad is created.
    const history = useHistory();
    const navigateTo = () => history.push('/basecamp');

    const emptyPlant = {
        title: '',
        description: '',
        targetUrl: '',
        images: [],
        mainImage: '',
        tags: [],
        adId: ''
    }

    const [currentPlant, setCurrentPlant] = useState(emptyPlant)
    const [plantId, setPlantId] = useState(window.location.href.split('?id=')[1])

    const getGalleryInfo = (galleryInfo) => {
        let tempGalleryInfo = [...galleryInfo]
        let tempPlant = { ...currentPlant }
        tempPlant.images = tempGalleryInfo;
        setCurrentPlant(tempPlant)
    }

    const getMainPhoto = (selectedImage) => {
        let tempMainImage = selectedImage;
        let tempPlant = { ...currentPlant };
        tempPlant.mainImage = tempMainImage;
        setCurrentPlant(tempPlant);
    }

    const getTags = (tags) => {
        let tempTags = tags;
        let tempPlant = { ...currentPlant };
        tempPlant.tags = tempTags;
        setCurrentPlant(tempPlant);
    }

    const handleChange = (e) => {
        let tempPlant = { ...currentPlant, [e.target.name]: e.target.value }
        setCurrentPlant(tempPlant)
        console.log(plantId);
    }


    const handleCreateAd = () => {
        let plantObj = { ...currentPlant }
        createAd(plantObj)
        navigateTo();
    }

    // useEffect(() => {
    //     console.log(currentPlant);
    // }, [currentPlant])

    return (
        <div className='create-plant-container'>
            <div className='base-camp-static-bg' />
            {/* <div className='white-bg' /> */}

            <div className='create-plant-content content-container'>

                <div className='sub-content-container'>
                    <div className='input-title-text'>Ad Title Text</div>
                    <div className='input-description-text'>This is the name which will appear in bold on the Ad Cards across SolutionTrees</div>
                    <input type='text' className='create-plant-title-input' name='title' onChange={(e) => handleChange(e)} />
                </div>
                <div className='sub-content-container'>
                    <div className='input-title-text'>Ad Description</div>
                    <div className='input-description-text'>This is the Summary which will appear in the subtext of the ad.</div>
                    <textarea className='create-plant-description-input' name='description' onChange={(e) => handleChange(e)} />
                </div>
                <div className='sub-content-container'>
                    <div className='input-title-text'>Target URL</div>
                    <div className='input-description-text'>This is the url a user will be taken to when they click on the ad.</div>
                    <input type='text' className='create-plant-title-input' name='targetUrl' onChange={(e) => handleChange(e)} />
                </div>
                <div className='sub-content-container plant-image-upload-area'>
                    <div className='input-title-text'>Upload Images</div>
                    <div className='input-description-text'>Use this space to upload the image desired for the ad.</div>
                    <div className='plant-gallery-upload-container'>
                        <PlantGalleryUpload galleryInfoFunc={getGalleryInfo} mainPhotoFunc={getMainPhoto} />
                    </div>
                </div>

                <div className='sub-content-container plant-category-tag'>
                    <div className='input-title-text'>Choose Tags</div>
                    <div className='input-description-text'>Choosing relevant categories ensures it will appear in users' feeds who are likely to be interested in it.</div>
                    <AdminMenu tagFunc={getTags} />
                </div>

                <div className='continue-btn clickable' onClick={() => handleCreateAd()}>Create Ad</div>

            </div>

        </div>
    )
}

CreateAd.propTypes = {
    createAd: PropTypes.func.isRequired,
    getCurrentPlant: PropTypes.func.isRequired,

}

const mapStateToProps = state => ({
    forest: state.forest,
    posts: state.posts.posts,
    auth: state.auth,
    profile: state.profile,
})


export default connect(mapStateToProps, { createAd, getCurrentPlant })(CreateAd);