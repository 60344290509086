import React, {useState, useEffect} from 'react'
import axios from 'axios';
//const sgMail = require('@sendgrid/mail')

export default function Demo() {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [subject, setSubject] = useState('')

    const word = "Hello!";

    useEffect(() => {
        console.log('test')
        console.log(name)
        console.log(email)
        console.log(subject)
        console.log(message)

    }, [name,email,message,subject])


    // TODO: Transform this into a proper API call
    const sendRequest = () => {

        console.log('inside post data')
        console.log(name)
        console.log(email)
        console.log(subject)
        console.log(message)

        let body = JSON.stringify({
            name,
            email,
            subject,
            message
        })

        let config = {
            headers:{
                "Content-Type":"application/json"
            },
        }

        axios.post('/api/email/resetPasswordRequest', body, config)
            .then(res=>{
                console.log(res);
                res.json();
            })
            .then(data=>{
                alert(data.message)
                setMessage('')
                setName('')
                setSubject('')
                setEmail('')
            })
            .catch(err=>{ console.log(err) })
        }





    return (
        <div>
            {word}

            <div style={{maxWidth: '60%', display:'flex', flexDirection: 'column', alignItems:'center'}} className='mycard'>
                <div className='card auth-card input-field'>
                    
                    <h2>Send me a Message</h2>

                    <input type='text' placeholder='email' value={email} onChange={(e)=>setEmail(e.target.value)} />

                    <input type='text' placeholder='name' value={name} onChange={(e)=>setName(e.target.value)} />

                    <input type='text' placeholder='subject' value={subject} onChange={(e)=>setSubject(e.target.value)} />

                    <textarea type='text' placeholder='Message' value={message} onChange={(e)=>setMessage(e.target.value)} />

                    <button onClick={()=>sendRequest()} >
                        Send Message
                    </button>

                </div>
            </div>
    
        </div>
    )
}

