// ! TARGET IS TO REMOVE THIS AND GLOBALLY REPLACE WITH "BASECAMP" VERSION

// Library Imports
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons'

// Redux Actions
import { getCurrentForest, getUserForests } from '../../redux/actions/forest'

// Assets
import './css/forest-selector.css' 

// ! TARGET IS TO REMOVE THIS AND GLOBALLY REPLACE WITH "BASECAMP" VERSION

const ForestSelectorMVP = ({ forest, getCurrentForest, getUserForests, parentCB, includeAllTreesEverywhere }) => {

    const [currentlySelected, setCurrentlySelected] = useState(0)
    const [forestArray, setForestArray] = useState([{
        title: 'Loading...',
        description: 'Loading...',
        posts: [],
        users: []
    }])

    //Get User Forests instantly
    useEffect(() => {
        getUserForests();
    }, []);

    //Populate object once forest data has come in.
    useEffect(() => {
        if (forest.forests.length > 0) {
            var tempForestArray = []
            for (var i = 0; i < forest.forests.length; i++) {
                tempForestArray.push(forest.forests[i])
            }

            if (includeAllTreesEverywhere) {
                //Get alltrees data
                axios.get('/api/posts/all').then(res => {
                    tempForestArray.unshift({
                        title: "All Trees Everywhere",
                        description: "show all posts to user",
                        posts: res.data
                    })
                    //Set the Callback function to point to the alltrees forest.
                    parentCB({
                        title: "All Trees Everywhere",
                        description: "show all posts to user",
                        posts: res.data
                    })
                }).catch(err => console.log(err))
            }

            setForestArray(tempForestArray)
        }
    }, [forest.loading]);

    useEffect(() => {
        parentCB(forestArray[currentlySelected]);
    }, [forestArray])

    const handleIncrement = () => {
        if (currentlySelected + 1 < forestArray.length) {
            setCurrentlySelected(currentlySelected + 1);
            parentCB(forestArray[currentlySelected + 1]);
        } else {
            setCurrentlySelected(0);
            parentCB(forestArray[0]);
        }
    }

    const handleDecrement = () => {
        if (currentlySelected - 1 < 0) {
            setCurrentlySelected(forestArray.length - 1);
            parentCB(forestArray[forestArray.length - 1]);
        } else {
            setCurrentlySelected(currentlySelected - 1);
            parentCB(forestArray[currentlySelected - 1]);
        }
    }


    return (
        <div className='forest-selector-tool sub-content-container'>
            <div className='btn-arrow clickable'><FontAwesomeIcon className='btn-arrow' icon={faCaretLeft} onClick={() => handleDecrement()} /></div>
            <div className='cell-container'>
                <div className='sliding-cell clickable' key='currentCell'>{forestArray[currentlySelected].title}</div>
            </div>
            <div className='btn-arrow clickable'><FontAwesomeIcon className='btn-arrow' icon={faCaretRight} onClick={() => handleIncrement()} /></div>
        </div>
    )
}

ForestSelectorMVP.propTypes = {
    currentForest: PropTypes.object,
    getCurrentForest: PropTypes.func.isRequired,
    getUserForests: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    forest: state.forest
})

export default connect(mapStateToProps, { getCurrentForest, getUserForests })(ForestSelectorMVP)
