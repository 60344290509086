/* eslint-disable */

import React, { useState, useEffect, useRef } from 'react';

// Path2D for a Heart SVG
const heartSVG = "M0 200 v-200 h200 a100,100 90 0,1 0,200 a100,100 90 0,1 -200,0 z"
const SVG_PATH = new Path2D(heartSVG);

// Scaling Constants for Canvas
const SCALE = 0.1;
export const canvasWidth = 300;
export const canvasHeight = 300;
export const circleRadius = 150;


export function draw(ctx, location) {
    //console.log("attempting to draw")

    ctx.fillRect(location.x, location.y, 3, 3);

    ctx.restore();
};

export function useCanvas() {
    const canvasRef = useRef(null);
    const [coordinates, setCoordinates] = useState([]);
    const [clickedCoordinates, setClickedCoordinates] = useState([]);
    const [interestValues, setInterestValues] = useState([0.3, 0.6, 0.5, 0.2, 0.3, 0.9, 0.6, 0.7, 0.6, 0.9, 1]);


    //UseEffect to handle click events and update array
    useEffect(() => {
        console.log(clickedCoordinates);
        if (clickedCoordinates.length > 0) {
            //Initialize Variables for use
            const canvasObj = canvasRef.current;

            //Handle offset based on screen size.
            var rect = canvasObj.getBoundingClientRect();
            const OFFSET_X = rect.left;
            const OFFSET_Y = rect.top;

            //Draw Spokes/ID Locations of each component.
            var numNodes = 11;
            var angle, i;
            var angles = [];

            //Calculate Spokes
            for (i = 0; i < numNodes; i++) {
                angle = ((i / (numNodes / 2)) * Math.PI);
                angles.push(angle);
            }

            clickedCoordinates[clickedCoordinates.length - 1].x = clickedCoordinates[clickedCoordinates.length - 1].x - OFFSET_X
            clickedCoordinates[clickedCoordinates.length - 1].y = clickedCoordinates[clickedCoordinates.length - 1].y - OFFSET_Y

            //Determine Current Magnitude of point
            var magnitude = Math.pow(((Math.pow(clickedCoordinates[clickedCoordinates.length - 1].x - 150, 2)) + (Math.pow(clickedCoordinates[clickedCoordinates.length - 1].y - 150, 2))), 0.5)

            //Normalize Magnitude
            magnitude > 150 ? magnitude = 150 : magnitude = magnitude;

            //Determine Current Angle of Point
            var dx = 150 - clickedCoordinates[clickedCoordinates.length - 1].x;
            var dy = 150 - clickedCoordinates[clickedCoordinates.length - 1].y;
            var theta = Math.atan2(dy, dx) + Math.PI; // range (-PI, PI]

            //Determine Closest Spoke of point
            var closest = angles.reduce(function (prev, curr) {
                return (Math.abs(curr - theta) < Math.abs(prev - theta) ? curr : prev);
            });

            var targetSpoke = angles.indexOf(closest);
            var tempInterests = interestValues;
            tempInterests[targetSpoke] = magnitude / 150;
            setInterestValues(tempInterests)
        }

    }, [clickedCoordinates])


    //UseEffect for handling motion across canvas
    useEffect(() => {

        //Initialize Variables for use
        const canvasObj = canvasRef.current;
        const ctx = canvasObj.getContext('2d');

        //Handle offset based on screen size.
        var rect = canvasObj.getBoundingClientRect();
        const OFFSET_X = rect.left;
        const OFFSET_Y = rect.top;

        // clear the canvas area before rendering the coordinates held in state
        ctx.clearRect(0, 0, canvasWidth, canvasHeight);

        ctx.strokeStyle = 'black'
        ctx.lineWidth = 1;


        //Draw Circles for base
        ctx.beginPath();
        ctx.arc(canvasWidth / 2, canvasHeight / 2, circleRadius, 0, 2 * Math.PI);
        ctx.stroke();

        ctx.beginPath();
        ctx.arc(canvasWidth / 2, canvasHeight / 2, circleRadius / 2, 0, 2 * Math.PI);
        ctx.stroke();

        //Draw Spokes/ID Locations of each component.
        var radius = circleRadius;
        var numNodes = 11;
        var nodes = [],
            width = canvasWidth,
            height = canvasHeight,
            angle,
            x,
            y,
            i;

        var angles = [];

        //Calculate Spokes
        for (i = 0; i < numNodes; i++) {
            angle = ((i / (numNodes / 2)) * Math.PI); // Calculate the angle at which the element will be placed.
            // For a semicircle, we would use (i / numNodes) * Math.PI.
            angles.push(angle);
            x = (radius * Math.cos(angle)) + (width / 2); // Calculate the x position of the element.
            y = (radius * Math.sin(angle)) + (width / 2); // Calculate the y position of the element.
            nodes.push({ 'id': i, 'x': x, 'y': y });
        }

        //Draw Spokes
        for (i = 0; i < nodes.length; i++) {
            ctx.lineWidth = 1;
            ctx.beginPath()
            ctx.moveTo(canvasWidth / 2, canvasHeight / 2)
            ctx.lineTo(nodes[i].x, nodes[i].y)
            ctx.stroke();
            //ctx.fillRect(nodes[i].x, nodes[i].y, 3, 3)
        }


        //Determine Interest Map Coordinates
        var interestCoords = []
        for (i = 0; i < nodes.length; i++) {

            //Calculate X and Y for interest amount
            var interestMag = interestValues[i] * 150;
            var interest_x = (interestMag * Math.cos(angles[i])) + (width / 2); // Calculate the x position of the element.
            var interest_y = (interestMag * Math.sin(angles[i])) + (width / 2); // Calculate the y position of the element.

            interestCoords.push({ 'id': i, 'x': interest_x, 'y': interest_y, 'magnitude': interestMag });
        }

        //Draw current Interest Map
        ctx.beginPath();
        for (i = 0; i < interestCoords.length; i++) {
            ctx.moveTo(interestCoords[i].x, interestCoords[i].y)

            //Ensure Proper reference points
            if (i === 10) {
                var cwClose = 0;
            } else {
                var cwClose = i + 1;
            }

            //Send line to mid point
            var averageMag = (interestCoords[i].magnitude + interestCoords[cwClose].magnitude) / 2 //Calc average magnitude of each point
            averageMag = averageMag * 0.625 //Modify Magnititude
            var averageAngle = (angles[i] + angles[cwClose]) / 2 //Calculate Midpoint Angle

            //Fix for where angles cross over zero point
            if (averageAngle > angles[cwClose]) {
                averageAngle = averageAngle + Math.PI
            }

            //Calc x and y
            var draw_x_mid = (averageMag * Math.cos(averageAngle)) + 150
            var draw_y_mid = (averageMag * Math.sin(averageAngle)) + 150
            ctx.lineTo(draw_x_mid, draw_y_mid)

            //Draw CW Side
            var draw_x = (interestCoords[cwClose].x)
            var draw_y = (interestCoords[cwClose].y)
            ctx.lineTo(draw_x, draw_y)

            //Reset to Origin
            ctx.lineTo(150, 150)
        }


        ctx.fillStyle = 'rgba(90, 63, 58, 0.95)';
        ctx.fill();


        //Draw based on coordinates with window offset
        if (coordinates.length > 0) {
            coordinates[coordinates.length - 1].x = coordinates[coordinates.length - 1].x - OFFSET_X
            coordinates[coordinates.length - 1].y = coordinates[coordinates.length - 1].y - OFFSET_Y

            //Determine Current Magnitude of point
            var magnitude = Math.pow(((Math.pow(coordinates[coordinates.length - 1].x - 150, 2)) + (Math.pow(coordinates[coordinates.length - 1].y - 150, 2))), 0.5)

            //Determine Current Angle of Point
            var dx = 150 - coordinates[coordinates.length - 1].x;
            var dy = 150 - coordinates[coordinates.length - 1].y;
            var theta = Math.atan2(dy, dx) + Math.PI; // range (-PI, PI]

            //Determine Closest Spoke of point
            var closest = angles.reduce(function (prev, curr) {
                return (Math.abs(curr - theta) < Math.abs(prev - theta) ? curr : prev);
            });

            //Draw Line on Spoke
            magnitude > 150 ? magnitude = 150 : magnitude = magnitude;
            var draw_x_src = (magnitude * Math.cos(closest)) + 150
            var draw_y_src = (magnitude * Math.sin(closest)) + 150
            ctx.strokeStyle = 'red'
            ctx.beginPath()
            ctx.moveTo(canvasWidth / 2, canvasHeight / 2)
            ctx.lineTo(draw_x_src, draw_y_src)

            //Find neighboring spokes
            var spokeNum = angles.indexOf(closest, 0)
            if (spokeNum === 10) {
                var cwClose = 0;
                var ccwClose = 9;
            } else if (spokeNum === 0) {
                var cwClose = 1;
                var ccwClose = 10;

            } else {
                var cwClose = spokeNum + 1;
                var ccwClose = spokeNum - 1;
            }

            //Draw Line on neighboring spokes
            // var draw_x = (magnitude * Math.cos(angles[cwClose])) + 150
            // var draw_y = (magnitude * Math.sin(angles[cwClose])) + 150
            var draw_x = (interestCoords[cwClose].x)
            var draw_y = (interestCoords[cwClose].y)
            ctx.strokeStyle = 'yellow'
            ctx.beginPath()
            ctx.moveTo(canvasWidth / 2, canvasHeight / 2)
            ctx.lineTo(draw_x, draw_y)

            //Send line to mid point
            ctx.strokeStyle = 'green'

            var averageMag = (magnitude + interestCoords[cwClose].magnitude) / 2 //Calc average magnitude of each point
            averageMag = averageMag * 0.625 //Modify Magnititude

            var averageAngle = (closest + angles[cwClose]) / 2 //Calculate Midpoint Angle

            //Fix for where angles cross over zero point
            if (averageAngle > angles[cwClose]) {
                averageAngle = averageAngle + Math.PI
            }

            //Calc x and y
            var draw_x_mid = (averageMag * Math.cos(averageAngle)) + 150
            var draw_y_mid = (averageMag * Math.sin(averageAngle)) + 150

            ctx.lineTo(draw_x_mid, draw_y_mid)

            //Back to source point
            ctx.lineTo(draw_x_src, draw_y_src)





            var draw_x = (interestCoords[ccwClose].x)
            var draw_y = (interestCoords[ccwClose].y)


            ctx.moveTo(canvasWidth / 2, canvasHeight / 2)
            ctx.lineTo(draw_x, draw_y)

            //Send line to mid point


            var averageMag = (magnitude + interestCoords[ccwClose].magnitude) / 2 //Calc average magnitude of each point
            averageMag = averageMag * 0.625 //Modify Magnititude

            var averageAngle = (closest + angles[ccwClose]) / 2 //Calculate Midpoint Angle

            //Fix for where angles cross over zero point
            if (averageAngle < angles[ccwClose]) {
                averageAngle = averageAngle + Math.PI
            }

            //Calc x and y
            var draw_x_mid = (averageMag * Math.cos(averageAngle)) + 150
            var draw_y_mid = (averageMag * Math.sin(averageAngle)) + 150

            ctx.lineTo(draw_x_mid, draw_y_mid)

            //Back to source point
            ctx.lineTo(draw_x_src, draw_y_src)

            ctx.fillStyle = 'rgba(243, 202, 64, 0.5)';
            ctx.fill();



        }




        // draw all coordinates held in state
        coordinates.forEach((coordinate) => {
            draw(ctx, coordinate)
        });
    });

    return [coordinates, setCoordinates, clickedCoordinates, setClickedCoordinates, canvasRef, canvasWidth, canvasHeight];
}