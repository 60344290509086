import React, { useState, useEffect } from 'react'

import '../css/admin-menu.css'

export default function AdminMenu({ tagFunc }) {

    const [expansionArray, setExpansionArray] = useState([false, false, false, false, false, false, false, false, false, false, false])
    const [categories, setCategories] = useState([])
    const [parentCategories, setParentCategories] = useState([])

    const handleExpansion = (row) => {
        var currentExpansions = [...expansionArray]
        currentExpansions[row] = !currentExpansions[row]
        setExpansionArray(currentExpansions);
    }

    const handleCheck = (name, parentName) => {
        var tempCategories = [...categories]
        var tempParentCategories = [...parentCategories]

        //Name check
        if (tempCategories.indexOf(name) !== -1) {
            tempCategories = tempCategories.filter(item => item !== name)
        } else {
            tempCategories.push(name)
        }

        //Parent Name check
        //TODO: Fix this so that it just loops through the arrays to ID what all categories need to be included.
        if (tempCategories.indexOf(parentName) !== -1) {
            if (categories.indexOf(name) !== -1) {
                tempCategories = tempCategories.filter(item => item !== parentName);
            } else {
                tempCategories = tempCategories;
            }
        } else {
            tempCategories.push(parentName);
            parentCategories.push(parentName);
        }

        setCategories(tempCategories); //Set State
        tagFunc(tempCategories); //Send to Parent

        console.log(tempCategories);
    }

    const wellnessOptions = ['Health Care', 'Mental Health', 'Nursing', 'Bodybuilding', 'Physical exercise', 'Running', 'Weight training', 'Meditation', 'Yoga',
        'Charity and causes', 'Community issues', 'Politics', 'Veterans', 'Volunteering', 'Environmentalism', 'Sustainability', 'Outdoors', 'Travel',
        'Beaches', 'Ecotourism', 'Lakes', 'Mountains', 'Rivers', 'Recreation', 'Boating', 'Camping', 'Fishing', 'Horseback riding', 'Hunting', 'Mountain biking', 'Surfing', 'Other'
    ]

    const spiritualityOptions = ['Judaism', 'Chrisitanity', 'Islam', 'Buddhism', 'Hinduism', 'Sikhism', 'Contemporary Spirituality', 'Indigenous', 'Ancient Spirituality', 'Other']

    const businessOptions = [
        'Advertising', 'Agriculture', 'Architecture', 'Aviation', 'Banking', 'Investment banking', 'Online banking', 'Retail banking',
        'Business', 'Construction', 'Economics', 'Engineering', 'Entrepreneurship', 'Management', 'Marketing', 'Personal finance',
        'Credit cards', 'Insurance', 'Investment', 'Mortgage loans', 'Real Estate', 'Retail', 'Small Business'
    ]

    const familyOptions = [
        'Dating', 'Family', 'Fatherhood', 'Friendship', 'Home and garden', 'Furniture', 'Gardening',
        'Home Appliances', 'Home improvement', 'Marriage', 'Motherhood', 'Parenting', 'Birds',
        'Cats', 'Dogs', 'Fish', 'Horses', 'Pet food', 'Rabbits', 'Reptiles', 'Weddings'
    ]

    const entertainmentOptions = ['Games', 'Action games', 'Board games', 'Browser games', 'Card games', 'Casino games', 'First-person shooter games',
        'Gambling', 'Multiplayer online games', 'Puzzle video games', 'Racing games', 'Role-playing games', 'Sports games', 'Strategy games', 'Video games',
        'Word games', 'Toys', 'Live events', 'Ballet', 'Bars', 'Concerts', 'Dancehalls', 'Music Festivals', 'Nightclubs', 'Parties', 'Plays', 'Theater', 'Movies',
        'Action', 'Animated', 'Anime', 'Bollywood', 'Comedy', 'Documentary', 'Drama', 'Fantasy', 'Horror', 'Musical', 'Science Fiction', 'Thriller', 'Music',
        'Blues', 'Classical', 'Country', 'Dance', 'Electronic', 'Gospel', 'Heavy Metal', 'Hip hop', 'Jazz', 'Music videos', 'Pop', 'Rhythm and blues', 'Rock',
        'Soul', 'Reading', 'Books', 'E-books', 'Comics', 'Fiction', 'Literature', 'Magazines', 'Manga', 'Mystery fiction', 'Newspapers', 'Non-fiction books',
        'Romance novels', 'TV', 'Comedies', 'Game shows', 'Reality shows', 'Talkshows', 'Dramas'
    ]

    const foodOptions = [
        'Alcoholic Beverages', 'Beer', 'Distilled beverage', 'Wine', 'Beverages', 'Coffee', 'Energy drinks', 'Juice', 'Soft drinks', 'Tea', 'Cooking', 'Baking',
        'Recipes', 'Cuisine', 'Chinese cuisine', 'French cuisine', 'German cuisine', 'Greek cuisine', 'Indian cuisine', 'Italian cuisine', 'Japanese cuisine',
        'Korean cuisine', 'Latin American cuisine', 'Mexican cuisine', 'Middle eastern cuisine', 'Spanish cuisine', 'Thai cuisine ', 'Vietnamese cuisine',
        'Food', 'Barbeque', 'Chocolate', 'Desserts', 'Fast food', 'Organic food', 'Pizza', 'Seafood', 'Veganism', 'Vegetarianism', 'Restaurants', 'Coffeehouses',
        'Diners', 'Fast casual restaurants', 'Fast food restaurants', 'Fine dining'
    ]

    const artOptions = ['Design', 'Graphic Design', 'Fashion Design', 'Interior Design', 'Acting', 'Crafts', 'Dance',
        'Drawing', 'Drums', 'Fine art', 'Guitar', 'Painting', 'Performing Arts', 'Photography', 'Sculpture',
        'Singing', 'Writing', 'Poetry', 'Tattoo', 'Sketch art'
    ]

    const shoppingOptions = [
        'Vehicles', 'Automobiles', 'Boats', 'Electric vehicle', 'Hybrids', 'Minivans', 'Motorcycles', 'RVs', 'SUVs',
        'Scooters ', 'Trucks', 'Beauty', 'Beauty salons', 'Cosmetics', 'Fragrances', 'Hair products', 'Spas', 'Clothing', 'Children’s clothing',
        'Men’s clothing', 'Shoes', 'Women’s clothing', 'Fashion Accessories', 'Dresses', 'Handbags', 'Jewelry', 'Sunglasses', 'Shopping', 'Boutiques',
        'Coupons', 'Discount Stores', 'Luxury goods', 'Online Shopping', 'Shopping malls'
    ]

    const sportOptions = [
        'American Football', 'Soccer', 'Auto racing', 'Baseball', 'Basketball', 'College Football',
        'Golf', 'Marathons', 'Skiing', 'Snowboarding', 'Swimming', 'Tennis', 'Triathlons', 'Volleyball'
    ]

    const techOptions = ['Computers', 'Computer memory', 'Computer monitors', 'Computer processors', 'Computer servers', 'Desktop computers',
        'Free software', 'Hard drives', 'Network storage', 'Software', 'Tablet computers', 'Consumer electronics', 'Audio equipment', 'Camcorders',
        'Cameras', 'E-book readers', 'GPS devices', 'Game consoles', 'Mobile phones', 'Portable media players', 'Projectors', 'Smartphones', 'Televisions',
        'Formal Sciences', 'Decision Theory', 'Logic', 'Mathematics', 'Statistics', 'Systems Theory', 'Computer Science', 'Natural Science',
        'Physics', 'Chemistry', 'Earth Science', 'Space/Astrology', 'Biology', 'Online', 'SEO', 'Web design', 'Web development', 'Web Hosting'
    ]

    const educationOptions = ['K-12 education', 'Higher education', 'Early education', 'Do It Yourself (DIY)', 'Tutorials']

    return (
        <div className='admin-menu-container'>
            <div className='admin-category-container sub-content-container'>
                <div className='admin-category-header'>
                    <div className='admin-category-title'>Wellness</div>
                    <div className='admin-category-expand-btn'
                        onClick={() => handleExpansion(0)}>{expansionArray[0] ? '-' : '+'}</div>
                </div>
                <div className={expansionArray[0] ? 'admin-category-list-container' : 'admin-category-list-container-hidden'}>
                    {wellnessOptions.map(option => (
                        <div className='admin-category-list-item' key={option}><input type='checkbox' onClick={() => handleCheck(option, 'Wellness')} />{' '}{option}</div>
                    ))}
                </div>
            </div>

            <div className='admin-category-container sub-content-container'>
                <div className='admin-category-header'>
                    <div className='admin-category-title'>Spirituality</div>
                    <div className='admin-category-expand-btn'
                        onClick={() => handleExpansion(1)}>{expansionArray[1] ? '-' : '+'}</div>
                </div>
                <div className={expansionArray[1] ? 'admin-category-list-container' : 'admin-category-list-container-hidden'}>
                    {spiritualityOptions.map(option => (
                        <div className='admin-category-list-item' key={option}><input type='checkbox' onClick={() => handleCheck(option, 'Spirituality')} />{' '}{option}</div>
                    ))}
                </div>
            </div>

            <div className='admin-category-container sub-content-container'>
                <div className='admin-category-header'>
                    <div className='admin-category-title'>Business/Industry</div>
                    <div className='admin-category-expand-btn'
                        onClick={() => handleExpansion(2)}>{expansionArray[2] ? '-' : '+'}</div>
                </div>
                <div className={expansionArray[2] ? 'admin-category-list-container' : 'admin-category-list-container-hidden'}>
                    {businessOptions.map(option => (
                        <div className='admin-category-list-item' key={option}><input type='checkbox' onClick={() => handleCheck(option, 'Business/Industry')} />{' '}{option}</div>
                    ))}
                </div>
            </div>

            <div className='admin-category-container sub-content-container'>
                <div className='admin-category-header'>
                    <div className='admin-category-title'>Family/Relationships</div>
                    <div className='admin-category-expand-btn'
                        onClick={() => handleExpansion(3)}>{expansionArray[3] ? '-' : '+'}</div>
                </div>
                <div className={expansionArray[3] ? 'admin-category-list-container' : 'admin-category-list-container-hidden'}>
                    {familyOptions.map(option => (
                        <div className='admin-category-list-item' key={option}><input type='checkbox' onClick={() => handleCheck(option, 'Family/Relationships')} />{' '}{option}</div>
                    ))}
                </div>
            </div>

            <div className='admin-category-container sub-content-container'>
                <div className='admin-category-header'>
                    <div className='admin-category-title'>Entertainment</div>
                    <div className='admin-category-expand-btn'
                        onClick={() => handleExpansion(4)}>{expansionArray[4] ? '-' : '+'}</div>
                </div>
                <div className={expansionArray[4] ? 'admin-category-list-container' : 'admin-category-list-container-hidden'}>
                    {entertainmentOptions.map(option => (
                        <div className='admin-category-list-item' key={option}><input type='checkbox' onClick={() => handleCheck(option, 'Entertainment')} />{' '}{option}</div>
                    ))}
                </div>
            </div>

            <div className='admin-category-container sub-content-container'>
                <div className='admin-category-header'>
                    <div className='admin-category-title'>Food/Drink</div>
                    <div className='admin-category-expand-btn'
                        onClick={() => handleExpansion(5)}>{expansionArray[5] ? '-' : '+'}</div>
                </div>
                <div className={expansionArray[5] ? 'admin-category-list-container' : 'admin-category-list-container-hidden'}>
                    {foodOptions.map(option => (
                        <div className='admin-category-list-item' key={option}><input type='checkbox' onClick={() => handleCheck(option, 'Food/Drink')} />{' '}{option}</div>
                    ))}
                </div>
            </div>

            <div className='admin-category-container sub-content-container'>
                <div className='admin-category-header'>
                    <div className='admin-category-title'>Art</div>
                    <div className='admin-category-expand-btn'
                        onClick={() => handleExpansion(6)}>{expansionArray[6] ? '-' : '+'}</div>
                </div>
                <div className={expansionArray[6] ? 'admin-category-list-container' : 'admin-category-list-container-hidden'}>
                    {artOptions.map(option => (
                        <div className='admin-category-list-item' key={option}><input type='checkbox' onClick={() => handleCheck(option, 'Art')} />{' '}{option}</div>
                    ))}
                </div>
            </div>

            <div className='admin-category-container sub-content-container'>
                <div className='admin-category-header'>
                    <div className='admin-category-title'>Shopping/Fashion</div>
                    <div className='admin-category-expand-btn'
                        onClick={() => handleExpansion(7)}>{expansionArray[7] ? '-' : '+'}</div>
                </div>
                <div className={expansionArray[7] ? 'admin-category-list-container' : 'admin-category-list-container-hidden'}>
                    {shoppingOptions.map(option => (
                        <div className='admin-category-list-item' key={option}><input type='checkbox' onClick={() => handleCheck(option, 'Shopping/Fashion')} />{' '}{option}</div>
                    ))}
                </div>
            </div>

            <div className='admin-category-container sub-content-container'>
                <div className='admin-category-header'>
                    <div className='admin-category-title'>Sports</div>
                    <div className='admin-category-expand-btn'
                        onClick={() => handleExpansion(8)}>{expansionArray[8] ? '-' : '+'}</div>
                </div>
                <div className={expansionArray[8] ? 'admin-category-list-container' : 'admin-category-list-container-hidden'}>
                    {sportOptions.map(option => (
                        <div className='admin-category-list-item' key={option}><input type='checkbox' onClick={() => handleCheck(option, 'Sports')} />{' '}{option}</div>
                    ))}
                </div>
            </div>

            <div className='admin-category-container sub-content-container'>
                <div className='admin-category-header'>
                    <div className='admin-category-title'>Technology/Science</div>
                    <div className='admin-category-expand-btn'
                        onClick={() => handleExpansion(9)}>{expansionArray[9] ? '-' : '+'}</div>
                </div>
                <div className={expansionArray[9] ? 'admin-category-list-container' : 'admin-category-list-container-hidden'}>
                    {techOptions.map(option => (
                        <div className='admin-category-list-item' key={option}><input type='checkbox' onClick={() => handleCheck(option, 'Technology/Science')} />{' '}{option}</div>
                    ))}
                </div>
            </div>

            <div className='admin-category-container sub-content-container'>
                <div className='admin-category-header'>
                    <div className='admin-category-title'>Education</div>
                    <div className='admin-category-expand-btn'
                        onClick={() => handleExpansion(10)}>{expansionArray[10] ? '-' : '+'}</div>
                </div>
                <div className={expansionArray[10] ? 'admin-category-list-container' : 'admin-category-list-container-hidden'}>
                    {educationOptions.map(option => (
                        <div className='admin-category-list-item' key={option}><input type='checkbox' onClick={() => handleCheck(option, 'Education')} />{' '}{option}</div>
                    ))}
                </div>
            </div>

        </div>
    )
}