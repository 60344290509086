//Library Imports
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import axios from 'axios'
import Modal from 'react-modal'

// Redux Actions
import { getCurrentForest, getUserForests } from '../../redux/actions/forest'

// Components
import ProfileCard from '../../components/Cards/ProfileCard/ProfileCard'
import Navbar from '../../components/Navbar/Navbar'
import ForestSelectorMVP from './ForestSelectorMVP'
import UserToForestModal from '../../components/Modals/UserToForestModal'
import CreateForestModal from '../../components/Modals/CreateForestModal'
import EditForestModal from '../../components/Modals/EditForestModal'
import ForestSelector from '../basecamp/components/ForestSelector'

// Assets
import './css/forest-organizer.css'
import '../../components/Cards/cards.css'

const ForestOrganizer = ({ forest, getCurrentForest, getUserForests, profile, auth }) => {

    //Modal Functions
    Modal.setAppElement(document.getElementById('root'));

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '2rem',
            zIndex: '10000'
        },
        overlay: { zIndex: 1001 }
    };

    const [userToForestIsOpen, setUserToForestIsOpen] = useState(false);
    const [createForestIsOpen, setCreateForestIsOpen] = useState(false);
    const [editForestIsOpen, setEditForestIsOpen] = useState(false);

    const openUserToForestModal = () => {
        setUserToForestIsOpen(true);
    }

    const openCreateForestModal = () => {
        setCreateForestIsOpen(true);
    }

    const openEditForestModal = () => {
        setEditForestIsOpen(true);
    }

    const closeModal = () => {
        setUserToForestIsOpen(false);
        setCreateForestIsOpen(false);
        setEditForestIsOpen(false);
    }

    const [currentlySelectedForest, setCurrentlySelectedForest] = useState({
        title: 'Loading...',
        description: 'Loading...',
        posts: [],
        users: []
    })

    const getForestFromChild = (forest) => {
        setCurrentlySelectedForest(forest);
    }

    useEffect(() => {
        //console.log(currentlySelectedForest);
    }, [currentlySelectedForest])


    const [mushroomMode, setMushroomMode] = useState(false)
    useEffect(() => {
        if (profile.profile) {
            setMushroomMode(profile.profile.mushroomMode);
        }
    }, [profile]);




    //User Gathering Stuff
    const [userArray, setUserArray] = useState([])
    const [currentUserToAdd, setCurrentUserToAdd] = useState(null)
    const [totalUserCount, setTotalUserCount] = useState(0)

    const createUserArray = () => {
        let forests = forest.forests
        let tempUserArray = []
        for (var i = 0; i < forests.length; i++) {
            for (var j = 0; j < forests[i].users.length; j++) {
                tempUserArray.push(forests[i].users[j])
            }
        }
        let uniqueUsers = [...new Set(tempUserArray)];
        for (var u = 0; u < uniqueUsers.length; u++) {
            axios.get(`/api/users/populateUser/${uniqueUsers[u]}`).then(res => {
                setCurrentUserToAdd(res.data)
            }).catch(err => console.log(err))
        }
    }

    useEffect(() => {
        if (currentUserToAdd !== null) {
            let tempUserArray = [...userArray, currentUserToAdd]
            setUserArray(tempUserArray);
        }
    }, [currentUserToAdd])

    useEffect(() => {
        if (forest !== null && forest.forests !== undefined) {
            createUserArray();
        }

    }, [forest])

    useEffect(() => {
        setTotalUserCount(userArray.length)
    }, [userArray])


    //Get forest info for user
    const [treesInForest, setTreesInForest] = useState(0)
    const [forestsAddedTo, setForestsAddedTo] = useState(0)

    useEffect(() => {
        if (auth.user !== null && auth.user._id !== undefined) {
            //Get how many forests this user is in
            axios.get(`/api/forest/checkForests/${auth.user._id}`).then(res => {
                setForestsAddedTo(res.data.length)
            }).catch(err => console.log(err))

            //Get how many trees this user has added to their forests
            axios.get(`/api/forest/totalUserCount/user/${auth.user._id}`).then(res => {
                setTreesInForest(res.data.length)
            }).catch(err => console.log(err))
        }

    }, [auth])


    return (
        <div className='forest-organizer-container content-container'>
            <div className={mushroomMode ? 'base-camp-static-bg mushroom' : 'base-camp-static-bg'} />
            <Navbar selected='forest' />
            Forest Organizer
            {/* <div className='row'>
                <div className='top-trees-block sub-content-container'>Recently Added
                
                    <div className='card-container'>
                        <ProfileCard name='Person 1' modalFunc={openUserToForestModal}/>
                        <ProfileCard name='Person 2' modalFunc={openUserToForestModal}/>
                        <ProfileCard name='Person 3' modalFunc={openUserToForestModal}/>
                        <ProfileCard name='Person 4' modalFunc={openUserToForestModal}/>
                    </div>
                </div>
            </div> */}
            <div className='row'>
                <div className='recently-added-block sub-content-container'>Recently Added
                    <div className='card-container'>
                        No new trees!
                        {/* <ProfileCard name='Person 5' />
                        <ProfileCard name='Person 6' />
                        <ProfileCard name='Person 7' />
                        <ProfileCard name='Person 8' /> */}
                    </div>
                </div>
                <div className='forest-info-block sub-content-container'>Forest Info
                <div className='sub-content-container'>Your Tree is currently in {forestsAddedTo} Forests.</div>
                    <div className='sub-content-container'>You currently have {treesInForest} Trees in your Forests.</div>
                    <button className='create-forest-btn clickable' onClick={() => openCreateForestModal()}>+ Create New Forest</button>
                </div>
            </div>
            <div className='row'>
                <div className='forest-view-block sub-content-container'>
                    <div className='forest-details sub-content-container'>
                        {/* // TODO Get proper Selector working in here */}
                        {/* <ForestSelector parentCB={getForestFromChild} includeAllTreesEverywhere={false}/> */}
                        <ForestSelectorMVP parentCB={getForestFromChild} includeAllTreesEverywhere={false}/>
                        <div className='forest-description'>{currentlySelectedForest.description}</div>
                        <div className='edit-details-btn clickable' onClick={() => openEditForestModal()}>Edit This Forest</div>
                    </div>
                    <div className='member-display-title'>Current Members:</div>

                    <div className='forest-member-display'>
                        {currentlySelectedForest.users.length > 0 ?
                            currentlySelectedForest.users.map(user => <ProfileCard key={user} userId={user} name='Testing' summaryOnly={true} />)
                            :
                            <div key='no-users' className='no-user-display'>No Users here, yet!</div>
                        }
                    </div>
                </div>

            </div>

            <Modal
                isOpen={userToForestIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Choose Forests Modal"
            >
                <UserToForestModal closeModal={closeModal} />
            </Modal>

            <Modal
                isOpen={createForestIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Create Forest Modal"
            >
                <CreateForestModal closeModal={closeModal} />
            </Modal>

            <Modal
                isOpen={editForestIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Edit Forest Modal"
            >
                <EditForestModal closeModal={closeModal} currentForest={currentlySelectedForest} />
            </Modal>

        </div>
    )
}

ForestOrganizer.propTypes = {
    currentForest: PropTypes.object,
    getCurrentForest: PropTypes.func.isRequired,
    getUserForests: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    forest: state.forest,
    profile: state.profile,
    auth: state.auth
})

export default connect(mapStateToProps, { getCurrentForest, getUserForests })(ForestOrganizer)
