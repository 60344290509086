// This is the Component which displays the latest breezes, as the lower half of the Breeze Block.

import React, {useState,useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlag } from '@fortawesome/free-solid-svg-icons'

import { useDispatch, useSelector } from 'react-redux'
import { getLatestBreezes, handleSupportBreeze, handleDownvoteBreeze } from '../../../redux/actions/breezes'

// Assets
import '../css/breeze-block.css'
import '../css/breeze-display.css'

// TODO: This should all be removed in favor of just using the Breeze Block's logic. Just pass in the variables! Most of this is already done.

export default function BreezeDisplay() {

    // REDUX
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.auth.user)
    const breezesLoading = useSelector(state => state.breezes.latestBreezesLoading)
    const latestBreezes = useSelector(state => state.breezes.latest)

    // Use effect once we've got a user to grab the breezes
    useEffect(() => {
        if (currentUser!=null){
            dispatch(getLatestBreezes(currentUser._id));
        }
    }, [currentUser])

    // Populate Breezes once they are loaded
    const [currentBreezeIndex, setCurrentBreezeIndex]= useState(-1)

    useEffect(() => {
        if (!breezesLoading){
            if (latestBreezes.length>0) {
                setCurrentBreezeIndex(0);
                setCurrentBreezeText(latestBreezes[0].text)
                setCurrentBreezeId(latestBreezes[0]._id)
            } else {
                setCurrentBreezeText("No More Breezes!");
                setCurrentBreezeId(-1);
                setCurrentBreezeSignature('');
            }
        }
    }, [breezesLoading])

    useEffect(() => {
        // If we're at the end of the list, indicate this.
        if (currentBreezeIndex >= latestBreezes.length){
            setCurrentBreezeText("No More Breezes!");
            setCurrentBreezeId(-1);
            setCurrentBreezeSignature('');

        // Otherwise, pass data.
        } else {
            if (latestBreezes.length>0) {
                setCurrentBreezeText(latestBreezes[currentBreezeIndex].text);
                setCurrentBreezeId(latestBreezes[currentBreezeIndex]._id);
                setCurrentBreezeSignature(latestBreezes[currentBreezeIndex].name)
            }
        }

    }, [currentBreezeIndex])

    const [currentBreezeId, setCurrentBreezeId] = useState(-1);
    const [currentBreezeText, setCurrentBreezeText] = useState('Loading...')
    const [currentBreezeSignature, setCurrentBreezeSignature] = useState('')

    const supportBreeze = () => {
        setCurrentBreezeIndex(currentBreezeIndex+1);
        dispatch(handleSupportBreeze(currentBreezeId, currentUser));
    }

    const downvoteBreeze = () => {
        setCurrentBreezeIndex(currentBreezeIndex+1);
        dispatch(handleDownvoteBreeze(currentBreezeId, currentUser));
    }



    return (
        <div className='latest-breeze-container'>

            <div className='current-breeze-container'>
                <div className='current-breeze-header'>
                    <div className='breeze-title'>Latest Breezes</div>
                    <a href={`https://docs.google.com/forms/d/e/1FAIpQLScKEI8ciA2xXboYaxLFiv6BWUWti9GZkDqnDt9Z6w_jgKAPQA/viewform?usp=pp_url&entry.1105456013=${currentBreezeId}`} target='_blank' rel="noopener noreferrer">
                        
                        {currentBreezeId != -1 ?
                            <div onClick={() => {}} className='btn-report clickable-danger tooltip'>
                                <FontAwesomeIcon icon={faFlag} />
                                <span className='tooltiptext'>Report Breeze</span>
                            </div>
                            :
                            <></>
                        }
                        
                    </a>
                </div>
                
                <div className='breeze-content'>{currentBreezeText}</div>

                {currentBreezeSignature != '' ? 
                    <div className='breeze-signature'>
                        --{currentBreezeSignature}
                    </div>
                    :
                    <></>
                }

                <div className='breeze-btn-container'>
                    <div className='btn-like btn-support clickable' onClick={() => supportBreeze()}>Keep It Going</div>
                    <div className='btn-like btn-danger clickable' onClick={() => downvoteBreeze()}>Stop The Breeze</div>
                </div>
            </div>

        </div>
    )
}
