import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'

import { connect } from 'react-redux'
import axios from 'axios'

import './modal.css'

const UserToForestModal = ({ closeModal, forests }) => {

    const [selectionArray, setSelectionArray] = useState([])

    const [selectionCount, setSelectionCount] = useState(0)

    useEffect(() => {
        let tempSelectionArray = []
        for (var i = 0; i < forests.length; i++) {
            tempSelectionArray.push(false)
        }
        setSelectionArray(tempSelectionArray);
    }, [])

    const selectionHandler = (index) => {
        let tempSelectionArray = [...selectionArray]
        tempSelectionArray[index] = !tempSelectionArray[index]
        setSelectionArray(tempSelectionArray);
    }

    useEffect(() => {
        console.log(selectionArray)
        let tempSelectionCount = 0;
        for (var i = 0; i < selectionArray.length; i++) {
            if (selectionArray[i]) tempSelectionCount++;
        }
        setSelectionCount(tempSelectionCount)
    }, [selectionArray])



    return (
        <div className='modal-content-container'>
            <div className='modal-title'>Choose Forest</div>
            <div className='modal-description'>Choose which forest(s) you'd like to put this tree in:</div>

            <div className='modal-content'>
                <form>

                    {forests.map((item, index) => (
                        <div
                            className={selectionArray[index] ? 'modal-selection-option selected clickable' : 'modal-selection-option clickable'}
                            onClick={() => selectionHandler(index)}
                            key={index}>
                            <div className='modal-selection-title' key={index}>{item.title}</div>
                        </div>
                    ))}


                </form>

            </div>

            <div className='modal-control-container'>
                <button className='modal-cancel-btn clickable' onClick={closeModal}>Cancel</button>
                <button
                    className='modal-continue-btn clickable'
                    onClick={() => {
                        console.log('we are going to make posts happen here!')
                        closeModal();
                    }}>
                    Add to {selectionCount === 0 ? 'All' : selectionCount} {selectionCount === 1 ? 'Forest' : 'Forests'}
                </button>
            </div>

        </div>
    )
}


const mapStateToProps = state => ({
    forests: state.forest.forests
})

UserToForestModal.propTypes = {
    forests: PropTypes.array.isRequired,
}

export default connect(mapStateToProps, {})(UserToForestModal);