import axios from 'axios'

import { GET_FOREST, FOREST_ERROR, CREATE_FOREST, GET_PROFILE, GET_USER_FORESTS } from './types'

//Get current forest data
export const getCurrentForest = (forestId) => async dispatch => {
    try {
        const res = await axios.get(`/api/forest/${forestId}`);
        dispatch({
            type: GET_FOREST,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: FOREST_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

//Create or update forest
export const createForest = (formData, history, edit = false) => async dispatch => {

    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const res = await axios.post('/api/forest', formData, config);
        dispatch({
            type: GET_FOREST,
            payload: res.data
        })

        //Get forest ID which was just added
        const forestId = res.data._id

        //Get existing forests array
        const resProfile = await axios.get('/api/profile/me', config);
        const currentForests = resProfile.data.forests
        var currentForestsArray = []
        currentForests.forEach(forest => currentForestsArray.push(forest._id))
        const newForestArray = [...currentForestsArray, forestId]

        //Create object and send for update
        const updateObj = { forests: newForestArray };
        const resProfileUpdated = await axios.post('/api/profile', updateObj, config);
        dispatch({
            type: GET_PROFILE,
            payload: resProfileUpdated.data
        })

        if (!edit) {
            history.push('/forestorganizer')
        }

    } catch (err) {
        dispatch({
            type: FOREST_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

//Get current user's forests
export const getUserForests = () => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try {
        const resProfile = await axios.get('api/profile/me', {}, config);
        const currentUserId = resProfile.data.user._id;
        const res = await axios.get(`api/forest/user/${currentUserId}`, {}, config);
        dispatch({
            type: GET_USER_FORESTS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: FOREST_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}


//Get current user's forests
export const getUserForestsById = (currentUserId) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try {
        const res = await axios.get(`/api/forest/user/${currentUserId}`, {}, config);
        dispatch({
            type: GET_USER_FORESTS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: FOREST_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}