import React from 'react'

import '../css/sign-up-footer.css'

export default function SignUpFooter() {
    return (
        <div className="signup-footer-container">
            <a className="citation-link" href="https://www.freepik.com/free-photos-vectors/">Vector Art created by freepik, studiogstock,<br />pch.vector, and makyzz at www.freepik.com</a>
        </div>
    )
}
