import React from 'react';
import '../../App.css';

import './css/sign-up.css'
import SignUpHeader from './components/SignUpHeader';
import SignUpFooter from './components/SignUpFooter';
import MailchimpSignup from './components/MailchimpSignup';

function HomePage() {
    return (
        <div>
            <SignUpHeader />
            <MailchimpSignup />
            <SignUpFooter />
        </div>
    );
}

export default HomePage;
