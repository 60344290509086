// Library Imports
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import axios from 'axios'
import { withRouter } from 'react-router-dom'

// Redux Actions
import { createForest } from '../../redux/actions/forest'

// Components
import UseDebounceEffect from '../../utils/UseDebounceEffect'

// Assets
import './modal.css'

const TagUserModal = ({ closeModal, createForest, history, forests, currentForest, parentCallback }) => {

    const [forestData, setForestData] = useState({
        title: '',
        description: '',
        users: []
    })

    const handleSaveTags = () => {
        let userSelection = []
        for (var i = 0; i < selectionArray.length; i++) {
            if (selectionArray[i]) userSelection.push(userArray[i])
        }
        parentCallback(userSelection)
    }

    const [userArray, setUserArray] = useState([]) //This is the object we use to populate via the axios requests, which is pushed to the formal state after it has finished loading.
    const [currentUserToAdd, setCurrentUserToAdd] = useState(null) //We use this to pass each user to the user array above which is temporary.

    const createUserArray = () => {
        let tempUserArray = []
        for (var i = 0; i < forests.length; i++) {
            for (var j = 0; j < forests[i].users.length; j++) {
                tempUserArray.push(forests[i].users[j])
            }
        }
        let uniqueUsers = [...new Set(tempUserArray)];
        for (var u = 0; u < uniqueUsers.length; u++) {
            axios.get(`/api/users/populateUser/${uniqueUsers[u]}`).then(res => {
                setCurrentUserToAdd(res.data)
            }).catch(err => console.log(err))
        }
    }

    useEffect(() => {
        if (currentUserToAdd !== null) {
            let tempUserArray = [...userArray, currentUserToAdd]
            setUserArray(tempUserArray);
        }
    }, [currentUserToAdd])


    UseDebounceEffect(() => {
        setUsers(userArray); //Set users which are mapped to render
        let tempSelectionArray = []

        setSelectionArray(tempSelectionArray); //Set Selection options which are mapped to render
    }, [userArray], 100)


    const [users, setUsers] = useState([]) //These are the actual users which are rendered, after they all have been grabbed/verified as grabbed.
    const [selectionArray, setSelectionArray] = useState([])

    //On load!
    useEffect(() => {
        createUserArray();
        if (currentForest !== undefined) {
            setForestData(currentForest)
        }
    }, [])

    const selectionHandler = (index) => {
        let tempSelectionArray = [...selectionArray]
        tempSelectionArray[index] = !tempSelectionArray[index]
        setSelectionArray(tempSelectionArray);
    }



    return (
        <div className='modal-content-container'>
            <div className='modal-title'>Tag Trees</div>
            <div className='modal-description'>You can add or remove tagged trees by selecting or de-selecting them, below.</div>

            <div className='modal-content'>

                <div className='modal-input-title'>Tagged Trees (selected):</div>
                <form className='user-select-form'>

                    {users.length === 0 ? <>No trees added, yet! You need to have added trees from their profile to tag them in posts.</>
                        :
                        <>
                            {
                                users.map((item, index) => (
                                    <div
                                        className={selectionArray[index] ? 'modal-selection-option selected clickable' : 'modal-selection-option clickable'}
                                        onClick={() => selectionHandler(index)}
                                        key={index}>
                                        <div className='modal-selection-title' key={index}>{item.name}</div>
                                    </div>
                                ))
                            }

                        </>
                    }


                </form>

            </div>

            <div className='modal-control-container'>
                <button className='modal-cancel-btn clickable' onClick={closeModal}>Cancel</button>
                <button
                    className='modal-continue-btn clickable'
                    onClick={() => {
                        handleSaveTags();
                        closeModal();
                    }}>
                    Add Tags
                </button>
            </div>


        </div >
    )
}

const mapStateToProps = state => ({
    forests: state.forest.forests
})

TagUserModal.propTypes = {
    createForest: PropTypes.func.isRequired,
    forests: PropTypes.array.isRequired,
}

export default connect(mapStateToProps, { createForest })(withRouter(TagUserModal))