// Library Imports
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import axios from 'axios'
import { withRouter } from 'react-router-dom'

// Redux Actions
import { createForest } from '../../redux/actions/forest'

// Components
import UseDebounceEffect from '../../utils/UseDebounceEffect'

// Assets
import './modal.css'


const EditForestModal = ({ closeModal, createForest, history, forests, currentForest }) => {

    const refreshPage = () => window.location.reload();

    const [forestData, setForestData] = useState({
        title: '',
        description: '',
        users: []
    })

    const handleTitle = (e) => {
        const tempState = { ...forestData }
        tempState.title = e.target.value
        setForestData(tempState);
    }

    const handleDescription = (e) => {
        const tempState = { ...forestData }
        tempState.description = e.target.value
        setForestData(tempState);
    }

    const handleForestUpdate = () => {
        let updateObj = { ...forestData }

        let newUsers = []
        for (var i = 0; i < selectionArray.length; i++) {
            if (selectionArray[i]) {
                newUsers.push(userArray[i]._id)
            }
        }
        updateObj.users = [...newUsers]

        axios.post(`/api/forest/update/${currentForest._id}`, updateObj).then(res => {
            console.log(res);
            refreshPage();
        }).catch(err => console.log(err))
    }

    const [userArray, setUserArray] = useState([]) //This is the object we use to populate via the axios requests, which is pushed to the formal state after it has finished loading.
    const [currentUserToAdd, setCurrentUserToAdd] = useState(null) //We use this to pass each user to the user array above which is temporary.

    const createUserArray = () => {
        let tempUserArray = []
        for (var i = 0; i < forests.length; i++) {
            for (var j = 0; j < forests[i].users.length; j++) {
                tempUserArray.push(forests[i].users[j])
            }
        }
        let uniqueUsers = [...new Set(tempUserArray)];
        for (var u = 0; u < uniqueUsers.length; u++) {
            axios.get(`/api/users/populateUser/${uniqueUsers[u]}`).then(res => {
                setCurrentUserToAdd(res.data)
            }).catch(err => console.log(err))
        }
    }

    useEffect(() => {
        if (currentUserToAdd !== null) {
            let tempUserArray = [...userArray, currentUserToAdd]
            setUserArray(tempUserArray);
        }
    }, [currentUserToAdd])


    UseDebounceEffect(() => {
        setUsers(userArray); //Set users which are mapped to render
        let tempSelectionArray = []
        for (var i = 0; i < userArray.length; i++) {
            //If the user is inside the current forest, select them.
            if (currentForest.users.indexOf(userArray[i]._id) !== -1) {
                tempSelectionArray.push(true)
            } else {
                tempSelectionArray.push(false)
            }
        }
        setSelectionArray(tempSelectionArray); //Set Selection options which are mapped to render
    }, [userArray], 100)


    const [users, setUsers] = useState([]) //These are the actual users which are rendered, after they all have been grabbed/verified as grabbed.
    const [selectionArray, setSelectionArray] = useState([])

    //On load!
    useEffect(() => {
        createUserArray();
        if (currentForest !== undefined) {
            setForestData(currentForest)
        }
    }, [])

    const selectionHandler = (index) => {
        let tempSelectionArray = [...selectionArray]
        tempSelectionArray[index] = !tempSelectionArray[index]
        setSelectionArray(tempSelectionArray);
    }



    return (
        <div className='modal-content-container'>
            <div className='modal-title'>Edit Forest</div>
            <div className='modal-description'>Change the Name/Description of your Forest. You can add or remove members by selecting or de-selecting them, below.</div>

            <div className='modal-content'>

                <div className='modal-input-title'>Forest Name</div>
                <input type='text'
                    className='modal-text-input'
                    name='title'
                    value={forestData.title}
                    onChange={(e) => handleTitle(e)} />

                <div className='modal-input-title'>Forest Description</div>
                <textarea
                    className='modal-text-input textarea'
                    rows={5}
                    name='description'
                    value={forestData.description}
                    onChange={(e) => handleDescription(e)} />

                <div className='modal-input-title'>Current Users (selected):</div>
                <form className='user-select-form'>

                    {users.length === 0 ? <>No trees added, yet!</>
                        :
                        <>
                            {
                                users.map((item, index) => (
                                    <div
                                        className={selectionArray[index] ? 'modal-selection-option selected clickable' : 'modal-selection-option clickable'}
                                        onClick={() => selectionHandler(index)}
                                        key={index}>
                                        <div className='modal-selection-title' key={index}>{item.name}</div>
                                    </div>
                                ))
                            }

                        </>
                    }


                </form>

            </div>

            <div className='modal-control-container'>
                <button className='modal-cancel-btn clickable' onClick={closeModal}>Cancel</button>
                <button
                    className='modal-continue-btn clickable'
                    onClick={() => {
                        handleForestUpdate();
                        closeModal();
                    }}>
                    Save Forest
                </button>
            </div>


        </div >
    )
}

const mapStateToProps = state => ({
    forests: state.forest.forests
})

EditForestModal.propTypes = {
    createForest: PropTypes.func.isRequired,
    forests: PropTypes.array.isRequired,
}

export default connect(mapStateToProps, { createForest })(withRouter(EditForestModal))