// Library Imports
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import axios from 'axios'

// Redux Actions
import { getUserPlants, getFieldPlants } from '../../redux/actions/plant'

// Components
import Navbar from '../../components/Navbar/Navbar'
import PlantCard from '../../components/Cards/PlantCard/PlantCard'
import UseDebounceEffect from '../../utils/UseDebounceEffect'

// Assets
import './field.css'

// * Note: This is the FIELD component, which means I can see my own field, including new or not yet dropped seeds.

const Field = ({ auth, plant, profile, getUserPlants, getFieldPlants }) => {

    const [guidePlants, setGuidePlants] = useState([])
    const [fieldPlants, setFieldPlants] = useState([])
    const [newPlants, setNewPlants] = useState([])

    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        getUserPlants(); //Get plants the user owns
    }, [auth.loading]);

    //Wait for profile to finish loading, then grab everything we need from there
    useEffect(() => {
        if (plant !== null) {
            if (plant.plants !== null) {
                console.log(plant.plants)
                preparePlantRenders();
            }
        }
    }, [plant.loading])

    //Render Functions
    const preparePlantRenders = () => {
        var tempGuidePlants = []
        var tempFieldPlants = []
        var tempNewPlants = []
        if (plant.plants !== null) {
            for (var i = 0; i < plant.plants.length; i++) {
                console.log(plant.plants[i]);
                if (plant.plants[i].isSeed) {
                    tempNewPlants.push(plant.plants[i])
                } else {
                    tempGuidePlants.push(plant.plants[i])
                }
            }

            setGuidePlants(tempGuidePlants);
            setFieldPlants(tempFieldPlants);
            setNewPlants(tempNewPlants);

        } else {
            console.log('plants not ready yet')
        }
    }

    //Get Field Plants for user, which are stored in their local profile
    useEffect(() => {
        console.log(profile.profile);
        if (profile.profile) {
            if (profile.profile.plants.length > 0) {
                let tempFieldPlants = []
                for (var i = 0; i < profile.profile.plants.length; i++) {
                    axios.get(`/api/plant/${profile.profile.plants[i]}`).then(res => {
                        tempFieldPlants.push(res.data)
                    }).catch(err => console.log(err))
                }
                setFieldPlants(tempFieldPlants);
            }
        }
    }, [profile.loading])

    UseDebounceEffect(() => {
        console.log('we will search now!')

    }, [searchQuery], 250)


    const [mushroomMode, setMushroomMode] = useState(false)
    useEffect(() => {
        if (profile.profile) {
            setMushroomMode(profile.profile.mushroomMode);
        }
    }, [profile]);


    return (
        <div className='content-container field-container'>
            <div className={mushroomMode ? 'base-camp-static-bg mushroom' : 'base-camp-static-bg'} />
            <Navbar selected='field' />

            <div className='field-view'>
                <div className='user-field sub-content-container'>
                    <div className='user-plant-display'>
                        <div className='user-left-container'>
                            <div className='plant-display-title'>Plants You Are Guiding</div>
                            <div className='plant-display-container'>
                                {(guidePlants.length > 0) ? guidePlants.map(item => <PlantCard key={item._id} plantId={item._id} plantName={item.title} plantImg={item.mainImage} />) : <>Nothing here yet!</>}
                            </div>
                        </div>
                        <div className='user-right-container'>
                            <div className='plant-display-title'>Plants In Your Field</div>
                            <div className='plant-display-container'>
                                {(fieldPlants.length > 0) ? fieldPlants.map(item => <PlantCard key={item._id} plantId={item._id} plantName={item.title} plantImg={item.mainImage} />) : <>Nothing here yet!</>}
                            </div>
                        </div>
                    </div>

                    <div className='search-header'>
                        <div className='search-bar-container field-search-container'>
                            <FontAwesomeIcon icon={faSearch} />
                            <input className='search-input' type='text' placeholder='Type here to search your field' onChange={(e) => setSearchQuery(e.target.value)} />
                        </div>
                    </div>
                </div>

                <div className='recommend-field sub-content-container'>
                    <div className='plant-display-title'>New Plants</div>
                    <div className='plant-display-container'>

                        {(newPlants.length > 0) ? newPlants.map(item => <PlantCard key={item._id} plantId={item._id} plantName={item.title} seedCard={true} plantImg={item.mainImage} />) : <>Nothing here yet!</>}

                    </div>
                </div>
            </div>

        </div>
    )
}


const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile,
    plant: state.plant
})

Field.propTypes = {
    getUserPlants: PropTypes.func.isRequired,
    getFieldPlants: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    plant: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, { getUserPlants, getFieldPlants })(Field);