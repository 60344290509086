// Library Imports
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Modal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileImage, faMapMarkerAlt, faVideo, faUserTag } from '@fortawesome/free-solid-svg-icons'

// Redux Actions

// Components
import TagUserModal from '../../../components/Modals/TagUserModal'
import ImageUploadModal from  '../../../components/Modals/ImageUploadModal'

// Assets
import '../css/make-post-container.css'

export default function MakePlantPost({ makePost, parentCallback, currentUser, currentMushroomMode, userIsCultivator = false, addToFieldFunc, currentPlant }) {

    //Modal Functions
    Modal.setAppElement(document.getElementById('root'));

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '2rem',
            zIndex: '10000'
        },
        overlay: { zIndex: 1001 }
    };

    const [tagUserModalIsOpen, setTagUserModalIsOpen] = useState(false);
    const [imageUploadModalIsOpen, setImageUploadModalIsOpen] = useState(false);

    const openTagUserModal = () => {
        setTagUserModalIsOpen(true);
    }

    const openImageUploadModal = () => {
        setImageUploadModalIsOpen(true);
    }

    const closeModal = () => {
        setTagUserModalIsOpen(false);
        setImageUploadModalIsOpen(false);
    }

    const [taggedUsers, setTaggedUsers] = useState([])
    const collectTags = (tags) => {
        setTaggedUsers(tags)
    }

    const [attachedImageUrl, setAttachedImageUrl] = useState('')
    const collectImageUrl = (url) => {
        setAttachedImageUrl(url)
    }

    const plantId = window.location.href.split('?id=')[1]

    const [postInput, setPostInput] = useState("");
    const handleChange = (e) => { setPostInput(e.target.value); };

    //Make Post in here is an imported function 
    const handleMakePost = () => {
        parentCallback();

        let stringTags = []
        for (var i = 0; i < taggedUsers.length; i++) {
            stringTags.push(taggedUsers[i]._id)
        }

        let postObj = {
            user: currentUser._id,
            name: currentUser.name,
            avatar: currentUser.avatar,
            text: postInput,
            targetPlant: plantId,
            postType: 'plant',
            isMushroom: currentMushroomMode,
            attachedImage: attachedImageUrl,
            taggedUsers: stringTags
        }


        if (currentMushroomMode) {
            axios.post(`/api/posts/plant/mushroomPost/${plantId}`, postObj).then(res => {

                //Notify all users they've been tagged.
                for (var i = 0; i < stringTags.length; i++) {
                    let notificationObj = {
                        sourceUser: currentUser._id,//Source User Id
                        sourceUserName: "Anonymous Mushroom",//Source User Name
                        sourceUserAvatar: "https://freevintageillustrations.com/wp-content/uploads/2017/11/mushroom-illustrations-public-domain-19th-century.jpg",//Source User Avatar
                        targetUser: stringTags[i],//Target User Id
                        notificationType: 'tagNotification',//Notification type
                        targetPost: plantId,//Target Post Id
                        targetName: currentPlant.title,//Target Post Title
                    }
                    axios.post('/api/notifications/', notificationObj)
                }

                //Refresh window to get all new data
                window.location.reload();

            }).catch(err => console.log(err));
        } else {
            axios.post(`/api/posts/plant/${plantId}`, postObj).then(res => {
                //console.log(res)

                //Notify all users they've been tagged.
                for (var i = 0; i < stringTags.length; i++) {
                    let notificationObj = {
                        sourceUser: currentUser._id,//Source User Id
                        sourceUserName: currentUser.name,//Source User Name
                        sourceUserAvatar: currentUser.avatar,//Source User Avatar
                        targetUser: stringTags[i],//Target User Id
                        notificationType: 'tagNotification',//Notification type
                        targetPost: plantId,//Target Post Id
                        targetName: currentPlant.title,//Target Post Title
                    }
                    axios.post('/api/notifications/', notificationObj)
                }

                //Refresh window to get all new data
                window.location.reload();
            }).catch(err => console.log(err));
        }



        console.log('postObj:')
        console.log(postObj)
    }


    return (
        <div className='make-post-container sub-content-container'>
            <div>Help Cultivate this Plant</div>
            {userIsCultivator ?
                <>
                    <textarea
                        className='make-post-text-input'
                        rows='5'
                        style={{ resize: 'none' }}
                        placeholder="What can you contribute to this project?"
                        value={postInput}
                        onChange={(e) => handleChange(e)}
                    />

                    {taggedUsers.length > 0 ?

                        <div className='user-tag-section'>Tagged Users: {taggedUsers.map((item, idx) => {
                            if (idx === taggedUsers.length - 1) {
                                return item.name
                            } else {
                                return item.name + ', '
                            }
                        })}

                        </div>

                        : <></>}

                    {attachedImageUrl !== '' ?

                        <div className='user-tag-section'>
                            <img className='image-preview' alt='image-preview' src={attachedImageUrl} style={{ maxWidth: '100%' }} />
                        </div>

                        : <></>}


                    <div className='make-post-buttons-container'>
                        <div className='make-post-left-buttons'>

                            <div className='make-post-left-btn clickable make-post-tooltip' onClick={() => openImageUploadModal()}>
                                <FontAwesomeIcon icon={faFileImage} />
                                <span className='make-post-tooltiptext'>Upload Image</span>
                            </div>
                            <div className='make-post-left-btn clickable make-post-tooltip' onClick={() => openTagUserModal()}>
                                <FontAwesomeIcon icon={faUserTag} />
                                <span className='make-post-tooltiptext'>Tag A Tree</span>
                            </div>

                        </div>
                        <div className='make-post-right-buttons'>
                            <button className={currentMushroomMode ? 'post-btn mushroom clickable' : 'post-btn clickable'} onClick={() => handleMakePost()}>{currentMushroomMode ? 'Post As Mushroom' : 'Post'}</button>
                        </div>
                    </div>
                </>
                :
                <div className='plant-add-btn-container'>
                    <button className='plant-add-btn clickable' onClick={() => addToFieldFunc()}>+ Add Plant to Field to cultivate!</button>
                </div>
            }
            <Modal
                isOpen={tagUserModalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Tag User Modal"
            >
                <TagUserModal closeModal={closeModal} parentCallback={collectTags} />
            </Modal>

            <Modal
                isOpen={imageUploadModalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Upload Image Modal"
            >
                <ImageUploadModal closeModal={closeModal} parentCallback={collectImageUrl} />
            </Modal>
        </div>
    )
}
