// Library Imports
import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

// Redux Actions
import { createProfile } from '../../../redux/actions/profile'

// Assets
import '../css/root-select.css'


const RootSelect = ({ createProfile, history }) => {

    const [selectedArray, setSelectedArray] = useState([false, false, false, false, false, false, false, false, false, false, false])

    const handleSelection = (item) => {
        var currentSelections = [...selectedArray]
        currentSelections[item] = !currentSelections[item]
        setSelectedArray(currentSelections);
    }

    const submitRoots = () => {

        const rootSelectionObject = {
            treerings: {
                wellness: selectedArray[0] ? 1 : 0,       // Wellness
                spirituality: selectedArray[1] ? 1 : 0,   // Spirituality
                business: selectedArray[2] ? 1 : 0,       // Business/industry
                relationships: selectedArray[3] ? 1 : 0,  // Family/relationships
                entertainment: selectedArray[4] ? 1 : 0,  // Entertainment
                food: selectedArray[5] ? 1 : 0,           // Food/Drink
                art: selectedArray[6] ? 1 : 0,            // Art
                shopping: selectedArray[7] ? 1 : 0,       // Shopping/Fashion
                sports: selectedArray[8] ? 1 : 0,         // Sports
                technology: selectedArray[9] ? 1 : 0,     // Technology
                education: selectedArray[10] ? 1 : 0,      // Education
            }
        }

        createProfile(rootSelectionObject, history)
    }

    return (
        <div className='root-select-container'>
            <div className='base-camp-static-bg' />
            {/* <div className='white-bg' /> */}

            <div className='root-select-content content-container'>

                <div className='welcome-title'>Choose Your Roots</div>
                <div className='welcome-description'>What are you interested in seeing on SolutionTrees?</div>

                <div className='root-selection-items'>

                    <div className={selectedArray[0] ? 'card med-card clickable selected-card' : 'card med-card clickable'}
                        onClick={() => handleSelection(0)}>
                        <div className='med-card-img-container'>
                            <div className='med-card-img'>[Img]</div>
                        </div>
                        <div className='card-text-container'>
                            <div className='card-title'>Wellness</div>
                        </div>
                    </div>

                    <div className={selectedArray[1] ? 'card med-card clickable selected-card' : 'card med-card clickable'}
                        onClick={() => handleSelection(1)}>
                        <div className='med-card-img-container'>
                            <div className='med-card-img'>[Img]</div>
                        </div>
                        <div className='card-text-container'>
                            <div className='card-title'>Spirituality</div>
                        </div>
                    </div>

                    <div className={selectedArray[2] ? 'card med-card clickable selected-card' : 'card med-card clickable'}
                        onClick={() => handleSelection(2)}>
                        <div className='med-card-img-container'>
                            <div className='med-card-img'>[Img]</div>
                        </div>
                        <div className='card-text-container'>
                            <div className='card-title'>Business/industry</div>
                        </div>
                    </div>

                    <div className={selectedArray[3] ? 'card med-card clickable selected-card' : 'card med-card clickable'}
                        onClick={() => handleSelection(3)}>
                        <div className='med-card-img-container'>
                            <div className='med-card-img'>[Img]</div>
                        </div>
                        <div className='card-text-container'>
                            <div className='card-title'>Family/relationships</div>
                        </div>
                    </div>

                    <div className={selectedArray[4] ? 'card med-card clickable selected-card' : 'card med-card clickable'}
                        onClick={() => handleSelection(4)}>
                        <div className='med-card-img-container'>
                            <div className='med-card-img'>[Img]</div>
                        </div>
                        <div className='card-text-container'>
                            <div className='card-title'>Entertainment</div>
                        </div>
                    </div>

                    <div className={selectedArray[5] ? 'card med-card clickable selected-card' : 'card med-card clickable'}
                        onClick={() => handleSelection(5)}>
                        <div className='med-card-img-container'>
                            <div className='med-card-img'>[Img]</div>
                        </div>
                        <div className='card-text-container'>
                            <div className='card-title'>Food/Drink</div>
                        </div>
                    </div>

                    <div className={selectedArray[6] ? 'card med-card clickable selected-card' : 'card med-card clickable'}
                        onClick={() => handleSelection(6)}>
                        <div className='med-card-img-container'>
                            <div className='med-card-img'>[Img]</div>
                        </div>
                        <div className='card-text-container'>
                            <div className='card-title'>Art</div>
                        </div>
                    </div>

                    <div className={selectedArray[7] ? 'card med-card clickable selected-card' : 'card med-card clickable'}
                        onClick={() => handleSelection(7)}>
                        <div className='med-card-img-container'>
                            <div className='med-card-img'>[Img]</div>
                        </div>
                        <div className='card-text-container'>
                            <div className='card-title'>Shopping/Fashion</div>
                        </div>
                    </div>

                    <div className={selectedArray[8] ? 'card med-card clickable selected-card' : 'card med-card clickable'}
                        onClick={() => handleSelection(8)}>
                        <div className='med-card-img-container'>
                            <div className='med-card-img'>[Img]</div>
                        </div>
                        <div className='card-text-container'>
                            <div className='card-title'>Sports</div>
                        </div>
                    </div>

                    <div className={selectedArray[9] ? 'card med-card clickable selected-card' : 'card med-card clickable'}
                        onClick={() => handleSelection(9)}>
                        <div className='med-card-img-container'>
                            <div className='med-card-img'>[Img]</div>
                        </div>
                        <div className='card-text-container'>
                            <div className='card-title'>Technology</div>
                        </div>
                    </div>

                    <div className={selectedArray[10] ? 'card med-card clickable selected-card' : 'card med-card clickable'}
                        onClick={() => handleSelection(10)}>
                        <div className='med-card-img-container'>
                            <div className='med-card-img'>[Img]</div>
                        </div>
                        <div className='card-text-container'>
                            <div className='card-title'>Education</div>
                        </div>
                    </div>

                </div>

                <a href='/profileimageupload'><div className='continue-btn clickable' onClick={() => submitRoots()}>Continue</div></a>

            </div>

        </div>
    )
}

RootSelect.propTypes = {
    createProfile: PropTypes.func.isRequired,
}


export default connect(null, { createProfile })(withRouter(RootSelect));