// Library Imports
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

// Redux Actions
import { createForest } from '../../../redux/actions/forest'

// Assets
import './create-forest.css'

const CreateForest = ({ createForest, history }) => {

    const [forestData, setForestData] = useState({
        title: '',
        description: '',
        users: []
    })

    const handleTitle = (e) => {
        const tempState = { ...forestData }
        tempState.title = e.target.value
        setForestData(tempState);
    }

    const handleDescription = (e) => {
        const tempState = { ...forestData }
        tempState.description = e.target.value
        setForestData(tempState);
    }

    const handleUsers = () => {

    }

    const handleForestCreation = () => {
        createForest(forestData, history);
        window.location.reload();

    }

    return (
        <div className='create-forest-container'>
            <div className='base-camp-static-bg' />
            {/* <div className='white-bg' /> */}

            <div className='create-forest-content content-container'>

                <div className='welcome-title'>Create a Forest</div>
                <div className='welcome-description'>Create a space to shoot the breeze, and see what your fellow guides have been up to.</div>

                <div className='sub-content-container'>
                    <div>Forest Name</div>
                    <input type='text'
                        className='create-forest-title-input'
                        name='title'
                        value={forestData.title}
                        onChange={(e) => handleTitle(e)} />
                </div>
                <div className='sub-content-container'>
                    <div>Forest Description</div>
                    <textarea
                        className='create-forest-description-input'
                        name='description'
                        value={forestData.description}
                        onChange={(e) => handleDescription(e)} />
                </div>

                <div className='continue-btn clickable' onClick={() => handleForestCreation()}>Create Forest</div>

            </div>

        </div>
    )
}

CreateForest.propTypes = {
    createForest: PropTypes.func.isRequired,
}

export default connect(null, { createForest })(withRouter(CreateForest))