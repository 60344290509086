// Library imports
import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux';

// Redux Actions
import store from './redux/store'
import { loadUser } from './redux/actions/auth'
import { getCurrentProfile } from './redux/actions/profile';

// Components
import InternalSiteScaffold from './pages/scaffolds/InternalSiteScaffold';

// Pages
import HomePage from './pages/signup/HomePage'
import BaseCamp from './pages/basecamp/BaseCamp'
import Profile from './pages/profile/Profile';
import ForestOrganizer from './pages/forest/ForestOrganizer';
import Roots from './pages/roots/Roots';
import RootSelect from './pages/signup/components/RootSelect'; 
import PlantMVP from './pages/plant/PlantMVP';
import Field from './pages/field/Field';
import UserField from './pages/field/UserField';

import SignIn from './pages/signin/SignIn'; // Sign In to App
import ContinueSignupPage from './pages/signup/components/ContinueSignupPage'; // Continue Signup Process from Home Page
import ProfilePhotoSelect from './pages/signup/components/ProfilePhotoSelect'; // Select Profile Photo Page

import CreateAd from './pages/create/ad/CreateAd';
import CreateBarkPost from './pages/create/bark/CreateBarkPost';
import CreateForest from './pages/create/forest/CreateForest';
import CreatePlant from './pages/create/plant/CreatePlant';
import EditPlant from './pages/create/plant/EditPlant';

import Search from './pages/search/Search';
import SinglePostView from './pages/single-post-view/SinglePostView';
import BreezeHistory from './pages/breezehistory/BreezeHistory';

import setAuthToken from './utils/setAuthToken';
import PrivateRoute from './utils/PrivateRoute';

// Assets
import './App.css';
import './pages/signup/css/sign-up.css'


import demo from './pages/demo';
import ResetPassword from './pages/reset-password/ResetPassword';
import UILibrary from './ui-library/UILibrary';

//starter function to run each time app runs, before app is even loaded:
if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {

  useEffect(() => {
    store.dispatch(loadUser());
    store.dispatch(getCurrentProfile());
    var currentUrl = window.location.href;
    if (currentUrl.indexOf('https://') === -1 && currentUrl.indexOf('localhost') === -1) {
      var redirectUrl = 'https://' + currentUrl.split('http://')[1]
      window.location.replace(redirectUrl)
    }
  }, [])


  return (
    <Provider store={store}>
      <Router >
        <div className="App">
          <Route exact path="/functionalcomponentdemo" component={demo} />
          <Route exact path="/" component={HomePage} />
          <Route exact path="/roots" component={Roots} />
          <Route exact path="/continuesignin" component={ContinueSignupPage} />
          <PrivateRoute exact path="/basecamp" component={BaseCamp} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/post" component={SinglePostView} />
          <Route exact path="/forestorganizer" component={ForestOrganizer} />
          <Route exact path="/plant" component={PlantMVP} />
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/rootselect" component={RootSelect} />
          <Route exact path="/profileImageUpload" component={ProfilePhotoSelect} />
          <Route exact path="/createforest" component={CreateForest} />
          <PrivateRoute exact path="/createplant" component={CreatePlant} />
          <PrivateRoute exact path="/createbark" component={CreateBarkPost} />
          <PrivateRoute exact path="/breezehistory" component={BreezeHistory} />
          <Route exact path="/editplant" component={EditPlant} />
          <PrivateRoute exact path="/createad" component={CreateAd} />
          <Route exact path="/search" component={Search} />
          <PrivateRoute exact path="/field" component={Field} />
          <Route exact path="/userfield" component={UserField} />
          <Route exact path="/scaffold" component={InternalSiteScaffold} />
          <Route exact path="/resetpassword" component={ResetPassword} />
          <Route exact path="/ui-elements" component={UILibrary}/>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
