import React from 'react'

// Internal Site Scaffold is designed to be a functional
// component in which all internal web pages can be built.

// ! Currently unused, but this is a feature to add!

import './scaffolds.css'

export default function InternalSiteScaffold() {

    // This component is for the INTERNAL (aka logged in) areas of the site.
    // Size Responsiveness: sm, md, lg versions.
    // Grid Layouts: Grid layouts provided by scaffold classes
    
    // Data loading will happen via each of the "Page" components.
    // These pages will be served on top of this scaffold, and will use
    // things like "useSelector" to interact with the app's state, or
    // "useDispatch" to trigger state changes.

    // Longer term, this scaffold can also deal with global state, if it makes sense
    // to refactor in this way. For now, this is simply a wrapper to apply
    // really clean, obvious sizing logic, and is intended to be used to help create sensible
    // grid-based layouts.

    return (
        <div className='scaffold-container '>
            <div className='scaffold-navigation'>Navbar</div>
            <div className='basecamp-scaffold'>
                <div className='component-1'>Navbar Area</div>
                <div className='component-2'>Breeze Block</div>
                <div className='component-3'>User Info</div>
                <div className='component-4'>User Field</div>
                <div className='component-5'>Forest Feed</div>
            </div>

            {/* <div className='forest-organizer-scaffold'>
                <div className='component-6'>Recently Added</div>
                <div className='component-7'>Forest Stats</div>
                <div className='component-8'>Member Selector</div>
            </div> */}

            {/* <div className='field-scaffold'>
                <div className='component-9'>New Plants</div>
                <div className='component-10'>Search Plants</div>
                <div className='component-11'>Owned Plants</div>
                <div className='component-12'>Added Plants</div>
            </div> */}

            {/* <div className='profile-scaffold'>
                <div className='component-13'>User Field</div>
                <div className='component-14'>User Stats</div>
                <div className='component-15'>User Info</div>
                <div className='component-16'>Bark Feed</div>
            </div>  */}

            {/* //TODO: View All Notifications Scaffold */}
            {/* //TODO: Search Scaffold */}
            
            
        </div>
    )
}
