// Library Imports
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import axios from 'axios'

// Redux Actions
import { getUserPlants, getFieldPlants } from '../../redux/actions/plant'

// Components
import Navbar from '../../components/Navbar/Navbar'
import PlantCard from '../../components/Cards/PlantCard/PlantCard'
import UseDebounceEffect from '../../utils/UseDebounceEffect'

// Assets
import './field.css'
import './user-field.css'

// * This is the USER Field component, which a view of a random user's field, accessed from a Profile.

const UserField = ({ auth, plant, profile, getUserPlants, getFieldPlants }) => {

    const [guidePlants, setGuidePlants] = useState([])
    const [fieldPlants, setFieldPlants] = useState([])
    const [allPlants, setAllPlants] = useState([])


    const [currentPlantToAdd, setCurrentPlantToAdd] = useState(null);

    const fieldUserId = window.location.href.split('?id=')[1]
    const [fieldUserName, setFieldUserName] = useState('')

    //Get All Plants this user has:
    useEffect(() => {
        //Get Owned plants
        axios.get(`/api/plant/user/${fieldUserId}`).then(res => {
            console.log('Got plants the current user owns:');
            console.log(res);
            setGuidePlants(res.data)
        }).catch(err => console.log(err))

        //Get Cultivating plants
        axios.get(`/api/profile/user/${fieldUserId}`).then(res => {
            console.log('Got user Profile:');
            console.log(res);
            setFieldUserName(res.data.user.name.split(' ')[0])
            //Grab each plant via the ID provided:
            for (var i = 0; i < res.data.plants.length; i++) {
                axios.get(`/api/plant/${res.data.plants[i]}`)
                    .then(res => {
                        setCurrentPlantToAdd(res.data);
                    }).catch(err => console.log(err))
            }

        }).catch(err => console.log(err))

        //Not getting seeds.
    }, [])

    //For adding fiel plants asynchronously, to be removed in optimization phase.
    useEffect(() => {
        if (currentPlantToAdd !== null) {
            let tempFieldPlants = [...fieldPlants, currentPlantToAdd]
            setFieldPlants(tempFieldPlants);
        }
    }, [currentPlantToAdd])

    //Populate All Plants
    useEffect(() => {
        setAllPlants([...guidePlants, ...fieldPlants]);
    }, [guidePlants, fieldPlants])


    useEffect(() => {
        console.log("fieldUserName:");
        console.log(fieldUserName);
    }, [fieldUserName])



    const [searchQuery, setSearchQuery] = useState('');
    UseDebounceEffect(() => {
        console.log('we will search now!')

    }, [searchQuery], 250)


    const [mushroomMode, setMushroomMode] = useState(false)
    useEffect(() => {
        if (profile.profile) {
            setMushroomMode(profile.profile.mushroomMode);
        }
    }, [profile]);


    return (
        <div className='content-container field-container'>
            <div className={mushroomMode ? 'base-camp-static-bg mushroom' : 'base-camp-static-bg'} />
            <Navbar />

            <div className='field-view'>

                <div className='user-field sub-content-container'>
                    <a href={`/profile/?id=${fieldUserId}`}><div className='back-to-profile-btn'><FontAwesomeIcon icon={faArrowLeft} /> Back to {fieldUserName}'s Profile</div></a>
                    <div className='user-plant-display'>
                        <div className='user-left-container'>
                            <div className='plant-display-title'>Plants {fieldUserName} is Guiding</div>
                            <div className='plant-display-container'>
                                {(guidePlants.length > 0) ? guidePlants.map(item => <PlantCard key={item._id} plantId={item._id} plantName={item.title} plantImg={item.mainImage} />) : <>Nothing here yet!</>}
                            </div>
                        </div>
                        <div className='user-right-container'>
                            <div className='plant-display-title'>Plants In {fieldUserName}'s Field</div>
                            <div className='plant-display-container'>
                                {(fieldPlants.length > 0) ? fieldPlants.map(item => <PlantCard key={item._id} plantId={item._id} plantName={item.title} plantImg={item.mainImage} />) : <>Nothing here yet!</>}
                            </div>
                        </div>
                    </div>

                    <div className='search-header'>
                        <div className='search-bar-container field-search-container'>
                            <FontAwesomeIcon icon={faSearch} />
                            <input className='search-input' type='text' placeholder={`Type here to search ${fieldUserName}'s field`} onChange={(e) => setSearchQuery(e.target.value)} />
                        </div>
                    </div>
                </div>


            </div>

        </div>
    )
}


const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile,
    plant: state.plant
})

UserField.propTypes = {
    getUserPlants: PropTypes.func.isRequired,
    getFieldPlants: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    plant: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, { getUserPlants, getFieldPlants })(UserField);