//This is a template built which connects to the s3 backend! Use and modify as necessary.

import React, { useState, useEffect } from 'react'
import axios from 'axios'

import '../css/plant-photo-select.css'

export default function PlantGalleryUpload({ galleryInfoFunc, mainPhotoFunc }) {

    // State Definition 
    const [currentFile, setCurrentFile] = useState(null);
    const [displayFile, setDisplayFile] = useState(null);
    const [displayError, setDisplayError] = useState(null);
    const [uploadType, setUploadType] = useState('single');
    const [currentGalleryURLs, setCurrentGalleryURLS] = useState([])
    const [currentlySelectedGallery, setCurrentlySelectedGallery] = useState([true, false, false, false, false, false])

    const [waitingForUpload, setWaitingForUpload] = useState(false);

    // Called on File Select, sets state.
    const onFileChange = (e) => {
        setDisplayError(null);

        if (e.target.files.length == 1) {
            setCurrentFile(e.target.files[0]);
            setUploadType('single');
            if (e.target.files[0].size > 2000000) {
                setDisplayError('File too large. Max size: 2MB')
                return
            }
        } else {
            setUploadType('multiple');
            let fileArray = []
            for (var i = 0; i < e.target.files.length; i++) {
                if (e.target.files[i].size > 2000000) {
                    setDisplayError('File too large. Max size: 2MB')
                    return
                }
                fileArray.push(e.target.files[i]);
            }
            if (fileArray.length > 5) setDisplayError('Error: Too Many Files (Max 5).')
            setCurrentFile(fileArray);
        }

    }

    // Called on Submit, handles which protocol to use (single or multi)
    const onFileUpload = () => { //Submitted for Upload
        (uploadType === 'multiple') ? multipleFileUploadHandler() : singleUploadHandler();
    };

    //Calls Single Image upload from API
    const singleUploadHandler = () => {
        if (currentFile != null) {
            setWaitingForUpload(true);

            // Create an object of formData 
            const data = new FormData();

            // Update the formData object 
            data.append('image', currentFile, currentFile.name);

            //Post to proper route
            axios.post('/api/files/profile-img-upload', data, {
                headers: {
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                }
            })
                .then((response) => {
                    if (200 === response.status) {
                        // If file size is larger than expected.
                        if (response.data.error) {
                            if ('LIMIT_FILE_SIZE' === response.data.error.code) {
                                setDisplayError('File too large. Max size: 2MB');
                            } else {
                                setDisplayError(response.data.error); // If not the given file type
                            }
                        } else {
                            // Success
                            let fileName = response.data;
                            console.log('fileName', fileName);
                            console.log('File Uploaded');
                            setDisplayFile(fileName.location);
                            setCurrentGalleryURLS([fileName.location]);
                            setWaitingForUpload(false);
                        }
                    }
                }).catch((error) => {
                    // If another error
                    setDisplayError(error);
                    setWaitingForUpload(false);
                });

        } else {
            // if file not selected throw error
            setDisplayError('Please select one or more files to upload');
        }

    }

    //Calls Multi Image upload from API
    const multipleFileUploadHandler = () => {
        var data = new FormData();
        let selectedFiles = currentFile;

        //Exit Function if we have too many files.
        if (selectedFiles.length > 5) {
            setDisplayError('Error: Too Many Files (Max 5).')
            return
        }

        // Append Files to formData Object (browser will not log formData when debugging, see details at link below):
        // https://stackoverflow.com/questions/40062477/formdata-append-not-working
        if (selectedFiles) {
            setWaitingForUpload(true);
            for (let i = 0; i < selectedFiles.length; i++) {
                data.append('image', selectedFiles[i], selectedFiles[i].name);
            }

            axios.post('/api/files/multiple-file-upload', data, {
                headers: {
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                }
            })
                .then((response) => {
                    //Log Successful Response
                    console.log('res', response);

                    //Check for any additional errors if we successfully pinged s3
                    if (200 === response.status) {
                        // If file size is larger than expected.
                        if (response.data.error) {
                            if ('LIMIT_FILE_SIZE' === response.data.error.code) {
                                setDisplayError('File too large. Max size: 2MB');
                            } else if ('LIMIT_UNEXPECTED_FILE' === response.data.error.code) {
                                setDisplayError('Max 5 images allowed.');
                            } else {
                                // If not the given file type
                                setDisplayError(response.data.error);
                            }
                        } else {
                            // Success
                            let fileName = response.data;
                            console.log('File Uploaded');
                            let freshURLs = response.data.locationArray;
                            setCurrentGalleryURLS(response.data.locationArray);
                            setDisplayFile(response.data.locationArray[0]);
                            setWaitingForUpload(false);
                        }
                    }
                }).catch((error) => {
                    // If another error
                    setDisplayError(error);
                    setWaitingForUpload(false);
                });
        } else {
            // if file not selected throw error
            setDisplayError('Please select one or more files to upload');
        }

    };

    const handleThumbnailClick = (index) => {
        setDisplayFile(currentGalleryURLs[index]);
        var tempSelected = [false, false, false, false, false, false]
        tempSelected[index] = true
        setCurrentlySelectedGallery(tempSelected);
    }


    //Rendering Functions
    //Displays Photo once it has been uploaded to s3.
    const displayUploadedPhoto = () => {
        if (displayFile != null) {
            return (
                <div className='main-photo-container'>
                    <img alt='Currently Selected Main Photo' src={displayFile} style={{ maxHeight: '400px', maxWidth: '600px' }} />
                </div>
            );
        } else {
            return (
                <div className='main-photo-container'>
                    Photo preview(s) will appear here.
                </div>
            );
        }
    };

    //Displays Any errors if they are present.
    const displayAnyErrors = () => {
        if (displayFile != null) {
            return (<div style={{ color: 'red', margin: '1rem' }}>{' '}</div>);
        } else {
            return (<div style={{ color: 'red', margin: '1rem', textAlign: 'center' }}>{displayError}</div>);
        }
    };

    // Effect Hooks
    // Forces a re-render since React won't do it for the errors.
    useEffect(() => {
        displayAnyErrors();
    }, [displayError])

    //Sends Data to parent any time it is changed
    useEffect(() => {
        galleryInfoFunc(currentGalleryURLs);
    }, [currentGalleryURLs])

    useEffect(() => {
        mainPhotoFunc(displayFile);
    }, [displayFile])


    return (
        <div className='plant-gallery-upload-container'>
            <div className='plant-gallery-upload-input'>
                {/* Option to do multiple, or not! If you skip multiple qualifier, it will only allow one file. */}
                <input type="file" onChange={(e) => onFileChange(e)} multiple="multiple" />
                {waitingForUpload ?
                    <div className='centered-text'>
                        Uploading...
                    </div>
                    :
                    <button className='upload-btn' onClick={() => onFileUpload()}>
                        Click To Upload!
                    </button>
                }
            </div>
            {displayAnyErrors()}
            {displayUploadedPhoto()}
            <div className='thumbnail-display-container'>
                {currentGalleryURLs.length > 0 ?
                    (<>
                        {currentGalleryURLs.map((url, index) => (<>
                            <div key={index} className='thumbnail-img-container' onClick={() => handleThumbnailClick(index)}>
                                <img
                                    className={currentlySelectedGallery[index] ? 'plant-gallery-thumbnail selected' : 'plant-gallery-thumbnail'}
                                    key={url}
                                    alt='Thumbnail Image'
                                    src={url} />
                            </div></>))
                        }

                    </>
                    ) : <></>
                }
            </div>
            {currentGalleryURLs.length > 0 ? <div className='centered-text'>Select Main Photo to be displayed across Solutiontrees.</div> : <></>}
        </div>
    )
}
