import * as types from "../actions/types";

const initialState = {
    latest: [],
    latestBreezesLoading: true,
    userOwns: [],
    userBreezesLoading: true,
    error: {}
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case types.GET_LATEST_BREEZES_REQUESTED:
            return {
                ...state,
                latestBreezesLoading: true
            }
        case types.GET_USER_BREEZES_REQUESTED:
            return {
                ...state,
                userBreezesLoading: true
            }

        case types.LOAD_MORE_USER_BREEZES_REQUESTED:
            return {
                ...state,
                userOwns: state.userOwns,
                userBreezesLoading: true
            }

        case types.GET_LATEST_BREEZES_SUCCESS:
            return {
                ...state,
                latest: payload,
                latestBreezesLoading: false
            }

        case types.GET_USER_BREEZES_SUCCESS:
        case types.LOAD_MORE_USER_BREEZES_SUCCESS:
            return {
                ...state,
                userOwns: payload,
                userBreezesLoading: false
            }

        case types.GET_LATEST_BREEZES_FAILED:
            return {
                ...state,
                latestBreezesLoading: false
            }
        case types.GET_USER_BREEZES_FAILED:
        case types.LOAD_MORE_USER_BREEZES_FAILED:
            return {
                ...state,
                userBreezesLoading: false
            }
        default:
            return state
    }
}